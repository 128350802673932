import React, { useState } from "react";
import GoBack, { ConfirmButton } from "../../components/goBack/GoBack";
import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Card from "../../components/layout/cards/Card";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import json from "../../json/guide.json";
import { useParams } from "react-router-dom";
import axios from "axios";
import Popover from "../../components/modal/Popover";
export default function Archivage({
	currentStep,
}: {
	currentStep: multiStepI;
}) {
	const { id } = useParams();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const archiveProject = () => {
		try {
			axios.delete(`${process.env.REACT_APP_API_URL}/projet/${id}`);
		} catch (error: unknown) {
			console.error(error);
		}
	};

	const handleTogglePopover = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div>
			<div>
				<Card number="1" content={json.archivage.step_0.description}>
					<h4>J'archive le dossier</h4>
					<p>le dossier ne sera plus visible sur JA-Elatools</p>
					<ValidateStepBtn validate={handleTogglePopover} text="Valider" />
				</Card>
				<Popover isOpen={isOpen} onClick={() => setIsOpen(false)}>
					<div>
						<p>Souhaitez-vous vraiment archiver le dossier ?</p>
						<p>ATTENTION : le dossier ne sera plus visible sur JA-Elatools</p>
						<ConfirmButton
							onClickYes={archiveProject}
							onClickNo={() => setIsOpen(false)}
						/>
					</div>
				</Popover>
				<GoBack currentStep={currentStep?.stepId} />
			</div>
		</div>
	);
}
