import axios from "axios";
import qs from "query-string";
import React, { useState } from "react";
import GenericBtn from "../../components/layout/buttons/generic/GenericBtn";
import { FloatInput } from "../../components/utils/inputs/FloatInput";
import useRedirect from "../../hook/useRedirect";
import "../../components/forms/LoginForm.css";

export default function ResetPassword() {
	const [userData, setUserData] = useState<{
		password: string;
		confirmedPassword: string;
	}>({
		password: "",
		confirmedPassword: "",
	});

	const [error, setError] = useState<string>("");

	const navigate = useRedirect();

	const handleReset = async (e: React.FormEvent) => {
		e.preventDefault();
		const { userId, token } = qs.parse(window.location.search);

		if (userData.password !== userData.confirmedPassword) {
			setError("Les mots de passe ne correspondent pas");
			return;
		}
		const varData = {
			password: userData.password,
			confirmedPassword: userData.confirmedPassword,
			token: token,
		};
		try {
			const response = await axios.patch(
				`${process.env.REACT_APP_API_URL}/user/reset/${userId}`,
				varData
			);

			if (response.status === 200) {
				navigate("/");
				window.location.reload();
			}
		} catch (error: any) {
			// if server don't respond return error perso
			if (error.message === "Network Error") {
				setError("Serveur injoignable, veuillez ré-essayer.");
				return;
			}
			setError("mot de passe incorrect, veuillez ré-essayer.");
			console.error(error);
		}
	};
	return (
		<div className="login_container">
			<div className="login_box">
				<div className="login_header">
					<h1>Réinitialiser votre mot de passe</h1>
				</div>
				<div className="login_body">
					<div className="login_input">
						<FloatInput
							inputLabel="Mot de passe"
							type="password"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setUserData({ ...userData, password: e.target.value })
							}
							value={userData.password}
						/>
					</div>
					<div className="login_input">
						<FloatInput
							inputLabel="Confirmer votre mot de passe"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setUserData({ ...userData, confirmedPassword: e.target.value })
							}
							value={userData.confirmedPassword}
							type="password"
						/>
					</div>
					{error && (
						<div className="login_error">
							<span>{error}</span>
						</div>
					)}
				</div>
				<div className="login_button">
					<GenericBtn text="Valider" onClick={handleReset} />
				</div>
			</div>
		</div>
	);
}
