import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

export default function Header() {
	return (
		<div className="header_list">
			<Link to="/dashboard/dev" className="link_dev">
				<p>Liste des Variables</p>
			</Link>
			<Link to="/dashboard/dev/traitement" className="link_dev">
				<p>Liste des Traitements</p>
			</Link>
		</div>
	);
}
