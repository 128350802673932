import axios from "axios";
import { debounce } from "lodash";
import React, { createContext, useCallback, useState } from "react";
import { useToasts } from "../hook/useToasts";

interface formDataInteface {
  retourPrepaId: string | number;
  retourPrepaValue: string | number;
  retourPrjId_CellId: string | number;
  retourPrepaisEdited: boolean;
}

export interface FormInterface {
  handleSubmit: (
    event: React.MouseEvent<Element, MouseEvent>,
    paramsId: string,
    object: {
      projetCreatorId: number;
      projetName: string;
      User: {
        userId: number;
        userName: string;
      };
    }
  ) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formData: formDataInteface[];
  setFormData: React.Dispatch<React.SetStateAction<formDataInteface[]>>;
  handleSelectChange: React.ChangeEventHandler<HTMLSelectElement>;
  debouncedHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormContext = createContext<FormInterface | null>(null);

function FormProvider({ children }: { children: React.ReactNode }) {
  const role = localStorage.getItem("role");
  const roleParsed = JSON.parse(role as string);
  const { pushToast } = useToasts();

  const [formData, setFormData] = useState<Array<formDataInteface>>(
    [] as formDataInteface[]
  );
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (
    event: React.MouseEvent<Element, MouseEvent>,
    paramsId: string,
    object: {
      projetCreatorId: number;
      projetName: string;
      User: {
        userId: number;
        userName: string;
      };
    }
  ) => {
    event.preventDefault();
    const notifData = {
      projetId: Number(paramsId),
      notificationType: "PrepaInfoFlorence",
      notificationSenderId: object?.User.userId,
      notificationRecipientId: object?.projetCreatorId,
      notificationContent: `${object?.User.userName} à apporté des corrections au dossier ${object?.projetName}`,
    };
    try {
      if (roleParsed === "ASSISTANT_GESTION" || roleParsed === "ADMIN") {
        axios.patch(`${apiUrl}/retour-prepa`, {
          formData: formData,
          id: paramsId,
        });
      } else if (roleParsed === "PREPARATEUR") {
        axios.patch(`${apiUrl}/retour-prepa`, {
          formData: formData,
          id: paramsId,
        });
        axios.post(`${apiUrl}/notifications`, notifData);
      }
      pushToast({
        type: "success",
        message: "Le retour de préparation a bien été modifié",
      });
    } catch (error: unknown) {
      pushToast({
        type: "error",
        message: "Une erreur est survenue",
      });
      console.error({
        message: error,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, id } = event.target;

    const parentElement = event.target.parentNode as HTMLTableCellElement;
    if (parentElement) {
      parentElement.style.backgroundColor = "orange";
    }

    const itemIndex = formData.findIndex(
      (item) =>
        item.retourPrepaId === Number(id) && item.retourPrjId_CellId === name
    );
    if (itemIndex >= 0) {
      setFormData((prevData: any) =>
        prevData.map((item: formDataInteface, index: number) =>
          index === itemIndex ? { ...item, retourPrepaValue: value } : item
        )
      );
    } else {
      setFormData([
        ...formData,
        {
          retourPrepaId: Number(id),
          retourPrepaValue: value,
          retourPrjId_CellId: name,
          retourPrepaisEdited: true,
        },
      ]);
    }
  };

  const debouncedHandleChange = useCallback(debounce(handleChange, 500), [
    handleChange,
  ]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value, id } = event.target;

    const parentElement = event.target.parentNode as HTMLTableCellElement;
    if (parentElement) {
      parentElement.style.backgroundColor = "orange";
    }

    const itemIndex = formData.findIndex(
      (item) =>
        item.retourPrepaId === Number(id) && item.retourPrjId_CellId === name
    );
    if (itemIndex >= 0) {
      setFormData((prevData: any) =>
        prevData.map((item: formDataInteface, index: number) =>
          index === itemIndex ? { ...item, retourPrepaValue: value } : item
        )
      );
    } else {
      setFormData([
        ...formData,
        {
          retourPrepaId: Number(id),
          retourPrepaValue: value,
          retourPrjId_CellId: name,
          retourPrepaisEdited: true,
        },
      ]);
    }
  };

  return (
    <FormContext.Provider
      value={{
        handleSubmit,
        handleChange,
        formData,
        setFormData,
        handleSelectChange,
        debouncedHandleChange,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export default FormProvider;
