import axios from "axios";
import { useContext } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { LoginContext, LoginType } from "../../../contexts/LoginContext";
import useRedirect from "../../../hook/useRedirect";
import "./LogoutBtn.css";
import {
  UserContext,
  UserContextInterface,
} from "../../../contexts/UserContext";
import { set } from "lodash";

export default function LogoutBtn({
  setIsLoggedIn,
}: {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const { deleteToken, token } = useContext(LoginContext) as LoginType;
  const { currentUserId, setCurrentUserId } = useContext(
    UserContext
  ) as UserContextInterface;
  const navigate = useRedirect();

  const handleLogout = () => {
    try {
      /**
       *
       * why this syntax ?
       * patch or post method are waiting a data for second argument
       * axios.post(url[, data[, config]])
       * axios.put(url[, data[, config]])
       * axios.patch(url[, data[, config]])
       * so we need to change the syntaxe of the axios call
       *
       */
      axios({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/logout`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      deleteToken();
      setIsLoggedIn(false);
      setCurrentUserId(0);
      navigate("/");
    } catch (error: unknown) {
      console.error({
        message: "Erreur lors de la déconnexion",
        error: error,
      });
    }
  };
  return (
    <div className="logout-nav">
      <div className="logout-row">
        <div className="logout-link-box">
          <div className="logout-link" onClick={handleLogout}>
            <FaSignOutAlt className="icon" />
            <span>Deconnexion</span>
          </div>
        </div>
      </div>
    </div>
  );
}
