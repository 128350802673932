import axios from "axios";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { LoginContext, LoginType } from "../../contexts/LoginContext";
import GenericBtn from "../layout/buttons/generic/GenericBtn";
import Popover from "../modal/Popover";
import "./GoBack.css";

export const ConfirmButton = ({
	onClickYes,
	onClickNo,
}: {
	onClickYes: () => void;
	onClickNo: () => void;
}) => {
	return (
		<div className="goback_popover_btn">
			<button onClick={onClickYes} className="goback_btn">
				<span className="goback_glyphe true">✓</span>Oui
			</button>
			<button onClick={onClickNo} className="goback_btn">
				<span className="goback_glyphe false">✗</span>Non
			</button>
		</div>
	);
};

export default function GoBack({
	currentStep,
}: {
	currentStep: string | number;
}) {
	const { id } = useParams();
	const { token } = useContext(LoginContext) as LoginType;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};
	const goBack = () => {
		const step = { stepId: Number(currentStep) - 1 };

		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/projet/${id}`, step, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			window.location.reload();
		} catch (error) {
			console.error({ message: "error", error });
		}
	};

	return (
		<>
			<div>
				<GenericBtn text={`← Retour à l'étape précedente`} onClick={toggle} />
			</div>
			<Popover isOpen={isOpen} onClick={() => setIsOpen(false)}>
				<div className="goback_popover">
					<p>Souhaitez vous vraiment revenir à l'étape précédente ?</p>
					<ConfirmButton onClickYes={goBack} onClickNo={toggle} />
				</div>
			</Popover>
		</>
	);
}
