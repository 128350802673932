import { utils } from "xlsx";

export const downloadMapwood = (excelArray: any[], fileName: string) => {
	if (excelArray.length > 0) {
		//* get the value of each object
		const val = excelArray?.map((item: any) =>
			item?.map((i: { retourPrepaValue: string }) => i.retourPrepaValue)
		);

		//* convert to sheet
		const ws = utils.json_to_sheet(val, { skipHeader: true });

		//* change the separator to semi-comma
		const csv = utils.sheet_to_csv(ws, {
			FS: ";",
		});
		const encodedData = "\ufeff" + csv; // ajoute le BOM pour l'encodage UTF-8

		//* generate a csv file
		if (excelArray) {
			// Créer un objet Blob avec les données CSV encodées en UTF-8
			const blob = new Blob([encodedData], {
				type: "text/csv;charset=utf-8;",
			});

			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
		} else {
			console.error("no file");
		}
	}
};
