import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";

import {
	IoChevronDownOutline,
	IoChevronUpOutline,
	IoNotificationsOutline,
} from "react-icons/io5";
import { LoginContext, LoginType } from "../../../contexts/LoginContext";
import { useToasts } from "../../../hook/useToasts";
import BadgeNotif from "../badge/BadgeNotif";
import "./Accordeon.css";
import { Link } from "react-router-dom";
import { redirectByRole } from "../array/ResponsiveTable";

type INotification = {
	Sender: {
		userName: string;
	};
	projetId: number;
	notificationId: number;
	notificationType: string;
	notificationContent: string;
	notificationCreatedAt: string;
};

export default function Accordeon() {
	const { token, role } = useContext(LoginContext) as LoginType;
	const [isActive, setIsActive] = useState(false);
	const [notifications, setNotifications] = useState<any>([]);
	const { pushToast } = useToasts();

	function formatDate(dateString: string) {
		const date = new Date(Date.parse(dateString));

		return date.toLocaleDateString("fr-FR", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		});
	}

	useEffect(() => {
		if (!token) return;
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/notifications`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setNotifications(response.data);
				if (response.data.length > 0) {
					pushToast({
						message: `Vous avez ${response.data.length} notifications`,
						type: "warning",
					});
				} else {
					pushToast({
						message: "Vous n'avez pas de nouvelle notification",
						type: "info",
					});
				}
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, [token]);

	const handleNotificationClick = async (id: number) => {
		const patchNotification = {
			notificationId: id,
		};
		try {
			await axios.patch(
				`${process.env.REACT_APP_API_URL}/notifications`,
				patchNotification,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			const filteredNotifications = notifications.filter(
				(notif: { notificationId: number }) => notif.notificationId !== id
			);
			setNotifications(filteredNotifications);
		} catch (error: unknown) {
			console.error(error);
		}
	};

	return (
		<div className="accordion">
			<div className="accordion-item">
				<div className="accordion-title" onClick={() => setIsActive(!isActive)}>
					<div>
						<IoNotificationsOutline size={24} />
						<div className="all-project-badge-notif">
							{/**
							 * pour ne pas afficher le badge si il n'y a pas de notification
							 */}
							{notifications.length > 0 && (
								<BadgeNotif nbrOfNotif={notifications.length} />
							)}
						</div>
					</div>
					<div>
						{isActive ? (
							<IoChevronDownOutline size={24} />
						) : (
							<IoChevronUpOutline size={24} />
						)}
					</div>
				</div>
				{isActive && (
					<div className="accordion-content">
						{notifications.length >= 1 ? (
							notifications.map((notif: INotification) => (
								<motion.div
									key={notif.notificationId}
									className="accordeon-body"
									onClick={() => handleNotificationClick(notif.notificationId)}
									initial={{ opacity: 1, y: -100 }}
									animate={{ opacity: 1, y: 0 }}
								>
									<Link to={redirectByRole(role, notif)} className="notif_link">
										<div>{notif.Sender.userName}</div>
										<div>{notif.notificationContent}</div>
										<div>{formatDate(notif.notificationCreatedAt)}</div>
									</Link>
								</motion.div>
							))
						) : (
							<p>Vous n'avez pas de notification</p>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
