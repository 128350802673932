import { useEffect, useState } from "react";
import "./EnvoiPrepa.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import Card from "../../components/layout/cards/Card";
import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Popover from "../../components/modal/Popover";
import GenericBtn from "../../components/layout/buttons/generic/GenericBtn";
import json from "../../json/guide.json";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import { exportFileToCsv } from "../../functions/exportToCSV";
import LoadingPopover from "../../components/modal/LoadingPopover";

export const deleteFileExtension = (fileName: string) => {
	const fileExtension = fileName.split(".");
	return fileExtension[0];
};

export default function EnvoiPrepa({
	currentStep,
}: {
	currentStep: multiStepI;
}): JSX.Element {
	/* ----------------- BOOLEAN ----------------- */
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	/* ----------------- STRING ----------------- */
	const [files, setFiles] = useState<string>("");
	const [filesId, setFilesId] = useState<string>("");
	/* ----------------- CONFIRM MESSAGE ----------------- */
	const [confirmMessage, setConfirmMessage] = useState<string>("");
	const [isLoadingMessage, setIsLoadingMessage] = useState<string>("");
	/* ----------------- ARRAY ----------------- */
	const [r6, setR6] = useState<[]>([]);
	const { id } = useParams<{ id: string }>();
	/* ----------------- USE EFFECT -----------------*/
	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/projet/${id}`
				);

				setFiles(response.data.File.fileName);
				setFilesId(response.data.File.fileId);
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);

	const applyTreatment = async () => {
		if (files !== null) {
			setIsLoading(true);
			setIsLoadingMessage("Traitement en cours...");
			try {
				const newFile = await axios.get(
					`${process.env.REACT_APP_API_URL}/header/projet`,
					{
						params: {
							filesId: filesId,
							projetId: id,
						},
					}
				);
				setR6(newFile.data);
				setIsLoading(false);
				if (newFile.status === 200 && !isLoading) {
					setIsOpen(true);
					setConfirmMessage("Le traitement a bien été effectué.");
				}
			} catch (err) {
				console.error(err);
				setConfirmMessage("Erreur lors du traitement du fichier.");
			} finally {
				setTimeout(() => {
					setIsOpen(false);
					setIsLoadingMessage("");
				}, 1500);
			}
		}
	};

	const applyTransformTreatment = async () => {
		setIsLoading(true);
		setIsLoadingMessage("Traitement en cours...");
		try {
			const newFile = await axios.post(
				`${process.env.REACT_APP_API_URL}/converts/${id}`,
				r6
			);
			setR6(newFile.data);
			setIsLoading(false);
			if (newFile.status === 200 && !isLoading) {
				setIsOpen(true);
				setConfirmMessage("Le traitement a bien été effectué.");
			}
		} catch (err) {
			console.error(err);
			setConfirmMessage("Erreur lors du traitement du fichier.");
		} finally {
			setTimeout(() => {
				setIsOpen(false);
				setIsLoadingMessage("");
			}, 1500);
		}
	};

	const transform = async () => {
		if (files) {
			setIsLoading(true);
			setIsLoadingMessage("Ecriture du fichier en cours...");
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/converts/${filesId}`,
					{
						params: {
							rFileId: currentStep.rFileId,
							projetId: id,
						},
					}
				);

				setR6(response.data);
				setIsLoading(false);
				if (response.status === 200 && !isLoading) {
					setIsOpen(true);
					setConfirmMessage("Le fichier a bien été transformé");
				}
			} catch (error) {
				console.error(error);
				setConfirmMessage("Erreur lors de la transformation du fichier");
			} finally {
				setTimeout(() => {
					setIsOpen(false);
					setIsLoadingMessage("");
				}, 2000);
			}
		}
	};

	const goToNextStep = async () => {
		const data = {
			stepId: 2,
		};
		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/projet/${id}`, data);
			setIsOpen(true);

			setConfirmMessage("Le dossier est prêt à partir en préparation.");
		} catch (err) {
			setConfirmMessage("Erreur lors de la mise à jour du dossier.");
			console.error(err);
		} finally {
			setTimeout(() => {
				setIsOpen(false);
				window.location.reload();
			}, 1500);
		}
	};

	const handleExport = () => {
		try {
			exportFileToCsv(r6, files, goToNextStep);
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<div className="first-step">
			<Card number="1" content={json.preparation.step_1.description}>
				{files !== null ? (
					<p>Le fichier à bien été chargé</p>
				) : (
					<p>
						Il n'y a pas de fichier veuillez contacter l'équipe de maintenance
					</p>
				)}
				{files !== null ? <p>{files}</p> : <p>Il n'y a pas de fichier</p>}
				<></>
			</Card>
			{currentStep.rFileId !== 4 ? (
				<Card number="2" content={json.preparation.step_2.description}>
					<p>Transformer le fichier en fichier R6</p>
					<GenericBtn text="Transformer" onClick={transform} />
				</Card>
			) : (
				<></>
			)}
			<Card
				number={currentStep.rFileId !== 4 ? "3" : "2"}
				content={json.preparation.step_3.description}
			>
				<p>Appliquer les traitements</p>
				<GenericBtn
					text="Appliquer"
					onClick={
						currentStep.rFileId !== 4 ? applyTransformTreatment : applyTreatment
					}
				/>
			</Card>
			<Card
				number={currentStep.rFileId !== 4 ? "4" : "3"}
				content={json.preparation.step_4.description}
			>
				<p>
					Télécharger le fichier avec les traitements au format CSV (à importer
					dans Arpentgis pour la préparation).
				</p>
				<ValidateStepBtn
					text="Export le fichier en CSV"
					validate={handleExport}
				/>
			</Card>
			{/*------------------ VALIDATION POPOVER ------------------*/}
			<LoadingPopover isLoading={isLoading} message={isLoadingMessage} />
			<Popover isOpen={isOpen}>
				<p>{confirmMessage}</p>
			</Popover>
		</div>
	);
}
