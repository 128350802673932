import React from "react";
import "./GenericBtn.css";
type ButtonTypeProps = {
	text: string;
	onClick?: (e: React.MouseEvent) => void;
	disabled?: boolean;
};

export default function GenericBtn({
	text,
	onClick,
	disabled = false,
}: ButtonTypeProps) {
	return (
		<button
			type="submit"
			className="generic-btn"
			onClick={onClick}
			disabled={disabled}
		>
			{text}
		</button>
	);
}
