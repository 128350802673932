import React, { useEffect, useState } from "react";
import "./Facturation.css";
import axios from "axios";
import Slider from "../../components/cardSlider/Slider";
import GenericBtn from "../../components/layout/buttons/generic/GenericBtn";
import BtnUpload from "../../components/layout/buttons/upload/BtnUpload";
import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Card from "../../components/layout/cards/Card";
import TrancheArray from "../../components/utils/array/TrancheArray";
import Popover from "../../components/modal/Popover";
import { useParams } from "react-router-dom";
import json from "../../json/guide.json";
import { useToasts } from "../../hook/useToasts";
import {
  SyntheseInterface,
  trancheInterface,
} from "../../Types/Interfaces/FacturationInterface";
import { arrondirNombre } from "../../functions/arrondirNombre";
import GoBack, { ConfirmButton } from "../../components/goBack/GoBack";
import Modal from "../../components/modal/Modal";
import { TeamData } from "../../components/forms/PricePerKilo";
import { parseCharSpe } from "../../functions/replaceCharSpe";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfRaf from "../../components/pdf/PdfRaf";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import { donwloadFile } from "../../functions/downloadFile";
import { FloatInput } from "../../components/utils/inputs/FloatInput";
import Loader from "../../components/loading/Loader";
import { downloadRetourPrepaCorrected } from "../../functions/donwloadRPEdited";

export default function Facturation({
  currentStep,
}: {
  currentStep: multiStepI;
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const { pushToast } = useToasts();

  const [synthese, setSynthese] = useState<SyntheseInterface>(
    {} as SyntheseInterface
  );
  const [tranche, setTranche] = useState<trancheInterface>(
    {} as trancheInterface
  );
  const [facturedTranche, setFacturedTranche] = useState<[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenStandBy, setIsOpenStandBy] = useState<boolean>(false);
  const [teams, setTeams] = useState([]);
  const [teamsData, setTeamsData] = useState<TeamData[]>([]);
  const [convertedFile, setConvertedFile] = useState<[]>([]);
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [retourPrepa, setRetourPrepa] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileisConverted, setIsFileisConverted] = useState<boolean>(false);
  //! ---------------- useEffect ----------------
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${apiUrl}/synthese/${id}`);
        setSynthese(response.data);
      } catch (error: unknown) {
        console.error({ message: error });
      }
    })();
  }, []);

  useEffect(() => {
    displayTrancheAlreadyFact();
  }, []);

  useEffect(() => {
    if (file) uploadTranche();
  }, [file]);

  useEffect(() => {
    (async () => {
      try {
        const responses = await axios.get(
          `${process.env.REACT_APP_API_URL}/equipe-projet/${id}`
        );
        setTeams(responses.data);
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, []);

  const getRP = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/retour-prepa/corrected/${id}`
      );
      setRetourPrepa(response.data);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  //! ---------------- API CALL ----------------
  const uploadTranche = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file as Blob);
      formData.append("id", id as string);
      const response = await axios.post(`${apiUrl}/synthese/tranche`, formData);
      setTranche(response.data);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const displayTrancheAlreadyFact = async () => {
    try {
      const trancheFact = await axios.get(`${apiUrl}/tranches/${id}`);
      setFacturedTranche(trancheFact.data);
    } catch (error: unknown) {
      console.error({ message: "pas de données" });
    }
  };

  const validateTranche = async () => {
    if (file) {
      try {
        await axios.patch(`${apiUrl}/retour-prepa/edit-tranches`, {
          tranche: tranche,
          id: id,
        });
        await axios.post(`${apiUrl}/tranches`, {
          tranche: tranche,
          id: id,
        });
        pushToast({
          message: `La tranche ${tranche.Ttemp} a bien été créée.`,
          type: "success",
        });
      } catch (error: unknown) {
        console.error(error);
      }
    } else {
      console.error({ message: "pas de fichier." });
    }
  };

  const goToNextStep = async () => {
    const data = {
      stepId: Number(currentStep.stepId) + 1,
    };
    try {
      axios.patch(`${apiUrl}/projet/${id}`, data);
      setTimeout(() => {
        setIsOpen(false);
        window.location.reload();
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  };

  const turnToStandBy = async () => {
    const data = {
      projetStatus: "StandBy",
    };
    try {
      axios.patch(`${apiUrl}/projet/${id}`, data);
      setIsOpenStandBy(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handleConfirm = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      axios.patch(`${apiUrl}/equipe-projet`, teamsData);
      pushToast({
        message: "Les distances ont bien été mises à jour.",
        type: "success",
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const generateTrancheFile = async () => {
    const formData = new FormData();
    formData.append("file", file as Blob);
    try {
      const response = await axios.post(
        `${apiUrl}/tranches/replace/${id}`,
        formData
      );
      donwloadFile(
        response.data,
        `T${Number(currentStep.projetIndiceTranche) + 1}_P.${
          currentStep.projetName
        }.csv`
      );
    } catch (error: unknown) {
      console.error(error);
    }
  };

  //! ---------------- FUNCTIONS ----------------

  const togglePopover = () => {
    setIsOpenStandBy(!isOpenStandBy);
  };

  const toggleModal = () => {
    setIsOpenModal(true);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    equipeId: number
  ) => {
    const newTeamsData: any = [...teamsData];
    const index = newTeamsData.findIndex(
      (item: { equipeProjetId: number }) => item.equipeProjetId === equipeId
    );
    if (index >= 0) {
      newTeamsData[index] = {
        ...newTeamsData[index],
        equipeProjetId: equipeId,
        distance: event.target.value === "" ? null : event.target.value,
      };
    } else {
      newTeamsData.push({
        equipeProjetId: equipeId,
        distance: event.target.value === "" ? null : event.target.value,
      });
    }
    setTeamsData(newTeamsData);
  };

  const validatePrepa = async () => {
    const data = {
      projetPrepaIsValidate: true,
    };
    try {
      await axios.patch(`${apiUrl}/projet/${id}`, data);
      pushToast({
        message: "La préparation a bien été validée.",
        type: "success",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const convertR6toRx = async () => {
    setIsLoading(true);
    setConfirmMessage("Transformation du fichier en cours !");
    try {
      await getRP();
      const file = await axios.post(
        `${process.env.REACT_APP_API_URL}/converts/reverse/${id}/${currentStep.rFileId}`,
        {
          retourPrepa: retourPrepa,
          rFile: currentStep.rFileId,
          fileId: currentStep.fileFileId,
        }
      );
      setConvertedFile(file?.data);
      setIsLoading(false);
      setIsFileisConverted(true);
    } catch (error: unknown) {
      console.error(error);
      setIsOpen(true);
      setConfirmMessage("Votre Fichier n'a pas pu être transformer !");
      setTimeout(() => {
        setIsOpen(false);
      }, 1500);
    } finally {
      setConfirmMessage("");
    }
  };

  const downloadConvertRetourPrepaWithoutEquipe = async () => {
    setIsLoading(true);
    setConfirmMessage("Ecriture du fichier en cours !");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/traitement/replace/convert/${id}`,
        { convertFile: convertedFile, fileId: currentStep.rFileId }
      );
      downloadRetourPrepaCorrected(
        response.data,
        `T${Number(currentStep.projetIndiceTranche) + 1}_P.${
          currentStep.projetName
        }.csv`
      );
      setIsLoading(false);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setConfirmMessage("");
    }
  };

  return currentStep.projetPrepaIsValidate !== true ? (
    <Card number="1">
      <h4>Enedis à valider la préparation ?</h4>
      <GenericBtn text="Valider la préparation" onClick={validatePrepa} />
    </Card>
  ) : (
    <div>
      {/* ______________________________________________________________________________________________________________________________
			__________________________________________________________ MAIN COMPONENT ________________________________________________________
		  __________________________________________________________________________________________________________________________________
			*/}
      {/* ---------- RAF ARRAY ---------- */}
      <Card
        display={false}
        number=""
        content={json.facturation.step_0.description}
      >
        <h1>Facturation</h1>
        <p>{synthese?.tension?.totalDistanceTension}</p>
        <TrancheArray tranche={facturedTranche} />
      </Card>
      {/* ---------- CARD COMPONENTS ---------- */}
      <Card number="1" content={json.facturation.step_1.description}>
        <p>Modifier les rendements (optionnel)</p>
        {/* pouvoir modifier les rendements */}
        <GenericBtn text="Modifier les rendements" onClick={toggleModal} />
        <p>Créer une nouvelle tranche</p>
        <BtnUpload
          setFiles={setFile}
          accept=".csv, .xlsx"
          fileName={file?.name}
          text="Upload"
        />
      </Card>
      <Card number="2" content={json.facturation.step_2.description}>
        <h4>Visualiser les informations de facturation et d'encours</h4>
        <h4>Projection facturation Tranche {tranche.Ttemp} :</h4>
        <div className="tranche_resume">
          <div className="tranche_resume_item">
            <p className="tranche_item">Distance</p>
            <p>{tranche.tranche?.truncateSumOfDistance} km</p>
          </div>
          <div className="tranche_resume_item">
            <p className="tranche_item">Montant</p>
            <p>{arrondirNombre(tranche.tranche?.sumOfTranche)} €</p>
          </div>
        </div>

        <h4>Reste à faire :</h4>
        <div className="tranche_resume">
          <div className="tranche_resume_item">
            <p className="tranche_item">Distance</p>
            <p>{tranche.tranche?.truncateSumOfDistanceRaf} km</p>
          </div>
          <div className="tranche_resume_item">
            <p className="tranche_item">Montant</p>
            <p>{arrondirNombre(tranche.tranche?.sumOfRaf)} €</p>
          </div>
        </div>

        <div className="slider_container">
          {tranche?.trancheByTeam?.totalRaf?.map(
            (equipe: any, index: number) => (
              <Slider
                equipe={equipe.equipeDescription}
                metres={equipe.distance}
                points={equipe.count}
                temps={arrondirNombre(equipe.tempsDeCoupe_jours)}
                key={index}
              />
            )
          )}
        </div>
        {file !== null ? (
          <div>
            <h4>Synthèse de tranche</h4>
            <button className="download_synthese">
              <PDFDownloadLink
                document={
                  <PdfRaf tranche={tranche} currentStep={currentStep} />
                }
                fileName={`${currentStep.projetName}_syntheseRaf.pdf`}
                className="download_link"
              >
                {({ loading }) =>
                  loading ? "Loading document..." : "Téléchager la synthèse"
                }
              </PDFDownloadLink>
            </button>
          </div>
        ) : (
          <></>
        )}
      </Card>
      <Card number="3" content={json.facturation.step_3.description}>
        <p>Valider la création de la tranche</p>
        <p>T{Number(currentStep.projetIndiceTranche) + 1}</p>
        <p>Transformer votre fichier en fichier RX</p>
        <GenericBtn
          text={
            currentStep?.rFileId === 4 // 4 = R6
              ? "Valider la tranche et générer le fichier de tranche"
              : "Valider la tranche"
          }
          onClick={() => {
            validateTranche();
            if (currentStep.rFileId === 4) {
              generateTrancheFile();
            }
          }}
        />
        {currentStep?.rFileId !== 4 ? (
          <div className="btn_rx_container">
            <p>Convertir le fichier en Fichier RP</p>
            <GenericBtn
              onClick={convertR6toRx}
              text="Convertir le fichier en RX"
            />
            {isFileisConverted ? (
              <>
                <p>{`Télécharger le fichier de Tranche ${
                  Number(currentStep.projetIndiceTranche) + 1
                }`}</p>
                <GenericBtn
                  onClick={downloadConvertRetourPrepaWithoutEquipe}
                  text="Télécharger le fichier"
                />
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Card>
      <Card number="4" content={json.facturation.step_4.description}>
        <p>
          J&apos;ai fini de facturer les travaux sous tension, je souhaite
          mettre en stand-by le dossier
        </p>
        <ValidateStepBtn validate={togglePopover} text="Stand-by" />
      </Card>
      <Card number="5" content={json.facturation.step_5.description}>
        <p>J&apos;ai fini de facturer, je souhaite clôturer le dossier</p>
        <ValidateStepBtn validate={() => setIsOpen(true)} text="Clôturer" />
      </Card>
      <GoBack currentStep={currentStep?.stepId} />
      {/* ______________________________________________________________________________________________________________________________
			_______________________________________________________________ POPOVER __________________________________________________________
			__________________________________________________________________________________________________________________________________
			*/}
      <Popover isOpen={isOpen} onClick={() => setIsOpen(false)}>
        <div>
          <p>Souhaitez vous confirmer la clôture du dossier ?</p>
          <GenericBtn onClick={goToNextStep} text="Confirmer" />
        </div>
      </Popover>
      <Popover isOpen={isOpenStandBy} onClick={togglePopover}>
        <div>
          <p>Souhaitez-vous vraiment mettre le dossier en Stand-By ?</p>
          <ConfirmButton onClickYes={turnToStandBy} onClickNo={togglePopover} />
        </div>
      </Popover>
      <Popover isOpen={isLoading}>
        <div>
          <p>{confirmMessage}</p>
          <Loader />
        </div>
      </Popover>
      {/* ______________________________________________________________________________________________________________________________
			_______________________________________________________________ MODAL ____________________________________________________________
			__________________________________________________________________________________________________________________________________
			*/}
      <Modal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        title={"Ajuster les rendements"}
      >
        <div>
          <form className="formulaire" onSubmit={handleConfirm}>
            {teams
              .sort(
                (a: { equipeId: number }, b: { equipeId: number }) =>
                  a.equipeId - b.equipeId
              )
              .map(
                (item: {
                  equipeProjetId: number;
                  equipeProjetDistanceSpecifique: number;
                  Equipe: {
                    equipeDescription: string;
                  };
                }) => (
                  <div className="toto" key={item?.equipeProjetId}>
                    <div className="input_modal_direction">
                      <FloatInput
                        onChange={(event) =>
                          handleInputChange(event, item?.equipeProjetId)
                        }
                        defaultValue={item?.equipeProjetDistanceSpecifique}
                        inputLabel={parseCharSpe(
                          item.Equipe?.equipeDescription
                        )}
                      />
                    </div>
                  </div>
                )
              )}
            <div className="time_btn">
              <button className="time_submit">send</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
