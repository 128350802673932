import React, { useEffect, useState } from "react";
import "./MappingPage.css";
import axios from "axios";
import Card from "../../components/layout/cards/Card";
import Breadcrumb from "../../components/utils/breadCrumb/Breadcrumb";
import { useToasts } from "../../hook/useToasts";
import { selectOptionsInterface } from "../../Types/Interfaces/selectOptions";
import VarHeader from "../../components/excelSheet/headerExcelSheet/VarHeader";

type rFileT = {
	rFileId: number;
	rFileType: string;
};

export default function MappingMapwood() {
	const [rFileInfo, setRFileInfo] = useState<rFileT>();
	const [rFile, setRFile] = useState<[]>([]);
	const [headersMapwood, setHeadersMapwood] = useState<any[]>([]);
	const [headersR6, setHeadersR6] = useState<any[]>([]);
	const [selectedOption, setSelectedOption] = useState<string[]>([]);
	const [selectedOptions, setSelectedOptions] = useState<
		selectOptionsInterface[]
	>([]);
	const [values, setValues] = useState<any>();
	const { pushToast } = useToasts();

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/header/${5}`
				);
				setHeadersMapwood(response.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/rfiles`
				);
				setRFile(response.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	useEffect(() => {
		if (rFileInfo === undefined) return;
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/header/${rFileInfo.rFileId}`
				);
				setHeadersR6(response.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, [rFileInfo?.rFileId]);

	const sendVar = () => {
		try {
			axios.post(
				`${process.env.REACT_APP_API_URL}/mapping-mapwood`,
				selectedOptions
			);
			pushToast({ type: "success", message: "Mapping sauvegardé" });
		} catch (error: unknown) {
			pushToast({
				type: "error",
				message: "Erreur lors de la sauvegarde de votre mapping",
			});
			throw new Error("error");
		}
	};
	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "Mapping", path: "/dashboard/admin/mapping" },
		{ label: "Mapwood", path: "/dashboard/admin/mapping/mapwood" },
	];

	return (
		<div className="mapping_page">
			<Breadcrumb items={items} />
			<div className="mapping_box">
				<Card number="" display={false}>
					<select onChange={(e) => setRFileInfo(JSON.parse(e.target.value))}>
						<option>Veuillez choisir un type de fichier</option>
						{rFile.map((file: any) => (
							<option
								value={JSON.stringify({
									rFileId: file.rFileId,
									rFileType: file.rFileType,
								})}
							>
								{file.rFileType}
							</option>
						))}
					</select>
					<></>
				</Card>
				<VarHeader
					arrayHeader={headersMapwood}
					setArrayHeader={setHeadersMapwood}
					rRefArray={headersR6}
					onClickSend={sendVar}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					selectedOptions={selectedOptions}
					setSelectedOptions={setSelectedOptions}
					pageTitle={`Mapping ${rFileInfo?.rFileType} vers ${
						headersMapwood[0]?.Rfile.rFileType as string
					}`}
					setValues={setValues}
					fichierRref={headersMapwood[0]?.Rfile.rFileType as string}
					fichierRx={rFileInfo?.rFileType as string}
				/>
			</div>
		</div>
	);
}
