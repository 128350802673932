import React from "react";
import "./InfoPopover.css";

export default function InfoPopover({
  show,
  content,
  id,
}: {
  show: boolean;
  content: string;
  id?: string;
}) {
  return (
    <div className={show ? "open_pop" : "close_pop"} id={id}>
      {content}
    </div>
  );
}
