import React from "react";
import { Link } from "react-router-dom";
import "./Badge.css";

export const getInitials = (name: string): string => {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
export default function Badge({ userName }: { userName: string }): JSX.Element {
  const generateRandomColor = (): string => {
    const colors = ["#f3722c", "#f9c74f", "#4d908e", "#277da1"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div
      className="badge-container"
      style={{
        background: generateRandomColor(),
      }}
    >
      <Link to="profil">
        <p className="badge">{getInitials(userName)}</p>
      </Link>
    </div>
  );
}
