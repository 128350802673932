import React from "react";
import "./Slider.css";
interface SliderInterface {
  equipe: string;
  metres: number;
  points: number;
  temps: number;
}

export default function Slider({
  equipe,
  metres,
  points,
  temps,
}: SliderInterface): JSX.Element {
  return (
    <div className="card_slider_container">
      <div className="card_slider_wrapper">
        <h4 className="card_slider_title">{equipe}</h4>
        <div className="card_slider_body">
          <div className="card_slider_content">
            <p>Metres</p>
            <p>{metres}</p>
          </div>
          <div className="card_slider_content">
            <p>Points</p>
            <p>{points}</p>
          </div>
          <div className="card_slider_content">
            <p>Tps</p>
            <p>{temps}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
