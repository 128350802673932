import "./Legend.css";

export default function Legend() {
  return (
    <div className="legend-container">
      <div className="legend-item-box">
        <div className="legend-item-align">
          <div className="legend-item isError"></div>
          <p>cellule en erreur</p>
        </div>
        <div className="legend-item-align">
          <div className="legend-item isCheck"></div>
          <p>cellule à vérifier</p>
        </div>
        <div className="legend-item-align">
          <div className="legend-item isEdited "></div>
          <p>cellule edité</p>
        </div>
      </div>
    </div>
  );
}
