import Header from "../preparateur/Header";
import { MapInterface } from "../preparateur/Row";
import SelectPreparator from "./SelectPreparator";
import "../preparateur/Row.css";
import DonwloadButton from "./DonwloadButton";
import React, { useState } from "react";
import axios from "axios";

export const truncate = (str: string, n: number) => {
  return str?.length > n ? str.slice(0, n) + "..." : str;
};

export default function RowPreparator({
  projets,
  preparators,
  onClick,
}: {
  projets: [];
  preparators: [];
  onClick: () => void;
}) {
  const [projectId, setProjectId] = useState<number>(0);

  const handleAssignedUser = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = Number(e.target.value);

    const data = {
      userId: userId,
    };
    try {
      axios.patch(`${process.env.REACT_APP_API_URL}/projet/${projectId}`, data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="row-container">
      <Header
        titleFile="Fichier"
        titlePrepa="Assigné a"
        titleProjet="Nom du projet"
        titleStatus="Status"
      />
      {projets.map((projet: MapInterface) => (
        <div className="row-direction" key={projet.projetId}>
          <div className="row-item projet">{projet.projetName}</div>
          <div className="row-item file" title={projet.File.fileName}>
            {truncate(projet.File.fileName, 13)}
            <span>
              <DonwloadButton onClick={onClick} />
            </span>
          </div>
          <div className="row-item status">{projet.projetStatus}</div>
          <div className="row-item preparator">
            <SelectPreparator
              preparators={preparators}
              onChange={handleAssignedUser}
              onClick={() => setProjectId(projet.projetId)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
