import React from "react";
import { IoMapOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Card from "../../layout/cards/Card";
import "./MappingNavigation.css";
export default function MappingNavigation() {
	const mapping_navigation = [
		{
			icon: <IoMapOutline size={24} />,
			title: "Gérer le mapping des équipes",
			link: "/dashboard/admin/mapping/teams",
		},
		{
			icon: <IoMapOutline size={24} />,
			title: "Gérer le mapping des équipements",
			link: "/dashboard/admin/mapping/stuffs",
		},
		{
			icon: <IoMapOutline size={24} />,
			title: "Gérer le mapping MapWood",
			link: "/dashboard/admin/mapping/mapwood",
		},

		{
			icon: <IoMapOutline size={24} />,
			title: "Gérer le mapping R6 vers R-X",
			link: "/dashboard/admin/mapping/R6-Rx",
		},
		{
			icon: <IoMapOutline size={24} />,
			title: "Uploader un nouveau type de fichier",
			link: "/dashboard/admin/header",
		},
	];

	const createCard = (
		icon: JSX.Element,
		title: string,
		link: string
	): JSX.Element => {
		return (
			<Card number={""} display={false}>
				<p className="card-icon-container">
					<span className="icon">{icon}</span>
					{title}
				</p>
				<Link to={link} className="link-btn">
					Gérer
				</Link>
			</Card>
		);
	};
	return (
		<div className="mapping_navigation">
			<h1>Gérer les mappings</h1>
			{mapping_navigation.map((item, index: number) => (
				<div key={index}>{createCard(item.icon, item.title, item.link)}</div>
			))}
		</div>
	);
}
