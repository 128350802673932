import React from "react";

type DividerType = {
	color?: string;
	marginTop?: number;
};

export default function Divider({ color, marginTop = 0 }: DividerType) {
	return (
		<div style={{ width: "100%" }}>
			<div
				style={{ backgroundColor: color, height: 1, marginTop: marginTop }}
			></div>
		</div>
	);
}
