import React, { useEffect, useRef, useState } from "react";
import "./SelectWithFilter.css";

interface SelectWithFilterProps {
	data: any;
	selectedOption: string;
	setSelectedOption: (value: string, col_R6: string) => void;
}

export default function SelectWithFilter({
	data,
	selectedOption,
	setSelectedOption,
}: SelectWithFilterProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const ref = useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	// create default value with mapping below each column

	return (
		<div className="custom-select" ref={ref}>
			<div className="selected-option" onClick={handleToggle}>
				{selectedOption ? selectedOption : "Sélectionner une valeur..."}
			</div>
			{isOpen && (
				<div className="options">
					<input
						type="text"
						placeholder="Rechercher..."
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
						}}
						className="search-input"
						autoComplete="on"
						autoFocus={isOpen ? true : false}
					/>
					<p
						id="no-corres"
						className="search-result"
						onClick={() => {
							setSelectedOption("no-corres", "null");
							setIsOpen(false);
						}}
					>
						Null - pas de correspondance
					</p>
					{data
						?.sort(
							(
								a: {
									headerColumn: string;
								},
								b: { headerColumn: string }
							) => {
								if (a.headerColumn.length != b.headerColumn.length) {
									return a.headerColumn.length - b.headerColumn.length;
								}
								return a.headerColumn.localeCompare(b.headerColumn);
							}
						)
						?.filter((item: { headerValue: string }) => {
							return item?.headerValue
								?.toString()
								?.toUpperCase()

								.includes(searchValue?.toString()?.toUpperCase());
						})
						?.map((item: any, index: number) => (
							<p
								key={index}
								className="search-result"
								onClick={() => {
									setSelectedOption(item?.headerValue, item?.headerColumn);
									setIsOpen(false);
								}}
								id={item?.headerColumn}
							>
								{`${item?.headerColumn} - ${item?.headerValue}`}
							</p>
						))}
				</div>
			)}
		</div>
	);
}
