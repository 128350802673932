import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LoginContext, LoginType } from "../../contexts/LoginContext";
import FilterInput from "../../components/filter/FilterInput";
import "./AllProjectPage.css";
import ResponsiveTable from "../../components/utils/array/ResponsiveTable";
import Accordeon from "../../components/utils/accordeon/Accordeon";
import { UserContext, UserContextInterface } from "../../contexts/UserContext";

export default function PrepaScreen() {
  const { token } = useContext(LoginContext) as LoginType;
  const { currentUserId } = useContext(UserContext) as UserContextInterface;

  const [filteredProjet, setFilteredProjet] = useState<string>("");
  const [userData, setUserData] = useState<[]>([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/projet/users/${currentUserId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    if (currentUserId === 0) {
      return;
    }

    fetchData();
  }, [token, currentUserId]);

  return (
    <div className="prepa-screen">
      <div>
        <FilterInput
          setFilteredProjet={setFilteredProjet}
          value={filteredProjet}
        />
      </div>
      <Accordeon />

      <ResponsiveTable projets={userData} filteredProjet={filteredProjet} />
    </div>
  );
}
