import axios from "axios";
import { useEffect, useState } from "react";
import "./CreateUserPage.css";
import Popover from "../../components/modal/Popover";
import Breadcrumb from "../../components/utils/breadCrumb/Breadcrumb";
import { FloatInput } from "../../components/utils/inputs/FloatInput";
import SelectRole from "../../components/utils/select/SelectRole";

export default function CreateNewUserPage() {
	const [userName, setUserName] = useState<string>("");
	const [userEmail, setUserEmail] = useState<string>("");
	const [userPhone, setUserPhone] = useState<string>("");
	const [userPassword, setUserPassword] = useState<string>("");
	const [userRole, setUserRole] = useState<string>("");
	const [preparator, setPreparator] = useState<[]>([]);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [validation, setValidation] = useState<string>("");
	const createUser = async (e: React.FormEvent) => {
		e.preventDefault();
		const data = {
			userName: userName,
			userEmail: userEmail,
			userPhone: userPhone,
			userPassword: userPassword,
			roleRoleId: Number(userRole),
		};

		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/user`, data);
			setValidation("Votre nouvel utilisateur a bien été créé");
			setIsOpen(true);
		} catch (err: unknown) {
			setIsOpen(true);
			setValidation(
				"Il y a eu une erreur dans la création de votre utilisateur"
			);
			console.error({
				message: "il y a eu une erreur dans le création de votre utilisateur",
				console: err,
			});
		} finally {
			setTimeout(() => {
				setIsOpen(false);
			}, 2000);
		}
	};

	useEffect(() => {
		const getRole = async () => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/role`);
				setPreparator(res.data);
			} catch (error: unknown) {
				console.error(error);
			}
		};
		getRole();
	}, []);

	const handleChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserName(e.target.value);
	};
	const handleChangesUserEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserEmail(e.target.value);
	};
	const handleChangeUserPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserPassword(e.target.value);
	};
	const handleChangeUserPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserPhone(e.target.value);
	};

	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "Create user", path: "/dashboard/admin/create" },
	];
	return (
		<div className="page-container">
			<h1 className="page-title">Créer un nouvel utilisateur</h1>

			<Breadcrumb items={items} />
			<div className="form-container">
				<div className="form-box">
					<form onSubmit={createUser} className="user-form">
						<div className="input-form">
							<FloatInput
								inputLabel="Nom et prénom"
								onChange={handleChangeUserName}
								value={userName}
							/>
						</div>
						<div className="input-form">
							<FloatInput
								inputLabel="Email"
								value={userEmail}
								onChange={handleChangesUserEmail}
							/>
						</div>
						<div className="input-form">
							<FloatInput
								inputLabel="Mot de passe"
								onChange={handleChangeUserPassword}
								value={userPassword}
							/>
						</div>
						<div className="input-form">
							<FloatInput
								inputLabel="Numéro de téléphone"
								onChange={handleChangeUserPhone}
								value={userPhone}
							/>
						</div>
						<div className="input-form">
							<SelectRole roleData={preparator} setValue={setUserRole} />
						</div>
						<div className="input-form">
							<button className="btn-form">Créer</button>
						</div>
					</form>
				</div>
				<Popover isOpen={isOpen}>
					<p>{validation}</p>
				</Popover>
			</div>
		</div>
	);
}
