import axios from "axios";
import React, { useState, useEffect } from "react";
import useRedirect from "../../hook/useRedirect";
import GenericBtn from "../layout/buttons/generic/GenericBtn";
import { FloatInput } from "../utils/inputs/FloatInput";
import "./LoginForm.css";
import elagage from "../../assets/elagage.png";
import packageJson from "../../../package.json";
import Popover from "../modal/Popover";

interface UserDataI {
	email: string;
	password: string;
}

export default function LoginForm({
	setIsLoggedIn,
}: {
	setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
	const [userData, setUserData] = useState<UserDataI>({
		email: "",
		password: "",
	});
	const [token, setToken] = useState<string>("");
	const [role, setRole] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const navigate = useRedirect();

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		const varData = {
			userEmail: userData.email,
			userPassword: userData.password,
		};
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/auth`,
				varData
			);
			setRole(await response.data.userRole);
			setToken(await response.data.token);
			if (response.status === 200) {
				navigate("/");
			}
		} catch (error: any) {
			// if server don't respond return error perso
			if (error.message === "Network Error") {
				setError("Serveur injoignable, veuillez ré-essayer.");
				return;
			}
			setError("Email ou mot de passe incorrect, veuillez ré-essayer.");
			console.error(error);
		}
	};

	const storeData = async (key: string, value: string) => {
		try {
			const jsonValue = JSON.stringify(value);
			localStorage.setItem(key, jsonValue);
		} catch (e) {
			console.error(e);
		} finally {
			if (token) {
				setIsLoggedIn(true);
			}
		}
	};

	useEffect(() => {
		if (token) {
			storeData("token", token);
			storeData("role", role);
		}
	}, [token]);

	const getResetToken = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const emailData = {
			userEmail: email,
		};
		try {
			await axios.post(
				`${process.env.REACT_APP_API_URL}/auth/reset`,
				emailData
			);
		} catch (error: any) {
			console.error(error);
		}
	};
	/**
	 * fonction pour ouvrir ou fermer le popover
	 * feature for open or close the popover
	 */
	const handleOpen = () => {
		setOpen(!open);
	};

	return (
		<div className="login_container">
			<div className="version_container">
				<p className="version_app">version {packageJson.version}</p>
			</div>

			<div className="image_container">
				<img src={elagage} className="image_ja" />
			</div>
			<div className="login_box">
				<div className="login_header">
					<h1>Connexion</h1>
				</div>
				<form onSubmit={handleLogin}>
					<div className="login_body">
						<div className="login_input">
							<FloatInput
								inputLabel="Email"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setUserData({ ...userData, email: e.target.value })
								}
								value={userData.email}
							/>
						</div>
						<div className="login_input">
							<FloatInput
								inputLabel="Mot de passe"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setUserData({ ...userData, password: e.target.value })
								}
								value={userData.password}
								type="password"
							/>
						</div>

						{error && (
							<div className="login_error">
								<span>{error}</span>
							</div>
						)}
					</div>
					<div className="forgotten_password_container">
						<p onClick={handleOpen} className="forgotten_password_text">
							Mot de passe oublié ?
						</p>
					</div>
					<div className="login_btn">
						<GenericBtn text="Connexion" />
					</div>
				</form>
			</div>
			<Popover isOpen={open} onClick={handleOpen}>
				<form
					className="reset_container"
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
						getResetToken(e);
						handleOpen();
					}}
				>
					<p>
						Veuillez renseigner votre email, un lien de réinitialisation vous
						sera envoyé.
					</p>
					<div className="reset_box_input">
						<input
							type="text"
							placeholder="Email"
							className="reset_input"
							value={email}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setEmail(e.target.value)
							}
						/>
					</div>
					<div className="reset_btn">
						<GenericBtn text="Envoyer" />
					</div>
				</form>
			</Popover>
		</div>
	);
}
