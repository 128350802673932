import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import GenericBtn from "../components/layout/buttons/generic/GenericBtn";
import ValidateStepBtn from "../components/layout/buttons/validate/ValidateStepBtn";
import { FloatInput } from "../components/utils/inputs/FloatInput";

import { LoginContext, LoginType } from "../contexts/LoginContext";
import { UserContext, UserContextInterface } from "../contexts/UserContext";
import { useToasts } from "../hook/useToasts";
import "./ProfilePage.css";

export interface UserInterface {
  userName: string;
  userPhone: string;
  userEmail: string;
  userPassword: string;
}

export default function ProfilePage() {
  const { token } = useContext(LoginContext) as LoginType;
  const { users } = useContext(UserContext) as UserContextInterface;
  const { pushToast } = useToasts();
  const getInitials = (name: string): string => {
    const names = name?.split(" ");
    if (!names) return "";
    const firstNameInitial = names[0]?.substring(0, 1)?.toUpperCase();
    const lastNameInitial = names[1]?.substring(0, 1)?.toUpperCase();
    return firstNameInitial + lastNameInitial;
  };
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (password !== confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas");
    } else if (password === confirmPassword) {
      setErrorMessage("les mots de passe correspondent");
    }
  }, [confirmPassword]);

  const updateData = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    const userData = {
      userPassword: password,
      userPhone: phone,
    };
    if (password !== confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas");
    }
    try {
      axios.patch(`${process.env.REACT_APP_API_URL}/user`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      pushToast({
        type: "success",
        message: "Vos informations ont bien été modifiées",
      });
    } catch (err: unknown) {
      pushToast({
        type: "error",
        message: "Une erreur est survenue",
      });
      console.error(err);
    }
  };

  if (!users) return <div>Loading...</div>;
  return (
    <div className="profile_page">
      <h1>Mon profil</h1>

      <div className="profile_badge_container">
        <div className="profile_badge_box">
          <div className="profile_badge">
            <p className="profile_badge_text">{getInitials(users?.userName)}</p>
          </div>
        </div>
        <div className="profile_badge_box">
          <div className="profile_info">
            <h1>Informations personnelles</h1>
            <div className="profile_text">
              <p className="text_bold">Nom Prénom</p>
              <p className="text_italic">{users.userName}</p>
            </div>
            <div className="profile_text">
              <p className="text_bold">Email</p>
              <p className="text_italic">{users.userEmail}</p>
            </div>
            <div className="profile_text">
              <p className="text_bold">Numéro de téléphone</p>
              <p className="text_italic">{users?.userPhone}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile_form">
        <div className="">
          <div>
            <h2>Modifier mes informations</h2>
          </div>
          <div>
            <h3>Modifier mon mot de passe</h3>
            <div className="profile_input">
              <FloatInput
                inputLabel="Mot de passe"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                value={password}
                type="password"
              />
            </div>
            <div className="profile_input">
              <FloatInput
                inputLabel="Confirmer le mot de passe"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
                value={confirmPassword}
                type="password"
              />
            </div>
            {errorMessage && confirmPassword.length >= 1 && (
              <p className="error_message">{errorMessage}</p>
            )}
          </div>
          <div>
            <h3>Modifier mon numéro de téléphone</h3>
            <div className="profile_input">
              <FloatInput
                inputLabel="Téléphone"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPhone(e.target.value)
                }
                value={phone}
              />
            </div>
          </div>
          <GenericBtn text="Valider" onClick={updateData} />
        </div>
      </div>
    </div>
  );
}
