import React from "react";
import UserList from "../../components/admin/userList/UserList";
import Breadcrumb from "../../components/utils/breadCrumb/Breadcrumb";

export default function UserListPage() {
	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "User", path: "/dashboard/admin/list" },
	];
	return (
		<div className="page-container">
			<h1 className="">Gérer les droits d'accès</h1>
			<Breadcrumb items={items} />
			<UserList />
		</div>
	);
}
