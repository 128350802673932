import { utils } from "xlsx";

import { changeFileExtension } from "./changeFileExtension";
import { objType } from "./exportToCSV";

export const donwloadFile = (data: any, fileName: string) => {
	const val = data.map((item: any) =>
		item.map((i: [{ val: string }]) => i[0].val)
	);

	const object = val.map((item: unknown[]) => {
		const obj: objType = {};
		item.forEach((i: any, index: number) => {
			obj[val[0][index]] = i;
		});
		return obj;
	});

	const removeFirstRow = object.slice(1);
	//* convert to sheet
	const ws = utils.json_to_sheet(removeFirstRow);
	//* change the separator to semi-comma
	const csv = utils.sheet_to_csv(ws, { FS: ";" });
	const encodedData = "\ufeff" + csv; // ajoute le BOM pour l'encodage UTF-8

	if (data) {
		const blob = new Blob([encodedData], {
			type: "text/csv;charset=utf-8;",
		});
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", changeFileExtension(fileName, "csv"));
		document.body.appendChild(link);
		link.click();
	}
};
