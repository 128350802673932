import axios from "axios";
import React, { useEffect, useState } from "react";
import { parseCharSpe } from "../../functions/replaceCharSpe";
import { useToasts } from "../../hook/useToasts";
import Breadcrumb from "../utils/breadCrumb/Breadcrumb";
import { FloatInput } from "../utils/inputs/FloatInput";
import "./PricePerKilo.css";

export interface IPricePerKilo {
	brasIsole: number;
	elagage: number;
	bucheronnage: number;
	lamier: number;
	grue: number;
	robot: number;
}

export interface TeamData {
	distance: number;
	equipeId: string;
	equipe: string;
	// Autres propriétés nécessaires pour teamsData
}

interface IPricePerKiloProps {
	pricePerKilo: IPricePerKilo;
	setPricePerKilo: (pricePerKilo: IPricePerKilo) => void;
}

export default function PricePerKilo() {
	const { pushToast } = useToasts();

	const [teams, setTeams] = useState([]);
	const [dicos, setDicos] = useState([]);
	const [dico, setDico] = useState<{ dicoId: string; dicoVersion: string }>({
		dicoId: "1",
		dicoVersion: "",
	});
	const [teamsData, setTeamsData] = useState<TeamData[]>([]);

	const postTime = () => {
		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/equipes`, teamsData);
			pushToast({
				type: "success",
				message: "Les données ont bien été envoyées",
			});
		} catch (error) {
			pushToast({ type: "error", message: "Une erreur est survenue" });
			console.error(error);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/dico`
				);

				setDicos(response.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	const getVersions = (dico: string): string[] => {
		const dicoVersion = dico.split("");
		const dicoVersionNumber = dicoVersion.filter((item) => {
			return !isNaN(Number(item));
		});

		return dicoVersionNumber;
	};

	useEffect(() => {
		(async () => {
			try {
				const responses = await axios.get(
					`${process.env.REACT_APP_API_URL}/equipes/${dico.dicoId}`
				);
				setTeams(responses.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, [dico.dicoId]);

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		equipeId: number
	) => {
		const newTeamsData: any = [...teamsData];
		const index = newTeamsData.findIndex(
			(item: { equipe_id: number }) => item.equipe_id === equipeId
		);
		if (index >= 0) {
			newTeamsData[index] = {
				...newTeamsData[index],
				equipe_id: equipeId,
				distance: event.target.value,
			};
		} else {
			newTeamsData.push({
				equipe_id: equipeId,
				distance: event.target.value,
				equipe: event.target.name,
			});
		}
		setTeamsData(newTeamsData);
	};
	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "Parametre application", path: "/dashboard/admin/time" },
	];

	return (
		<div className="page_container_time_form">
			<Breadcrumb items={items} />

			<h1 className="title_time">
				Distances en mètres par jour et par équipes
			</h1>
			<div className="nav_dico">
				{dicos.map((item: { dicoId: string; dicoVersion: string }) => (
					<div
						onClick={() =>
							setDico({ dicoId: item.dicoId, dicoVersion: item.dicoVersion })
						}
						className={
							item.dicoId === dico.dicoId
								? "dico_version active"
								: "dico_version"
						}
						key={item.dicoId}
					>
						dico {getVersions(item.dicoVersion)}
					</div>
				))}
			</div>
			<form className="formulaire" onSubmit={postTime}>
				{teams
					.sort(
						(a: { equipeId: number }, b: { equipeId: number }) =>
							a.equipeId - b.equipeId
					)
					.map(
						(
							item: {
								equipeId: number;
								equipeDescription: string;
								equipeDistanceGenerale: number;
							},
							index: number
						) => (
							<div className="toto" key={item.equipeId}>
								<FloatInput
									name={item.equipeDescription}
									inputLabel={parseCharSpe(item.equipeDescription)}
									defaultValue={item.equipeDistanceGenerale}
									onChange={(event) => handleInputChange(event, item.equipeId)}
								/>
							</div>
						)
					)}
				<div className="time_btn">
					<button className="time_submit">send</button>
				</div>
			</form>
		</div>
	);
}
