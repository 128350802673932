import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { LoginContext, LoginType } from "../../../contexts/LoginContext";
import { useNavigate } from "react-router-dom";
import "./SelectProject.css";

export default function SelectProject() {
  const [userData, setUserData] = useState([]);
  const { token } = useContext(LoginContext) as LoginType;
  const history = useNavigate();
  const [count, setCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/projet/number`,
          {
            signal: controller.signal,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCount(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  });

  useEffect(() => {
    if (!token && count === undefined) {
      return;
    }

    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/projet/projets?page=${1}&perPage=${count}`,
          {
            signal: controller.signal,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data.projects);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [token, count]);

  function handleChange(e: any) {
    if (e) {
      history(`/projet/${e}`);
    }
  }

  return (
    <div>
      <p className="select_prepa_label">Changer de dossier</p>
      <Select
        onChange={(e) => handleChange(e?.value)}
        options={userData.map((project: any) => ({
          value: project.projetId,
          label: project.projetName,
        }))}
        className="select-prepa"
      />
    </div>
  );
}
