import Loader from "../loading/Loader";
import "./LoadingPopover.css";
export default function LoadingPopover({
	isLoading,
	message,
}: {
	isLoading: boolean;
	message: String;
}) {
	return (
		<div
			className={isLoading ? "load_popover_container" : "load_popover_close"}
		>
			<div className="load_popover_paper">
				<div className="load_popover_body">
					<p>{message}</p>
					<Loader />
				</div>
			</div>
		</div>
	);
}
