import { changeFileExtension } from "./changeFileExtension";

export const downloadFileFromAPI = (
  data: any,
  fileName: string,
  extension: string
) => {
  const blob = new Blob([data], {
    type: "text/csv;charset=utf-8;",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const newFileName = changeFileExtension(fileName, extension);
  link.setAttribute("download", newFileName);
  document.body.appendChild(link);
  link.click();
};
