import React from "react";
import { Link } from "react-router-dom";
import Card from "../../layout/cards/Card";
import {
  IoPersonAddOutline,
  IoLockOpenOutline,
  IoClipboardOutline,
  IoConstructOutline,
  IoMapOutline,
} from "react-icons/io5";
import "./Dashboard.css";

export default function Dashboard() {
  const navigation = [
    {
      icon: <IoPersonAddOutline size={24} />,
      title: "Créer un nouvel utilisateur",
      link: "/dashboard/admin/create",
    },
    {
      icon: <IoLockOpenOutline size={24} />,
      title: "Gérer les droits",
      link: "/dashboard/admin/list",
    },
    {
      icon: <IoClipboardOutline size={24} />,
      title: "Charger un nouveau dico",
      link: "/dashboard/admin/upload-dico",
    },
    {
      icon: <IoConstructOutline size={24} />,
      title: "Paramétrer les distances par équipe de l'application",
      link: "/dashboard/admin/time",
    },
    {
      icon: <IoMapOutline size={24} />,
      title: "Gérer le mapping",
      link: "/dashboard/admin/mapping",
    },
  ];

  const createCard = (
    icon: JSX.Element,
    title: string,
    link: string
  ): JSX.Element => {
    return (
      <Card number={""} display={false}>
        <p className="card-icon-container">
          <span className="icon">{icon}</span>
          {title}
        </p>
        <Link to={link} className="link-btn">
          Gérer
        </Link>
      </Card>
    );
  };

  // const createDashboardCard = () => {
  //   return (
  //     <Card number={""} display={false}>
  //       <p>Nombre d'utilisateurs : 5</p>
  //       <p>Mapping R3 :✅</p>
  //       <p>Mapping R4 ✅</p>
  //       <p>Mapping R5 : ✅</p>
  //       <p>Mapping Mapwood : ✅</p>
  //     </Card>
  //   );
  // };

  return (
    <div className="dashboard-container">
      <h1>Administration de l'application JA-ELATOOLS</h1>
      {/* {createDashboardCard()} */}

      {navigation.map((item, index: number) => (
        <div key={index}>{createCard(item.icon, item.title, item.link)}</div>
      ))}
    </div>
  );
}
