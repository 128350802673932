import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { UserInterface } from "../pages/ProfilePage";

export interface UserContextInterface {
  getUserData: () => void;
  users: UserInterface;
  setUsers: React.Dispatch<React.SetStateAction<UserInterface>>;
  currentUserId: number;
  setCurrentUserId: React.Dispatch<React.SetStateAction<number>>;
  currentUserName: string;
}

export const UserContext = createContext<UserContextInterface | null>(null);

function UserProvider({ children }: { children: React.ReactNode }) {
  const [users, setUsers] = useState<UserInterface>({} as UserInterface);
  const token = localStorage.getItem("token");
  const parsedToken = JSON.parse(token as string);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [currentUserName, setCurrentUserName] = useState<string>("");

  const getUserData = async () => {
    if (!parsedToken) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/users`,
        {
          headers: {
            Authorization: `Bearer ${parsedToken}`,
          },
        }
      );
      setUsers(response.data);
      setCurrentUserId(response.data.userId);
      setCurrentUserName(response.data.userName);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, [parsedToken]);

  return (
    <UserContext.Provider
      value={{
        getUserData,
        users,
        setUsers,
        currentUserId,
        setCurrentUserId,
        currentUserName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
