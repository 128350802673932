export const error_guid = {
  errors: [
    {
      error_title: "Erreur 1",
      error_rule:
        "Si la colonne Q (Numérotati) possède deux fois la même valeur = erreur",
    },
    {
      error_title: "Erreur 2",
      error_rule: "Si la colonne R (Debutlong1) est vide = erreur",
    },
    {
      error_title: "Erreur 3",
      error_rule: "Si la colonne S (Debutlati1) est vide = erreur",
    },
    {
      error_title: "Erreur 4",
      error_rule:
        'Si la colonne Y (Avisduprop) est égale à "ABSP - Absence du propriétaire" = erreur',
    },
    {
      error_title: "Erreur 5",
      error_rule:
        'Si la colonne AL (Modedereal) est égale à "EMAN - Elagage manuel" OU "JARR - Elagage par Jarraf ET toutes les colonnes équipes sont égales à "Non"',
    },
    {
      error_title: "Erreur 6",
      error_rule:
        'Si la colonne AL (Modedereal) est égale à "NDEF - Non défini" ET au moins une équipe est égale à "Oui"',
    },
    {
      error_title: "Erreur 7",
      error_rule:
        'Si la colonne [AL (Modedereal) est égale à "JARR - Elagage par Jarraf" ET la colonne BA (EqBI) = Non ET la colonne BE (EqRobot) sont égales à "Non"',
    },
    {
      error_title: "Erreur 8",
      error_rule:
        'Si la colonne AL (Modedereal) est égale à "EMAN – Elagage manuel" ET EqGrap = Non ET EqEpar = Non ET EqBroyFo = Non ET EqPelleAr = Non ET EqElag = Non ET EqBuch = Non',
    },
    {
      error_title: "Erreur 9",
      error_rule:
        'Si la colonne AC (Longueurel) est égale à 0 ET la colonne AT (Termine) = "Non"',
    },
    {
      error_title: "Erreur 10",
      error_rule: "Si Couleur est vide",
    },
    {
      error_title: "Erreur 11",
      error_rule: "Si AG=U0 alors AI=O",
    },
    {
      error_title: "Erreur 12",
      error_rule: "Si AG=U1 ET AL = EMAN - Elagage manuel ALORS AI=O",
    },
    {
      error_title: "Erreur 13",
      error_rule: "Si AI=O ALORS AJ différent de 0",
    },
    {
      error_title: "Erreur 14",
      error_rule:
        "Si Y = ELPP - Elagage par le propriétaire ALORS AC=0 Sinon Erreur (on met en surbrillance les cellules Y et AC)",
    },
    {
      error_title: "Erreur 15",
      error_rule:
        "Si Y = ELPP - Elagage par le propriétaire  ALORS AL = NDEF - Non défini SINON Erreur (on met en surbrillance les cellules Y et AL)",
    },
    {
      error_title: "Erreur 16",
      error_rule:
        "Si Y = RFPP - Refus du propriétaire ALORS AL = NDEF - Non défini ALORS AT = Oui ALORS AC = 0 SINON Erreur (on met en surbrillance les cellules Y et AL et AT et AC)",
    },
    {
      error_title: "Erreur 17",
      error_rule:
        "Si AL = NDEF - Non défini ALORS (Y= ELPP - Elagage par le propriétaire ou Y = RFPP - Refus du propriétaire) ALORS AT = Oui SINON Erreur (on met en surbrillance les cellules Y et AL et AT)",
    },
    {
      error_title: "Erreur 18",
      error_rule:
        "Si Y = ELPP - Elagage par le propriétaire ALORS AT(Terminé) = Oui SINON Erreur (on met en surbrillance lescellules Y / AT)",
    },
    {
      error_title: "Erreur 19",
      error_rule:
        "Si Y = ABSENCE PROPRIETAIRE alors ProcAbs (Col BW) <> Sans sinon ERREUR",
    },
    {
      error_title: "Erreur 20",
      error_rule: "Desactivé",
    },
    {
      error_title: "Erreur 21",
      error_rule: `Si AC > 0 ET EqBI = Non 
        ET EqRobot = Non 
        ET EqGrap = Non 
        ET EqEpar = Non 
        ET EqBroyFo = Non 
        ET EqPelleAr = Non 
        ET EqElag = Non 
        ET EqBuch = Non 
        ET Si Y <> ELPP - Elagage par le propriétaire`,
    },
  ],
};
