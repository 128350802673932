import { createContext, ReactNode, useEffect, useState } from "react";

export type LoginType = {
	isLogged: boolean;
	setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
	token: string;
	setToken: React.Dispatch<React.SetStateAction<string>>;
	deleteToken: () => void;
	role: string;
	setRole: React.Dispatch<React.SetStateAction<string>>;
};

export const LoginContext = createContext<LoginType | null>(null);

export default function ModalProvider({ children }: { children: ReactNode }) {
	const [isLogged, setIsLogged] = useState<boolean>(false);
	const [token, setToken] = useState<string>("");
	const [role, setRole] = useState<string>("");

	useEffect(() => {
		try {
			const getJson = localStorage.getItem("token");
			const getRole = localStorage.getItem("role");
			if (getJson !== null && getRole !== null) {
				setToken(JSON.parse(getJson));
				setRole(JSON.parse(getRole));
			}
		} catch (e: unknown) {
			console.error(e);
		}
	}, []);

	const deleteToken = () => {
		try {
			localStorage.removeItem("token");
			localStorage.removeItem("role");
			localStorage.removeItem("userId");
			setIsLogged(false);
		} catch (e: unknown) {
			console.error(e);
		}
	};

	return (
		<LoginContext.Provider
			value={{
				isLogged,
				setIsLogged,
				token,
				setToken,
				deleteToken,
				role,
				setRole,
			}}
		>
			{children}
		</LoginContext.Provider>
	);
}
