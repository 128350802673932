import { ComponentProps, createContext, useRef } from "react";
import Toast from "../components/toast/Toast";
import { Toasts } from "../components/toast/Toasts";

export type Params = ComponentProps<typeof Toast>;

const defaultPush = (toast: Params) => {};

export const ToastContext = createContext({
  pushToastRef: { current: defaultPush },
});

export default function ToastProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const pushToastRef = useRef(defaultPush);

  return (
    <ToastContext.Provider value={{ pushToastRef }}>
      <Toasts />
      {children}
    </ToastContext.Provider>
  );
}
