import React from "react";
import { Link } from "react-router-dom";
import { truncate } from "../../assistant/RowPreparator";
import "./ResponsiveTable.css";
import { HiOutlineTrash } from "react-icons/hi2";

function Thead({
  setPreparatorState,
  setCompanyState,
  preparatorArray,
  companyArray,
}: {
  setPreparatorState: React.Dispatch<React.SetStateAction<string>>;
  setCompanyState: React.Dispatch<React.SetStateAction<string>>;
  preparatorArray: Array<object>;
  companyArray: Array<object>;
}) {
  const role = JSON.parse(localStorage.getItem("role") as string);
  return (
    <thead className="responsive-table">
      <tr className="responsive-table thead_row">
        <th className="responsive-table__head__title thead_cell">
          Nom du dossier
        </th>
        <th className="responsive-table__head__title thead_cell">
          <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setCompanyState(e.target.value)
            }
            className="responsive_select"
          >
            <option value="">Société</option>
            {companyArray?.map((item: any, index: number) => (
              <option value={item.companyName} key={index}>
                {item.companyName}
              </option>
            ))}
          </select>
        </th>
        <th className="responsive-table__head__title thead_cell">Etape</th>
        <th className="responsive-table__head__title thead_cell">
          <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setPreparatorState(e.target.value)
            }
            className="responsive_select"
          >
            <option value="">Préparateur</option>
            {preparatorArray?.map((item: any, index: number) => (
              <option value={item.userName} key={index}>
                {item.userName}
              </option>
            ))}
          </select>
        </th>
        <th className="responsive-table__head__title thead_cell">Fichier</th>
        <th className="responsive-table__head__title thead_cell">
          Ouvert au Prepa
        </th>
        {role === "ADMIN" && (
          <th className="responsive-table__head__title thead_cell">
            Supression
          </th>
        )}
      </tr>
    </thead>
  );
}

function Tbody({
  projets,
  filteredProjet,
  filteredPreparator,
  filteredCompany,
  onClick,
}: {
  projets: [];
  filteredProjet?: string;
  filteredPreparator?: string;
  filteredCompany?: string;
  onClick: (id: number) => void;
}) {
  const role = JSON.parse(localStorage.getItem("role") as string);
  return (
    <tbody className="responsive-table tbody">
      {projets
        .filter((itemFilter: any) => {
          // nous stockons les résultats de chaque test de
          // correspondance avec includes() dans des variables
          const projectNameMatch = itemFilter.projetName
            ?.toUpperCase()
            .includes(filteredProjet?.toUpperCase());
          const userMatch = itemFilter.User.userName
            ?.toUpperCase()
            .includes(filteredPreparator?.toUpperCase());
          const companyMatch = itemFilter.Company.companyName
            ?.toUpperCase()
            .includes(filteredCompany?.toUpperCase());
          return (
            // on retour true si les valeurs filtrées sont vides
            // ou si les tests sont réussis pour chaque valeur.
            (!filteredProjet || projectNameMatch) &&
            (!filteredPreparator || userMatch) &&
            (!filteredCompany || companyMatch)
          );
        })
        .map((projet: any) => (
          <tr className="responsive-table tbody_row" key={projet.projetId}>
            <td className="responsive-table__body__text  tbody_cell tprojet">
              <Link to={`/projet/${projet.projetId}`} className="row_link">
                {projet.projetName}
              </Link>
            </td>
            <td className="responsive-table__body__text tbody_cell">
              {projet.Company.companyName}
            </td>
            <td className="responsive-table__body__text tbody_cell">
              {projet.Step.stepName}
            </td>
            <td className="responsive-table__body__text tbody_cell">
              {projet.User.userName}
            </td>
            <td className="responsive-table__body__text tbody_cell">
              {truncate(projet?.File?.fileName, 13)}
            </td>
            <td className="responsive-table__body__text tbody_cell">
              {projet.isOpenForPreparator === true ? "Oui" : "Non"}
            </td>
            {role === "ADMIN" && (
              <td className="responsive-table__body__text tbody_cell">
                <HiOutlineTrash
                  size={24}
                  color="black"
                  className="delete-icon"
                  onClick={() => onClick(projet.projetId)}
                />{" "}
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
}

function TableTag({ children }: { children?: JSX.Element }): JSX.Element {
  return <table className="responsive-table">{children}</table>;
}

export default function ResponsiveTableAssistant({
  projets,
  filteredProjet,
  filteredPreparator,
  setCompanyState,
  setPreparatorState,
  preparatorArray,
  companyArray,
  filteredCompany,
  handlePageChange,
  currentPage,
  numberOfPages,
  onClick,
}: {
  projets: [];
  filteredProjet: string;
  setCompanyState: React.Dispatch<React.SetStateAction<string>>;
  setPreparatorState: React.Dispatch<React.SetStateAction<string>>;
  filteredPreparator: string;
  preparatorArray: Array<object>;
  companyArray: Array<object>;
  filteredCompany?: string;
  handlePageChange: (currentPage: number) => void;
  currentPage: number;
  numberOfPages: number;
  onClick: (id: number) => void;
}) {
  return (
    <div className="container">
      <TableTag>
        <>
          <Thead
            setCompanyState={setCompanyState}
            setPreparatorState={setPreparatorState}
            preparatorArray={preparatorArray}
            companyArray={companyArray}
          />
          <Tbody
            projets={projets}
            filteredProjet={filteredProjet}
            filteredPreparator={filteredPreparator}
            filteredCompany={filteredCompany}
            onClick={onClick}
          />
        </>
      </TableTag>
      <div className="pagination-bar">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Précédent
        </button>

        <div className="page-numbers">
          {Array.from({ length: numberOfPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === numberOfPages}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
