import { Document, Page, Text, View } from "@react-pdf/renderer";
import { HeaderInterface } from "../../Types/Interfaces/Pdf-Interface";
import { styles } from "./StyleSheet";

export const PdfHeader = ({
	nom_du_dossier,
	ref_du_dossier,
	preparateur,
	longueur_total,
	longueur_tension,
	longueur_hors_tension,
	total_fic,
	montant_sous_tension,
	montant_hors_tension,
	dico_version,
	isProd,
}: HeaderInterface): JSX.Element => {
	const dateDuJour = new Date().toLocaleDateString();
	const now = new Date();
	const heureSansSecondes = now.getHours() + ":" + now.getMinutes(); // concatène l'heure et les minutes

	return (
		<View>
			<View style={styles.header_section}>
				<Text>
					{nom_du_dossier} / {ref_du_dossier}
				</Text>
				<Text>{isProd ? "Synthese de Production" : "Synthese de RAF"}</Text>
			</View>
			<View style={styles.info_header_column}>
				<View style={styles.information}>
					<Text style={styles.light_text}>{preparateur}</Text>
				</View>
				<View style={styles.information}>
					<Text>Longueur Total : {longueur_total} km</Text>
					<Text>Longueur sous tension : {longueur_tension} km</Text>
					<Text>Longueur hors tension : {longueur_hors_tension} km</Text>
				</View>
				<View style={styles.information}>
					<Text>Nombre Total de FIC : {total_fic} </Text>
					<Text>Montant sous tension : {montant_sous_tension} €</Text>
					<Text>Montant hors tension : {montant_hors_tension} €</Text>
					<Text>
						Montant total : {montant_sous_tension + montant_hors_tension} €
					</Text>
				</View>
				<View style={styles.info_header_column}>
					<View style={styles.information}>
						<Text style={styles.light_text}>
							{dateDuJour} {heureSansSecondes}
						</Text>
						<Text style={styles.light_text}>Dico Version v.{dico_version}</Text>
					</View>
				</View>
			</View>
		</View>
	);
};
