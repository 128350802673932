import axios from "axios";
import React, { useEffect, useState } from "react";
import GenericBtn from "../../../components/layout/buttons/generic/GenericBtn";
import { useToasts } from "../../../hook/useToasts";
import Breadcrumb from "../../../components/utils/breadCrumb/Breadcrumb";
import { parseCharSpe } from "../../../functions/replaceCharSpe";

export default function StuffMapping() {
	const [stuffs, setStuffs] = useState<[]>([]);
	const [dicos, setDicos] = useState<[]>([]);
	const [dico, setDico] = useState<{ dicoId: string; dicoVersion: string }>({
		dicoId: "",
		dicoVersion: "",
	});
	const [stuffColEdited, setStuffColEdited] = useState<any>([]);
	const [mergeArray, setMergeArray] = useState([]);
	const { pushToast } = useToasts();
	useEffect(() => {
		(async () => {
			try {
				const responses = await axios.get(
					`${process.env.REACT_APP_API_URL}/stuffs/${dico.dicoId}`
				);
				setStuffs(responses.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, [dico.dicoId]);

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/dico`
				);
				setDicos(response.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	const getVersions = (dico: string): string[] => {
		const dicoVersion = dico.split("");
		const dicoVersionNumber = dicoVersion.filter((item) => {
			return !isNaN(Number(item));
		});

		return dicoVersionNumber;
	};

	const patchColonnes = () => {
		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/stuffs`, mergeArray);
			pushToast({
				type: "success",
				message: "Les colonnes ont bien été modifiées",
			});
		} catch (error: unknown) {
			console.error(error);
		}
	};

	const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		const itemIndex = stuffColEdited.findIndex(
			(item: { equipement_id: number }) => item.equipement_id === Number(name)
		);
		if (itemIndex >= 0) {
			setStuffColEdited((prevData: any) =>
				prevData.map((item: any, index: number) =>
					index === itemIndex ? { ...item, value_col: value } : item
				)
			);
		} else {
			setStuffColEdited([
				...stuffColEdited,
				{
					equipement_id: Number(name),
					value_col: value,
				},
			]);
		}
	};

	const handleChangeInputPos = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		const itemIndex = stuffColEdited.findIndex(
			(item: { equipement_id: number }) => item.equipement_id === Number(name)
		);
		if (itemIndex >= 0) {
			setStuffColEdited((prevData: any) =>
				prevData.map((item: any, index: number) =>
					index === itemIndex ? { ...item, value_pos: value } : item
				)
			);
		} else {
			setStuffColEdited([
				...stuffColEdited,
				{
					equipement_id: Number(name),
					value_pos: value,
				},
			]);
		}
	};

	useEffect(() => {
		const duplicateArray = [...stuffColEdited];
		// in duplicateArray, we have the same team_id twice, so we need to merge the two objects
		const mergeArray = duplicateArray.reduce(
			(
				acc: { equipement_id: number }[],
				current: {
					equipement_id: number;
				}
			) => {
				const x = acc.find(
					(item: { equipement_id: number }) =>
						item.equipement_id === current.equipement_id
				);
				if (!x) {
					return acc.concat([current]);
				} else {
					return acc.map((item: { equipement_id: number }) =>
						item.equipement_id === current.equipement_id
							? { ...item, ...current }
							: item
					);
				}
			},
			[]
		);
		setMergeArray(mergeArray);
	}, [stuffColEdited]);
	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "Mapping", path: "/dashboard/admin/mapping" },
		{ label: "Equipements", path: "/dashboard/admin/mapping/stuffs" },
	];

	return (
		<div className="teams_mapping">
			<Breadcrumb items={items} />
			<div className="dico_navigation">
				{dicos.map((item: { dicoId: string; dicoVersion: string }) => (
					<div
						onClick={() =>
							setDico({ dicoId: item.dicoId, dicoVersion: item.dicoVersion })
						}
						className={
							item.dicoId === dico.dicoId
								? "dico_version active"
								: "dico_version"
						}
						key={item.dicoId}
					>
						dico {getVersions(item.dicoVersion)}
					</div>
				))}
			</div>
			{dico.dicoVersion === "" ? (
				<h1>Liste globale</h1>
			) : (
				<h1>dico {getVersions(dico.dicoVersion)}</h1>
			)}
			<div>
				<table className="var_cell var_table mapping_table">
					<thead>
						<tr className="var_head_row">
							<th className="var_th">Equipes</th>
							<th className="var_th">Descriptions</th>
							<th className="var_th">Colonnes</th>
							<th className="var_th">Positions</th>
						</tr>
					</thead>
					<tbody>
						{stuffs
							?.sort(
								(
									a: { equipementPos: number },
									b: {
										equipementPos: number;
									}
								) => a.equipementPos - b.equipementPos
							)
							.map((stuff: any) => (
								<tr className="var_body_row" key={stuff.equipementId}>
									<td className="var_td">{stuff.equipementName}</td>
									<td className="var_td">
										{parseCharSpe(stuff.equipementDescription)}
									</td>
									<td className="var_td">
										<input
											placeholder="Choissiez une colonne"
											defaultValue={stuff.equipementCol}
											onChange={handleChangeInput}
											name={stuff.equipementId.toString()}
											className="var_input_mapping"
										/>
									</td>
									<td className="var_td">
										<input
											placeholder="Choissiez une position"
											defaultValue={stuff.equipementPos}
											onChange={handleChangeInputPos}
											name={stuff.equipementId.toString()}
											className="var_input_mapping"
										/>
									</td>
								</tr>
								//
							))}
					</tbody>
				</table>
				<div className="teams_mapping_btn">
					<GenericBtn onClick={patchColonnes} text="Valider" />
				</div>
			</div>
		</div>
	);
}

// const { token } = useContext(LoginContext) as LoginType;
// <select
// 	className="var_select_mapping"
// 	onChange={(event) =>
// 		handleChangeSelect(event, stuff.equipementId)
// 	}
// 	value={stuff.variableId}
// >
// 	{variables.map(
// 		(variable: {
// 			variableId: string;
// 			variableName: string;
// 		}) => (
// 			<option
// 				key={variable.variableId}
// 				value={variable.variableId}
// 			>
// 				{variable.variableName}
// 			</option>
// 		)
// 	)}
// </select>

// const [variables, setVariables] = useState<[]>([]);

// const handleChangeSelect = (
// 	e: React.ChangeEvent<HTMLSelectElement>,
// 	teamId: number
// ) => {
// 	const { value } = e.target;

// 	// check if the team is already in the array
// 	const existingItem = stuffVarEdited.find(
// 		(item: { equipement_id: number }) => item.equipement_id === teamId
// 	);
// 	// if the team is already in the array, we update the variable_id
// 	if (existingItem) {
// 		setStuffVarEdited((prevData: any) =>
// 			prevData.map((item: any) =>
// 				item.equipementId === teamId ? { ...item, variable_id: value } : item
// 			)
// 		);
// 		// if the team is not in the array, we add it
// 	} else {
// 		setStuffVarEdited((prevData: any) => [
// 			...prevData,
// 			{
// 				equipement_id: teamId,
// 				variable_id: value,
// 			},
// 		]);
// 	}
// };

// useEffect(() => {
// 	(async () => {
// 		try {
// 			const responses = await axios.get(
// 				`${process.env.REACT_APP_API_URL}/variables`,
// 				{
// 					headers: {
// 						Authorization: `Bearer ${token}`,
// 					},
// 				}
// 			);
// 			setVariables(responses.data);
// 		} catch (error: unknown) {
// 			console.error(error);
// 		}
// 	})();
// }, []);
