import "./Popover.css";

export default function Popover({
	isOpen,
	children,
	onClick,
	showbtn = true,
}: {
	isOpen: boolean;
	children: JSX.Element;
	onClick?: () => void;
	showbtn?: boolean;
}): JSX.Element {
	return (
		<div className={isOpen ? "popover-container" : "popover-close"}>
			<div className="popover-paper">
				<div className="popover-close-btn">
					{showbtn && (
						<button onClick={onClick} className="popover-btn">
							X
						</button>
					)}
				</div>
				<div className="popover-wrapper">{children}</div>
			</div>
		</div>
	);
}
