import React from "react";

export default function SelectPreparator({
	preparators,
	onChange,
	onClick,
	className,
	required,
	defaultValue,
}: {
	preparators: [];
	onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onClick?: () => void;
	className?: string;
	required?: boolean;
	defaultValue?: string;
}) {
	return (
		<select
			onChange={onChange}
			onClick={onClick}
			className={className}
			required={required}
			defaultValue={defaultValue}
		>
			<option value="">assigner un preparateur au projet</option>
			{preparators.map((preparator: { userName: string; userId: number }) => (
				<option value={preparator.userId} key={preparator.userId}>
					{preparator.userName}
				</option>
			))}
		</select>
	);
}
