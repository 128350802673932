import React from "react";
import { error_guid } from "../../json/guide-error";
import "./Guid.css";
export default function Guid() {
	return (
		<div className="error_guid">
			<h1>Guide des erreurs</h1>
			{error_guid.errors.map(
				(err: { error_title: string; error_rule: string }, index: number) => (
					<div className="error_guid_body">
						<div className="error_guid_number">{err.error_title} : </div>
						<div className="error_guid_rule">{err.error_rule}</div>
						<div
							className="error_guid_divider"
							style={
								// if last index display none
								index === error_guid.errors.length - 1
									? { display: "none" }
									: {}
							}
						></div>
					</div>
				)
			)}
		</div>
	);
}
