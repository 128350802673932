import React from "react";
import { Document, Page, View } from "@react-pdf/renderer";
import { styles } from "./StyleSheet";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import { getLastCharBeforeDot } from "../../functions/getLastCharBeforeDot";
import { ResumeCoupure, Resume } from "./Resume";
import { PdfHeader } from "./PdfHeader";
import { Row } from "./Row";

/**
 *
 *  Genère un pdf avec les données provenant de l'api
 *  pour la synthèse de RAF
 *  on utilise react-pdf pour générer le pdf
 *  https://react-pdf.org/
 *  react pdf se base sur la syntaxe de React Native pour les div et les textes
 *  le fichier style se trouve dans le dossier src/components/pdf/StyleSheet.ts
 *  on l'externalise pour une meilleur lisibilité
 * @param tranche : tranche contenant les données provenant de l'api
 * @param currentStep : currentStep contenant les données provenant de l'api pour le projet
 * @returns pdf
 */
export default function PdfRaf({
	tranche,
	currentStep,
}: {
	tranche: any;
	currentStep: multiStepI;
}) {
	return (
		<Document>
			{tranche?.raf?.globalRafArray?.map((i: any, index: number) => (
				<React.Fragment key={index}>
					<Page orientation="landscape">
						<View style={styles.pdf_page}>
							<View style={styles.headers}>
								<PdfHeader
									isProd={false}
									nom_du_dossier={currentStep?.projetName}
									ref_du_dossier={currentStep?.projetReference}
									preparateur={currentStep?.User?.userName}
									longueur_total={tranche?.raf?.distanceProjetTotal.toFixed(2)}
									longueur_tension={tranche?.raf?.distanceTension.toFixed(2)}
									longueur_hors_tension={tranche?.raf?.distanceHorsTension.toFixed(
										2
									)}
									total_fic={tranche?.raf?.nbrFic}
									montant_sous_tension={tranche?.raf?.montantTension}
									montant_hors_tension={tranche?.raf?.montantHorsTension}
									montant_total={tranche?.raf?.montantTotal}
									dico_version={getLastCharBeforeDot(
										currentStep.Dico.dicoVersion
									)}
								/>
							</View>
							<View style={styles.resume}>
								<ResumeCoupure
									total_fic={i?.hors_tension.total_fics}
									equipe={i.team_desc}
									temps_en_jours={
										i.hors_tension?.total_day ? i.hors_tension?.total_day : 0
									}
									temps_en_heures={
										i.hors_tension?.total_hours
											? i.hors_tension?.total_hours
											: 0
									}
									distance_equipe={
										i?.hors_tension?.distance > 0
											? i?.hors_tension?.distance
											: 0
									}
									CA={i?.hors_tension?.total_CA ? i?.hors_tension?.total_CA : 0}
								/>
							</View>
							<React.Fragment>
								{i?.hors_tension?.sans_equipements.map(
									(wStuff: any, index: number) => (
										<React.Fragment key={index}>
											<View style={styles.equipement_even}>
												<Row
													equipe={wStuff.equipement}
													fics={wStuff.fic?.join(", ")}
													length={wStuff.fic.length}
												/>
											</View>
											{i?.hors_tension?.equipements
												?.sort((a: any, b: any) =>
													a.equipement_pos > b.equipement_pos ? 1 : -1
												)
												.map((stuff: any, index: number) => (
													<View
														style={
															index % 2 == 0
																? styles.equipement_odd
																: styles.equipement_even
														}
														key={stuff.equipement_column}
													>
														<Row
															equipe={stuff.equipement_desc}
															fics={stuff.fic?.join(", ")}
															length={stuff.fic.length}
														/>
													</View>
												))}
										</React.Fragment>
									)
								)}
							</React.Fragment>
						</View>
					</Page>
					<Page orientation="landscape" key={index}>
						<View style={styles.pdf_page}>
							<View style={styles.headers}>
								<PdfHeader
									isProd={false}
									nom_du_dossier={currentStep?.projetName}
									ref_du_dossier={currentStep?.projetReference}
									preparateur={currentStep?.User?.userName}
									longueur_total={tranche?.raf?.distanceProjetTotal.toFixed(2)}
									longueur_tension={tranche?.raf?.distanceTension.toFixed(2)}
									longueur_hors_tension={tranche?.raf?.distanceHorsTension.toFixed(
										2
									)}
									total_fic={tranche?.raf?.nbrFic}
									montant_sous_tension={tranche?.raf?.montantTension}
									montant_hors_tension={tranche?.raf?.montantHorsTension}
									montant_total={tranche?.raf?.montantTotal}
									dico_version={getLastCharBeforeDot(
										currentStep.Dico.dicoVersion
									)}
								/>
							</View>
							<View style={styles.resume}>
								<Resume
									total_fic={i?.tension.total_fics}
									equipe={i.team_desc}
									temps_en_jours={
										i.tension?.total_day ? i.tension?.total_day : 0
									}
									temps_en_heures={
										i.tension?.total_hours ? i.tension?.total_hours : 0
									}
									distance_equipe={
										i?.tension?.distance > 0 ? i?.tension?.distance : 0
									}
									CA={i?.tension?.total_CA ? i?.tension?.total_CA : 0}
								/>
							</View>
							<React.Fragment>
								{i?.tension?.sans_equipements.map(
									(wStuff: any, index: number) => (
										<React.Fragment key={index}>
											<View style={styles.equipement_even}>
												<Row
													equipe={wStuff.equipement}
													fics={wStuff.fic?.join(", ")}
													length={wStuff.fic.length}
												/>
											</View>
											{i?.tension?.equipements
												?.sort((a: any, b: any) =>
													a.equipement_pos > b.equipement_pos ? 1 : -1
												)
												.map((stuff: any, index: number) => (
													<View
														style={
															index % 2 == 0
																? styles.equipement_odd
																: styles.equipement_even
														}
														key={stuff.equipement_column}
													>
														<Row
															equipe={stuff.equipement_desc}
															fics={stuff.fic.join(", ")}
															length={stuff.fic.length}
														/>
													</View>
												))}
										</React.Fragment>
									)
								)}
							</React.Fragment>
						</View>
					</Page>
				</React.Fragment>
			))}
		</Document>
	);
}
