import React, { memo, useContext } from "react";
import * as datefns from "date-fns";
import "./Switch.css";
import { FormContext, FormInterface } from "../../../contexts/FormContext";

const Switch = memo(({ item }: any) => {
  const { debouncedHandleChange, handleSelectChange } = useContext(
    FormContext
  ) as FormInterface;

  const formatDate = (date: string) => {
    let formattedDate;

    const dateObject = new Date(Date?.parse(date));
    if (!isNaN(dateObject?.getTime())) {
      formattedDate = datefns?.format(dateObject, "dd/MM/yyyy");
    } else if (date === "vide") {
      formattedDate = "vide";
    } else {
      const [day, month, year] = date.split("/").map(Number);
      formattedDate = datefns?.format(
        new Date(year, month - 1, day),
        "dd/MM/yyyy"
      );
    }

    return formattedDate;
  };

  switch (item.type) {
    case "text":
      return (
        <input
          type="text"
          defaultValue={item.retourPrepaValue}
          // disabled={item.retourPrepaisError === true ? false : true}
          className="switch-input"
          onChange={debouncedHandleChange}
          name={item?.retourPrjId_CellId}
          id={item?.retourPrepaId.toString()}
        />
      );
    case "numeric":
      return (
        <input
          type="number"
          defaultValue={item.retourPrepaValue}
          // disabled={item.retourPrepaisError === true ? false : true}
          className="switch-input"
          name={item?.retourPrjId_CellId}
          onChange={debouncedHandleChange}
          id={item?.retourPrepaId.toString()}
        />
      );
    case "menu":
      return (
        <select
          // disabled={item.retourPrepaisError === true ? false : true}
          className="switch-input"
          defaultValue={item.retourPrepaValue}
          onChange={handleSelectChange}
          id={item?.retourPrepaId.toString()}
          name={item?.retourPrjId_CellId}
        >
          {item.retourPrepaValue === "vide" && <option>vide</option>}
          {typeof item?.menu === "object" ? (
            item?.menu?.map((item: any, index: number) => (
              <option key={index} value={item?.code ? item?.code : item}>
                {item?.code ? item?.code : item}
              </option>
            ))
          ) : (
            <option value={item?.menu}>{item?.menu}</option>
          )}
        </select>
      );
    case "date":
      return <p>{formatDate(item?.retourPrepaValue)}</p>;

    default:
      return <p></p>;
  }
});
export default Switch;
