import React from "react";
import "./BtnUpload.css";
export default function BtnUpload({
	setFiles,
	accept,
	fileName,
	text = "upload",
	disabled = false,
}: {
	setFiles: React.Dispatch<React.SetStateAction<File | null>>;
	fileName?: string;
	accept?: string;
	text?: string;
	disabled?: boolean;
}) {
	const downloadRef = React.useRef<HTMLInputElement>(null);
	return (
		<div>
			<input
				ref={downloadRef}
				type="file"
				onChange={(e) => {
					setFiles(e.target.files ? e.target.files[0] : null);
				}}
				accept={accept}
				name="file"
				hidden
				id="contained-button-file"
			/>

			<button
				className="upload-btn"
				type="button"
				onClick={() => {
					if (downloadRef.current) {
						downloadRef.current.click();
					}
				}}
				disabled={disabled}
				style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }}
			>
				{text}
			</button>
			<div className="upload-filename">
				<p>{fileName}</p>
			</div>
		</div>
	);
}
