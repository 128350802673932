import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import Dashboard from "./components/admin/dashboard/Dashboard";
import UserListPage from "./pages/admin/UserListPage";
import CreateVarPage from "./pages/dev/CreateVarPage";
import PrepaScreen from "./pages/display-project/PrepaScreen";
import MultiStep from "./components/assistant/MultiStep";
import CreateProject from "./pages/create-project/CreateProject";
import { useContext } from "react";
import { LoginContext, LoginType } from "./contexts/LoginContext";
import ProfilePage from "./pages/ProfilePage";
import UploadDicoPage from "./pages/admin/UploadDicoPage";
import Correction from "./pages/corrections/Correction";
import PricePerKilo from "./components/forms/PricePerKilo";
import MappingNavigation from "./components/navigation/mapping/MappingNavigation";
import TeamsMapping from "./pages/admin/mapping/TeamsMapping";
import InputMappingFile from "./pages/admin/mapping/InputMappingFile";
import StuffMapping from "./pages/admin/mapping/StuffMapping";
import MappingMapWoodR6 from "./pages/mapping/MappingMapWoodR6";
import MappingRrefToR3 from "./pages/mapping/MappingRrefToR3";

import CreateTraitementPage from "./pages/dev/CreateTraitementPage";
import MapppingHeader from "./pages/mapping/MapppingHeader";
import Guid from "./pages/corrections/Guid";
import AllProjectPage from "./pages/display-project/AllProjectPage";
import CreateNewUserPage from "./pages/admin/CreateNewUserPage";
import LoginForm from "./components/forms/LoginForm";
import ResetPassword from "./pages/reset/ResetPassword";
import EditProject from "./pages/admin/EditProject";
import CorrectionMapwood from "./pages/corrections/CorrectionMapwood";

const ProtectedRoute = ({
  role,
  allowedRoles = ["ADMIN"],
  redirectPath = "/",
}: {
  role: string;
  allowedRoles?: string[];
  redirectPath?: string;
}) => {
  if (allowedRoles.includes(role)) {
    return <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default function Router({
  isLoggedIn,
  setIsLoggedIn,
}: {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const role = localStorage.getItem("role");
  const parsedRole = JSON.parse(role as string);

  const redirectByRole = (role: string): JSX.Element => {
    switch (role) {
      case "PREPARATEUR": {
        return <PrepaScreen />;
      }
      case "ASSISTANT_GESTION": {
        return <AllProjectPage />;
      }
      case "ADMIN": {
        return <AllProjectPage />;
      }
      default:
        return <AllProjectPage />;
    }
  };

  return isLoggedIn ? (
    <Routes>
      <Route path="/" element={redirectByRole(parsedRole)} />
      <Route path="/create-project" element={<CreateProject />} />
      <Route path="edit-project/:id" element={<EditProject />} />
      <Route path="/prepa-project/:id" element={<MultiStep />} />
      <Route path="/prepa-screen" element={<PrepaScreen />} />
      {/* // create protected route for admin */}
      <Route element={<ProtectedRoute role={parsedRole} />}>
        <Route path="/dashboard/admin" element={<Dashboard />} />

        <Route path="/dashboard/admin/create" element={<CreateNewUserPage />} />
        <Route path="/dashboard/admin/list" element={<UserListPage />} />
        <Route
          path="/dashboard/admin/upload-dico"
          element={<UploadDicoPage />}
        />
        <Route path="/dashboard/admin/time" element={<PricePerKilo />} />
        <Route
          path="/dashboard/admin/mapping"
          element={<MappingNavigation />}
        />
        <Route
          path="/dashboard/admin/mapping/teams"
          element={<TeamsMapping />}
        />
        <Route
          path="/dashboard/admin/mapping/stuffs"
          element={<StuffMapping />}
        />
        <Route
          path="/dashboard/admin/mapping/mapwood"
          element={<MappingMapWoodR6 />}
        />

        <Route
          path="/dashboard/admin/mapping/R6-Rx"
          element={<MappingRrefToR3 />}
        />

        <Route path="/dashboard/admin/header" element={<MapppingHeader />} />
        {/* // create protected route for dev */}
        <Route path="/dashboard/dev" element={<CreateVarPage />} />
        <Route
          path="/dashboard/dev/traitement"
          element={<CreateTraitementPage />}
        />
      </Route>

      <Route path="/projet/:id" element={<MultiStep />} />
      <Route path="/profil" element={<ProfilePage />} />
      {/* // create protected route for prepa */}
      <Route path="/correction/:id" element={<Correction />} />
      <Route path="correction/mapwood/:id" element={<CorrectionMapwood />} />
      <Route path="/correction/guid" element={<Guid />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<LoginForm setIsLoggedIn={setIsLoggedIn} />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
}
