export const getLastCharBeforeDot = (str: string) => {
	const lastChar = str.split("");
	// convert each element of the array to a number
	const array = lastChar.map((element) => {
		return parseInt(element);
	});
	// remove element isNan from the array
	const filtered = array.filter((element) => {
		return !isNaN(element);
	});
	// destroy the array
	const number = filtered.join("");

	return number;
};
