import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { Params, ToastContext } from "../../contexts/ToastContext";
import Toast from "./Toast";
import "./Toast.css";

export function Toasts() {
  const [toast, setToast] = useState<Params[]>([]);
  const { pushToastRef } = useContext(ToastContext);

  pushToastRef.current = (toast: Params) => {
    setToast((toasts) => [...toasts, toast]);
    setTimeout(() => {
      setToast((v) => v.filter((t) => t !== toast));
    }, 3000);
  };

  return (
    <div className="toasts-container">
      <AnimatePresence>
        {toast.map((toast, index: any) => (
          <motion.div
            initial={{ opacity: 1, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            key={index}
          >
            <Toast {...toast} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
