import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
	pdf_page: {
		width: "100%",
		padding: "5px 10px",
	},
	headers: {
		marginVertical: "30px",
	},
	section: {
		flexDirection: "row",
	},
	section_column: {
		flexDirection: "column",
	},
	header_section: {
		borderBottom: "5px solid #04766e",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	info_header_column: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	information: {
		flexDirection: "column",
		fontSize: "12px",
	},
	resume: {
		marginVertical: "10px",
	},
	equipement_odd: {
		flexDirection: "row",
		backgroundColor: "#ababab",
		fontSize: "12px",
	},
	equipement_even: {
		flexDirection: "row",
		backgroundColor: "white",
		fontSize: "12px",
	},
	light_text: {
		color: "#ababab",
		fontSize: "12px",
		fontStyle: "italic",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	direction: {
		flexDirection: "column",
	},
	fic_size: {
		fontSize: "12px",
	},
	row: {
		flexDirection: "row",
		paddingVertical: "5px",
		width: "100%",
	},
	cellHeader: {
		minWidth: "150px",
		height: "100%",
	},
	fics: {
		width: "100%",
		marginLeft: "10px",
	},
});
