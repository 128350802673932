import Error from "../error/Error";
import "./modal.css";

type ModalTypeProps = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	title: string;
	children?: JSX.Element;
	modalRef?: React.RefObject<HTMLDivElement>;
};
export default function Modal({
	isOpen,
	setIsOpen,
	title,
	children,
}: ModalTypeProps): JSX.Element {
	return (
		<div className={isOpen ? "modal-container" : "modal-close"}>
			<div className="modal-paper">
				<div className="modal-btn-close">
					<button onClick={() => setIsOpen(!isOpen)} className="btn_close">
						X
					</button>
				</div>

				<div className="modal-box">
					<div className="modal">
						<h1>{title}</h1>
						<div className="modal-body">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
