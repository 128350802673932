import { utils } from "xlsx";
import { objType } from "./exportToCSV";

export const downloadRetourPrepaCorrected = (
  excelArray: any[],
  fileName: string
) => {
  if (excelArray.length > 0) {
    const valEachObj = Object.values(excelArray);
    //* get the value of each object
    const val = valEachObj?.map((item: any) =>
      item?.map((i: { retourPrepaValue: string }) => i.retourPrepaValue)
    );

    //* get the key of each object
    const object = val.map((item: unknown[]) => {
      const obj: objType = {};
      item?.forEach((i: any, index: number) => {
        if (i === "vide") {
          obj[val[0][index]] = "";
        } else {
          obj[val[0][index]] = i;
        }
      });
      return obj;
    });
    //* remove first row
    const removeFirstRow = object.slice(1);
    //* convert to sheet
    const ws = utils.json_to_sheet(removeFirstRow);
    //* change the separator to semi-comma
    const csv = utils.sheet_to_csv(ws, {
      FS: ";",
    });
    const encodedData = "\ufeff" + csv; // ajoute le BOM pour l'encodage UTF-8

    //* generate a csv file
    if (excelArray) {
      // Créer un objet Blob avec les données CSV encodées en UTF-8
      const blob = new Blob([encodedData], {
        type: "text/csv;charset=utf-8;",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } else {
      console.error("no file");
    }
  }
};
