import React, { useEffect, useState } from "react";
import { Document, Page, View } from "@react-pdf/renderer";
import axios from "axios";
import { SyntheseData } from "../../Types/Interfaces/Pdf-Interface";
import { Row } from "./Row";
import { styles } from "./StyleSheet";
import { ResumeCoupure, Resume } from "./Resume";
import { PdfHeader } from "./PdfHeader";

/**
 *
 *  Genère un pdf avec les données provenant de l'api
 *  pour la synthèse de prod
 *  on utilise react-pdf pour générer le pdf
 *  https://react-pdf.org/
 *  react pdf se base sur la syntaxe de React Native pour les div et les textes
 *  le fichier style se trouve dans le dossier src/components/pdf/StyleSheet.ts
 *  on l'externalise pour une meilleur lisibilité
 *
 * @param id : id du projet pour le useEffect
 * @param preparateur : afin d'identifier le préparateur pour ce dossier
 * @param nom_du_dossier : nom du dossier pour le pdf
 * @param dico_version : version du dictionnaire pour le pdf
 *
 * @returns pdf
 */
export const MyDocument = ({
  id,
  preparateur,
  nom_du_dossier,
  dico_version,
  ref_du_dossier,
}: {
  id: string | number | undefined;
  preparateur: string;
  nom_du_dossier: string;
  dico_version: string;
  ref_du_dossier: string;
}): JSX.Element => {
  const [synthese, setSynthese] = useState<SyntheseData>({} as SyntheseData);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/synthese/${id}`
        );
        setSynthese(response.data);
      } catch (err: unknown) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <Document>
      {synthese?.equipes?.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <Page orientation="landscape">
            <View style={styles.pdf_page}>
              <View style={styles.headers}>
                <PdfHeader
                  isProd={true}
                  nom_du_dossier={nom_du_dossier}
                  ref_du_dossier={ref_du_dossier}
                  preparateur={preparateur}
                  longueur_total={synthese.distance_globale}
                  longueur_tension={synthese.distance_en_tension}
                  longueur_hors_tension={synthese.distance_en_coupure}
                  total_fic={synthese.nbrFic}
                  montant_sous_tension={synthese.montant_total_en_tension}
                  montant_hors_tension={synthese.montant_total_en_coupure}
                  montant_total={
                    synthese.montant_total_en_coupure +
                    synthese.montant_total_en_tension
                  }
                  dico_version={dico_version}
                />
              </View>
              <View style={styles.resume}>
                <Resume
                  total_fic={
                    item.tension?.avec_equipements?.total_fics +
                    item?.tension?.sans_equipement?.length
                  }
                  equipe={item.tension.avec_equipements.equipe_description}
                  temps_en_jours={item.tension.avec_equipements.temps_en_jours}
                  temps_en_heures={
                    item.tension.avec_equipements.temps_en_heures
                  }
                  distance_equipe={item.tension.avec_equipements.distances}
                  CA={item.tension.avec_equipements.chiffre_affaires}
                />
              </View>
              <View>
                <View style={styles.equipement_even}>
                  <Row
                    length={item.tension.sans_equipement?.length}
                    fics={item.tension.sans_equipement?.fics}
                    equipe={"Sans equipement"}
                  />
                </View>
                {item?.tension?.avec_equipements?.equipements
                  ?.sort((a: any, b: any) =>
                    a.equipement_pos > b.equipement_pos ? 1 : -1
                  )
                  ?.map((o: any, index: number) => (
                    <View
                      style={
                        index % 2 == 0
                          ? styles.equipement_odd
                          : styles.equipement_even
                      }
                      key={index}
                    >
                      <Row
                        length={o?.fics_length}
                        fics={o?.fics}
                        equipe={o?.equipement_desc}
                      />
                    </View>
                  ))}
              </View>
            </View>
          </Page>
          <Page orientation="landscape">
            <View style={styles.pdf_page}>
              <View style={styles.headers}>
                <PdfHeader
                  isProd={true}
                  nom_du_dossier={nom_du_dossier}
                  ref_du_dossier={ref_du_dossier}
                  preparateur={preparateur}
                  longueur_total={synthese.distance_globale}
                  longueur_tension={synthese.distance_en_tension}
                  longueur_hors_tension={synthese.distance_en_coupure}
                  total_fic={synthese.nbrFic}
                  montant_sous_tension={synthese.montant_total_en_tension}
                  montant_hors_tension={synthese.montant_total_en_coupure}
                  montant_total={
                    Number(synthese.montant_total_en_coupure) +
                    Number(synthese.montant_total_en_tension)
                  }
                  dico_version={dico_version}
                />
              </View>
              <View style={styles.resume}>
                <ResumeCoupure
                  total_fic={
                    item.coupure?.avec_equipements?.total_fics +
                    item?.coupure?.sans_equipement?.length
                  }
                  equipe={item.coupure.avec_equipements.equipe_description}
                  temps_en_jours={item.coupure.avec_equipements.temps_en_jours}
                  temps_en_heures={
                    item.coupure.avec_equipements.temps_en_heures
                  }
                  distance_equipe={item.coupure.avec_equipements.distances}
                  CA={item.coupure.avec_equipements.chiffre_affaires}
                />
              </View>
              <View style={styles.equipement_even}>
                <Row
                  length={item.coupure.sans_equipement.length}
                  fics={item.coupure.sans_equipement.fics}
                  equipe={"Sans equipement"}
                />
              </View>
              {item?.coupure?.avec_equipements?.equipements
                ?.sort((a: any, b: any) =>
                  a.equipement_pos > b.equipement_pos ? 1 : -1
                )
                ?.map((o: any, index: number) => (
                  <View
                    style={
                      index % 2 == 0
                        ? styles.equipement_odd
                        : styles.equipement_even
                    }
                    key={index}
                  >
                    <Row
                      length={o.fics_length}
                      fics={o?.fics}
                      equipe={o?.equipement_desc}
                    />
                  </View>
                ))}
            </View>
          </Page>
        </React.Fragment>
      ))}
    </Document>
  );
};
