import axios from "axios";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { removeSixFirstCharAndExtension } from "../../functions/removeSixFirstCharAndExtension";
import "./InitProjectPrice.css";

export default function InitProjectPrice({
  setDico,
  setPriceByTheCut,
  setPriceForKm,
  setRfile,
  setCompany,
  priceByTheCut,
  priceForKm,
  dicoValue,
}: {
  setDico: Dispatch<SetStateAction<number | string | null>>;
  setPriceByTheCut: Dispatch<SetStateAction<string>>;
  setCompany: Dispatch<SetStateAction<number | string | null>>;
  setPriceForKm: Dispatch<SetStateAction<string>>;
  setRfile: Dispatch<SetStateAction<number>>;
  dicoValue?: string;
  priceByTheCut: string;
  priceForKm: string;
}) {
  const [dicoVersion, setDicoVersion] = useState<[]>([]);
  const [types, setTypes] = useState<[]>([]);
  const [companys, setCompanys] = useState<[]>([]);
  useEffect(() => {
    const handleDicoVersion = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/dico`)
        .then((res) => setDicoVersion(res.data));
    };
    handleDicoVersion();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const types = await axios.get(
          `${process.env.REACT_APP_API_URL}/rfiles`
        );
        setTypes(types.data);
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const companys = await axios.get(
          `${process.env.REACT_APP_API_URL}/company`
        );
        setCompanys(companys.data);
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div className="init-project-price-container">
      <div className="init-project-price row-price">
        <div className="dico column">
          <label>Choix de la société</label>
          <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setCompany(e.target.value)
            }
            className="dico"
          >
            <option value="">Choisir une société</option>
            {companys?.map(
              (company: { companyId: number; companyName: string }) => (
                <option value={company.companyId} key={company.companyId}>
                  {company.companyName}
                </option>
              )
            )}
          </select>
          <label>Choix du Dico </label>
          <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setDico(e.target.value)
            }
            className="dico"
            value={dicoValue}
          >
            <option value="">Choisir un dico</option>
            {dicoVersion?.map(
              (dico: { dicoId: number; dicoVersion: string }) => (
                <option value={dico.dicoId} key={dico.dicoId}>
                  dico {removeSixFirstCharAndExtension(dico.dicoVersion)}
                </option>
              )
            )}
          </select>
          <label>Choix du type de fichier</label>
          <select
            className="file"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setRfile(Number(e.target.value))
            }
          >
            <option>Choisir un type de fichier</option>
            {types
              ?.filter(
                (type: { rFileType: string }) => type.rFileType !== "Mapwood"
              )
              .map((type: { rFileId: number; rFileType: string }) => (
                <option value={type.rFileId} key={type.rFileId}>
                  {type.rFileType}
                </option>
              ))}
          </select>
        </div>
        <div className="column price">
          <label htmlFor="">Prix à la coupe</label>
          <input
            type="text"
            value={priceByTheCut}
            onChange={(e) => setPriceByTheCut(e.target.value)}
            className="price"
            id="price"
          />
          <label htmlFor="km">prix au km</label>
          <input
            type="text"
            value={priceForKm}
            onChange={(e) => setPriceForKm(e.target.value)}
            className="price"
            id="km"
          />
        </div>
      </div>
    </div>
  );
}
