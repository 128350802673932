import React, { useContext, useEffect, useState } from "react";

import VariableForm from "../../components/dev/VariableForm";
import VarList from "../../components/dev/VarList";
import Popover from "../../components/modal/Popover";
import "./CreateVarPage.css";
import { IoAddCircleOutline } from "react-icons/io5";
import { LoginContext, LoginType } from "../../contexts/LoginContext";
import axios from "axios";
import Header from "../../components/dev/header/Header";

type VariableType = {
  variableName: string;
  variableDescription: string;
};
export default function CreateVarPage(): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [varLists, setVarLists] = useState<any>([]);
  const [newVariable, setNewVariable] = useState<{
    name: string;
    description: string;
  }>({
    name: "",
    description: "",
  });
  const { token } = useContext(LoginContext) as LoginType;
  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/variables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setVarLists(res.data);
      })
      .catch((err) => console.error(err));
  }, [token]);

  const deleteVar = (id: number) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/variables/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setVarLists(
          varLists.filter((v: { variableId: number }) => v.variableId !== id)
        );
      })
      .catch((err) => console.error(err));
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: VariableType = {
      variableName: newVariable.name,
      variableDescription: newVariable.description,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/variables`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVarLists([...varLists, res.data]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="createVarPage_container">
      <Header />
      <div onClick={openPopover} className="var_page_newvar">
        <span>
          {" "}
          <IoAddCircleOutline size={50} />{" "}
        </span>
        créer une nouvelle variable
      </div>
      <VarList onDeleteVar={deleteVar} data={varLists} />
      <Popover isOpen={isOpen} onClick={closePopover}>
        <VariableForm
          onSubmitForm={submitForm}
          newVariable={newVariable}
          setNewVariable={setNewVariable}
        />
      </Popover>
    </div>
  );
}
