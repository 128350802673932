import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

import "./VarList.css";
export default function VarList({
	data,
	onDeleteVar,
}: {
	data: [];
	onDeleteVar: (id: number) => void;
}) {
	return (
		<div>
			<h1>Listes des variables</h1>
			<table className="var_cell var_table">
				<thead>
					<tr className="var_head_row">
						<th className="var_th">nom de la variable</th>
						<th className="var_th">Valeur</th>
						{/* <th className="var_th">Visible</th> */}
						<th className="var_th">Supr</th>
					</tr>
				</thead>
				<tbody>
					{data.map(
						(v: {
							variableName: string;
							variableDescription: string;
							variableId: number;
						}) => (
							<tr className="var_body_row" key={v.variableId}>
								<td className="var_td">{v.variableName}</td>
								<td className="var_td">{v.variableDescription}</td>
								{/* <td className="var_td var_input">
									<input type="checkbox" />
								</td> */}
								<td className="var_td">
									<button
										className="var_btn"
										onClick={() => {
											onDeleteVar(v.variableId);
										}}
									>
										<RiDeleteBin6Line color="black" fontSize={24} />
									</button>
								</td>
							</tr>
						)
					)}
				</tbody>
			</table>
		</div>
	);
}
