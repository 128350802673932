import axios from "axios";
import React, { useState, useEffect } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import Header from "../../components/dev/header/Header";
import TraitementForm from "../../components/dev/TraitementForm";
import TraitementList from "../../components/dev/TraitementList";

import Popover from "../../components/modal/Popover";

type traitementType = {
	traitementValue: string;
	traitementDescription: string;
};
const defaultValue = {
	traitementValue: "",
	traitementDescription: "",
};

export default function CreateTraitementPage() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [traitementLists, setTraitementLists] = useState<any>([]);
	const [headers, setHeaders] = useState<any>([]);
	const [newTraitement, setNewTraitement] =
		useState<traitementType>(defaultValue);

	const [traitementEdited, setTraitementEdited] = useState<any>([]);

	const openPopover = () => {
		setIsOpen(true);
	};

	const closePopover = () => {
		setIsOpen(false);
	};

	const sendTraitement = () => {
		try {
			axios.post(`${process.env.REACT_APP_API_URL}/traitement`, newTraitement);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const headers = await axios.get(
					`${process.env.REACT_APP_API_URL}/header/${4}`
				);
				setHeaders(headers.data);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const traitement = await axios.get(
					`${process.env.REACT_APP_API_URL}/traitement`
				);
				setTraitementLists(traitement.data);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	const handleChangeSelect = (
		e: React.ChangeEvent<HTMLSelectElement>,
		headerId: number
	) => {
		const { value } = e.target;
		const existingItem = traitementEdited.find(
			(item: { header_id: number }) => item.header_id === headerId
		);
		if (existingItem) {
			setTraitementEdited((prevData: any) =>
				prevData.map((item: any) =>
					item.header_id === headerId ? { ...item, traitement_id: value } : item
				)
			);
		} else {
			setTraitementEdited((prevData: any) => [
				...prevData,
				{
					header_id: headerId,
					traitement_id: value,
				},
			]);
		}
	};

	const validateTraitement = () => {
		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/header`, traitementEdited);
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<div className="createVarPage_container">
			<Header />
			<div onClick={openPopover} className="var_page_newvar">
				<span>
					<IoAddCircleOutline size={50} />
				</span>
				créer un nouveau traitement
			</div>
			<TraitementList
				headers={headers}
				traitements={traitementLists}
				handleChangeSelect={handleChangeSelect}
				validateTraitement={validateTraitement}
			/>
			<Popover isOpen={isOpen} onClick={closePopover}>
				<div>
					<TraitementForm
						setNewTraitement={setNewTraitement}
						newTraitement={newTraitement}
						onSubmitForm={sendTraitement}
					/>
				</div>
			</Popover>
		</div>
	);
}
