import React, { memo, useEffect, useRef } from "react";
import Switch from "../switch-component/Switch";
import "./Table.css";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import axios from "axios";
import { useParams } from "react-router-dom";

interface retourPrepaArray {
  retourPrepaId: number | string;
  retourPrepaRow: number;
  retourPrepaCol: string;
  retourPrepaValue: string;
  retourPrjId_CellId: string;
  retourPrepaisEdited: boolean;
  retourPrepaisError: boolean;
  retourPrepaErrorNumber: number;
  retourPrepaFic: number;
  header: string;
  type: string;
  menu: string[];
}

interface RetourPrepaFirstRow {
  retourPrepaId: number | string;
  header: string;
  retourPrepaValue: string;
}

const ErrorTableBackEnd = memo(
  ({
    arrayOfData,
    left = 0,
    position = "sticky",
    setFormData,
  }: {
    arrayOfData: Array<retourPrepaArray[]>;
    left?: number;
    position?: "sticky" | "relative" | "absolute" | "fixed" | "static";
    setFormData: React.Dispatch<React.SetStateAction<[]>>;
  }) => {
    const isChangeRef = useRef<HTMLTableCellElement>(null);
    const { id } = useParams();

    useEffect(() => {
      (async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/projet/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setFormData(response.data);
        } catch (error: unknown) {
          console.error({ message: "error", error });
        }
      })();
    }, []);

    const changeColorIfEdited = (item: {
      retourPrepaisEdited: boolean;
      retourPrepaisError: boolean;
    }): string => {
      if (
        item.retourPrepaisEdited === true &&
        item.retourPrepaisError === true
      ) {
        return "#bde0fe";
      } else if (
        item.retourPrepaisEdited === false &&
        item.retourPrepaisError === true
      ) {
        return "red";
      }
      return "transparent";
    };

    const goToTheTop = () => {
      window.scrollTo(0, 0);
    };

    return (
      <div>
        <table className="table">
          <thead className="thead">
            <tr className="col">
              <th
                style={{
                  position: position,
                  left: left,
                }}
              >
                Numéro de FIC
              </th>
              {/*//* nom des colonnes */}
              {arrayOfData[0]?.map(
                (array: {
                  retourPrepaCol: string;
                  retourPrepaId: number | string;
                }) => (
                  <th key={array.retourPrepaId}>{array?.retourPrepaCol}</th>
                )
              )}
            </tr>
            <tr className="header">
              {/*//* nom des entêtes de colonnes */}
              <th
                style={{
                  position: position,
                  left: left,
                }}
              ></th>
              {arrayOfData[0]?.map((array: RetourPrepaFirstRow) => (
                <th key={array.retourPrepaId}>{array?.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {arrayOfData
              ?.slice(1)
              ?.map((line: Array<retourPrepaArray>, index: number) => (
                <tr key={index}>
                  {line && (
                    <th
                      style={{
                        position: position,
                        left: left,
                      }}
                    >
                      {line[0]?.retourPrepaFic}
                    </th>
                  )}
                  {line?.map((item: retourPrepaArray) => (
                    <td
                      key={item?.retourPrepaId}
                      ref={isChangeRef}
                      className="body-content-row"
                      style={{
                        backgroundColor: changeColorIfEdited(item),
                      }}
                      title={
                        item?.retourPrepaErrorNumber !== null
                          ? `code erreur : ${item?.retourPrepaErrorNumber}`
                          : "Pas d'erreur"
                      }
                    >
                      <Switch item={item} />
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        <div className="correction_arrow">
          <IoArrowUpCircleOutline
            size={50}
            onClick={goToTheTop}
            className="arrow_btn"
          />
        </div>
      </div>
    );
  }
);

export default ErrorTableBackEnd;
