import React from "react";

export default function SelectType({
  onChange,
  onClick,
  className,
  required,
  defaultValue,
}: {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onClick?: () => void;
  className?: string;
  required?: boolean;
  defaultValue?: string;
}) {
  const typeArray = [
    { name: "CLASSIQUE", id: 1 },
    { name: "MAPWOOD", id: 2 },
  ];
  return (
    <select
      onChange={onChange}
      onClick={onClick}
      className={className}
      required={required}
      defaultValue={defaultValue}
    >
      <option value="">selectionner le type de départ</option>
      {typeArray.map((type: { name: string; id: number }) => (
        <option value={type.name} key={type.id}>
          {type.name}
        </option>
      ))}
    </select>
  );
}
