import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/layout/cards/Card";
import Loader from "../../components/loading/Loader";
import { removeSixFirstCharAndExtension } from "../../functions/removeSixFirstCharAndExtension";
import { useToasts } from "../../hook/useToasts";

export default function EditProject() {
	const { id } = useParams<{ id: string }>();
	const [preparatorId, setPreparatorId] = useState<number>(0);
	const [dicoVersion, setDicoVersion] = useState<[]>([]);
	const [dico, setDico] = useState<number | string | null>(null);
	const [companys, setCompanys] = useState<[]>([]);
	const [company, setCompany] = useState<number | string | null>(null);
	const [priceByTheCut, setPriceByTheCut] = useState<number>(0);
	const [pricePerKm, setPricePerKm] = useState<number>(0);
	const [preparators, setPreparators] = useState<[]>([]);
	const [types, setTypes] = useState<[]>([]);
	const [rFile, setRfile] = useState<number>(4);
	const [project, setProject] = useState<any>({});
	const [reference, setReference] = useState<string>("");
	const { pushToast } = useToasts();

	useEffect(() => {
		// get the projet to async
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/projet/${id}`
				);
				setProject(response.data);
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/user/preparator`
				);
				setPreparators(response.data);
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);
	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setPreparatorId(Number(e.target.value));
	};
	useEffect(() => {
		const handleDicoVersion = () => {
			axios
				.get(`${process.env.REACT_APP_API_URL}/dico`)
				.then((res) => setDicoVersion(res.data));
		};
		handleDicoVersion();
	}, []);
	useEffect(() => {
		(async () => {
			try {
				const types = await axios.get(
					`${process.env.REACT_APP_API_URL}/rfiles`
				);
				setTypes(types.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);
	useEffect(() => {
		(async () => {
			try {
				const companys = await axios.get(
					`${process.env.REACT_APP_API_URL}/company`
				);
				setCompanys(companys.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	const handleSubmit = async () => {
		const data = {
			userId: preparatorId,
			dicoId: Number(dico),
			projetPriceByTheCut: priceByTheCut,
			projetPricePerKm: pricePerKm,
			rFileId: Number(rFile),
			company: Number(company),
			reference: reference,
		};
		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/projet/${id}`, data);
			pushToast({ type: "success", message: "Le dossier a bien été modifié" });
		} catch (error) {
			console.error(error);
			pushToast({
				message: "Une erreur est survenue",
				type: "error",
			});
		}
	};
	if (project === undefined) {
		return (
			<div>
				<div>Chargement...</div>
				<Loader />
			</div>
		);
	}
	return (
		<div className="create-project-page">
			<div>
				<h1>
					Modifier le dossier : <br /> {project?.projetName}
				</h1>
			</div>
			<Card number="1">
				<div className="create-project-dossier">
					<p>Selectionner le preparateur</p>
					<select
						value={preparatorId ? preparatorId : project?.userId}
						onChange={handleChange}
					>
						{preparators.map(
							(preparator: { userName: string; userId: number }) => (
								<option value={preparator.userId} key={preparator.userId}>
									{preparator.userName}
								</option>
							)
						)}
					</select>

					<label className="edit-project-label">
						Définir la référence du dossier
					</label>
					<input
						onChange={(e) => setReference(e.target.value)}
						value={reference ? reference : project?.projetReference}
					/>
				</div>
				<></>
			</Card>
			<Card number="2">
				<div className="init-project-price-container">
					<div className="init-project-price row-price">
						<div className="dico column">
							<label>Choix de la société</label>
							<select
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
									setCompany(e.target.value)
								}
								className="dico"
								value={company ? company : project?.Company?.companyName}
							>
								{companys?.map(
									(company: { companyId: number; companyName: string }) => (
										<option value={company.companyId} key={company.companyId}>
											{company.companyName}
										</option>
									)
								)}
							</select>
							<label>Choix du Dico</label>
							<select
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
									setDico(e.target.value)
								}
								className="dico"
								value={dico ? dico : project?.dicoId}
							>
								<option value="">Choisir un dico</option>
								{dicoVersion?.map(
									(dico: { dicoId: number; dicoVersion: string }) => (
										<option value={dico.dicoId} key={dico.dicoId}>
											dico {removeSixFirstCharAndExtension(dico.dicoVersion)}
										</option>
									)
								)}
							</select>
							<label>Choix du type de fichier</label>
							<select
								className="file"
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
									setRfile(Number(e.target.value))
								}
								value={rFile ? rFile : project?.rFileId}
							>
								<option>Choisir un type de fichier</option>
								{types?.map((type: { rFileId: number; rFileType: string }) => (
									<option value={type.rFileId} key={type.rFileId}>
										{type.rFileType}
									</option>
								))}
							</select>
						</div>
						<div className="column price">
							<label htmlFor="km">prix au km</label>
							<input
								type="text"
								defaultValue={project?.projetPricePerKm}
								onChange={(e) => setPricePerKm(Number(e.target.value))}
								className="price"
								id="km"
							/>
						</div>
					</div>
				</div>
				<></>
			</Card>
			<div className="create-project-btn">
				<button className="create-btn" onClick={handleSubmit}>
					Valider
				</button>
			</div>
		</div>
	);
}
