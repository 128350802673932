import "./Header.css";
import SelectProject from "../utils/select/SelectProject";

interface IHeader {
	nomDossier: string;
	refDossier: string;
}

export default function Header({
	nomDossier,
	refDossier,
}: IHeader): JSX.Element {
	return (
		<div className="header-projet-container">
			<div className="header-projet-box">
				<h1 className="header-projet-title">
					{nomDossier} / {refDossier}
				</h1>
				<SelectProject />
			</div>
		</div>
	);
}
