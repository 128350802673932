import "./DownloadBtn.css";

export default function DownloadBtn({
	text,
	download,
	disabled = false,
}: {
	text: string;
	download: () => void;
	disabled?: boolean;
}) {
	return (
		<button
			type="button"
			className="download-btn"
			onClick={download}
			disabled={disabled}
		>
			{text}
		</button>
	);
}
