import React, { useEffect, useState } from "react";
import axios from "axios";
import BtnUpload from "../../components/layout/buttons/upload/BtnUpload";
import Card from "../../components/layout/cards/Card";
import "./MappingHeader.css";
import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Breadcrumb from "../../components/utils/breadCrumb/Breadcrumb";
import { useToasts } from "../../hook/useToasts";

export default function MapppingHeader() {
	const [headerFile, setHeaderFile] = useState<File | null>(null);
	const [types, setTypes] = useState<[]>([]);
	const [rFile, setRfile] = useState<number>(0);
	const { pushToast } = useToasts();

	const uploadHeader = () => {
		const newFiles = new FormData();
		newFiles.append("file", headerFile as Blob);
		newFiles.append("rFileId", rFile.toString());
		try {
			axios.post(`${process.env.REACT_APP_API_URL}/header`, newFiles);
			pushToast({ type: "success", message: "Le fichier a bien été uploadé" });
		} catch (error: unknown) {
			console.error(error);
			pushToast({ type: "error", message: "Une erreur est survenue" });
			throw new Error("error");
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const types = await axios.get(
					`${process.env.REACT_APP_API_URL}/rfiles`
				);
				setTypes(types.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "Mapping", path: "/dashboard/admin/mapping" },
		{ label: "Header", path: "/dashboard/admin/mapping/header" },
	];

	return (
		<div className="mapping_header">
			<Breadcrumb items={items} />
			<Card number={""} display={false}>
				<div className="card_header_body">
					<div className="card_select">
						<label>Uploader votre fichier</label>
						<BtnUpload setFiles={setHeaderFile} fileName={headerFile?.name} />
					</div>
					<div className="card_select">
						<label>Choix du type de fichier</label>
						<select
							className="file"
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
								setRfile(Number(e.target.value))
							}
						>
							<option>Choisir un type de fichier</option>
							{types?.map((type: { rFileId: number; rFileType: string }) => (
								<option value={type.rFileId} key={type.rFileId}>
									{type.rFileType}
								</option>
							))}
						</select>
					</div>
				</div>
				<ValidateStepBtn validate={uploadHeader} text="valider l'upload" />
			</Card>
		</div>
	);
}
