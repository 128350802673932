import axios from "axios";
import { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import "./ProgressBar.css";

interface ProgressBarInterface {
	currentStep: number | string;
	id: string | undefined;
}

export default function ProgressBar({ currentStep, id }: ProgressBarInterface) {
	const [stepsState, setStepsState] = useState([]);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/step`)
			.then((res) => setStepsState(res.data))
			.catch((err) => console.error(err));
	}, []);

	const goForward = (step: number) => {
		// can go forward but not to the next step
		if (step < currentStep) {
			const data = {
				stepId: step,
			};
			try {
				axios.patch(`${process.env.REACT_APP_API_URL}/projet/${id}`, data);
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} catch (error: unknown) {
				console.error(error);
			}
		} else {
			// can't go to the next step
			console.error("can't go to the next step");
		}
	};

	return (
		<div className="stepper-wrapper">
			{stepsState
				.sort(
					(a: { stepCurrentPos: number }, b: { stepCurrentPos: number }) =>
						a.stepCurrentPos - b.stepCurrentPos
				)
				.map(
					(
						step: { stepCurrentPos: number; stepName: string },
						index: number
					) => (
						<div
							className={`stepper-item ${
								currentStep === step.stepCurrentPos ? "active" : ""
							} ${currentStep > step.stepCurrentPos ? "completed" : ""}`}
							key={index}
						>
							<div className="step-counter">
								<button
									className="stepper_btn"
									onClick={() => goForward(step.stepCurrentPos)}
									style={
										step.stepCurrentPos < currentStep
											? { cursor: "pointer" }
											: {}
									}
								>
									{currentStep > step.stepCurrentPos ? (
										<AiOutlineCheck />
									) : (
										step.stepCurrentPos
									)}
								</button>
							</div>
							<div className="step-name">{step.stepName}</div>
						</div>
					)
				)}
		</div>
	);
}
