import React, { useEffect, useState } from "react";
import "./VarHeader.css";
import "../../utils/array/Table.css";

import SelectWithFilter from "../../filter/SelectWithFilter";

import GenericBtn from "../../layout/buttons/generic/GenericBtn";
import { selectOptionsInterface } from "../../../Types/Interfaces/selectOptions";

interface varHeaderProps {
	arrayHeader: any;
	setArrayHeader: React.Dispatch<React.SetStateAction<any[]>>;
	rRefArray: any;
	onClickSend: (e: React.MouseEvent) => void;
	selectedOption: string[];
	setSelectedOption: React.Dispatch<React.SetStateAction<string[]>>;
	selectedOptions: Array<selectOptionsInterface>;
	setSelectedOptions: React.Dispatch<
		React.SetStateAction<Array<selectOptionsInterface>>
	>;
	pageTitle: string;
	mapping?: any;
	setValues: React.Dispatch<React.SetStateAction<any>>;
	fichierRref: string;
	fichierRx: string;
}

export default function VarHeader({
	arrayHeader,
	rRefArray,
	onClickSend,
	selectedOption,
	setSelectedOption,
	selectedOptions,
	setSelectedOptions,
	pageTitle,
	setValues,
	mapping,
	fichierRref,
	fichierRx,
}: varHeaderProps) {
	const handleSelectOption = (
		value: string | null,
		column: string,
		index: number,
		col: string,
		mw_header: string
	) => {
		const displayData: string[] | any[] = [...selectedOption];
		// const inputValue = inputValues[index];

		displayData[index] = value;
		setSelectedOption(displayData);
		setSelectedOptions([
			...selectedOptions,
			{
				index: index,
				column_prev: column,
				header_prev: value,
				column_reference: col,
				header_reference: mw_header,
				// default_value: inputValue,
			},
		]);

		// si l'index existe déjà dans le tableau on le modifie
		const indexInArray = selectedOptions.findIndex(
			(item: selectOptionsInterface, idx) => idx === index
		);
		if (indexInArray !== -1) {
			const newArray = [...selectedOptions];
			newArray[indexInArray] = {
				index: index,
				column_prev: column,
				header_prev: value,
				column_reference: col,
				header_reference: mw_header,
				// default_value: inputValue,
			};
			setSelectedOptions(newArray);
		}
	};

	useEffect(() => {
		// Cette fonction est appelée lorsque rRefArray change
		// lorsque rRefArray est chargé alors on initialise le tableau d'objet
		// en créant chaque objet avec la valeur du header de reference ainsi que sa colonne
		// la personne qui effectuera le mapping n'aura plus besoin d'ajouter la valeur "no corres"
		// lorsqu'il n'y aura pas de correpondance
		// c'est un gain de temps surtout pour le fichier Mapwood
		const createHeaderObjectArray = () => {
			const headerObjects = arrayHeader.map((item: any, index: number) => {
				return {
					column_prev: "null",
					column_reference: item.headerColumn,
					header_prev: "no-corres",
					header_reference: item.headerValue,
					index: index,
				};
			});
			setSelectedOptions(headerObjects);
		};

		if (rRefArray.length > 0) {
			createHeaderObjectArray();
		}
	}, [rRefArray]);

	useEffect(() => {
		// Cette fonction est appelée lorsque arrayHeader change
		const addHeader = (array: any) => {
			const arrayWithHeader: Array<{
				header: string;
				col_mw: string;
			}> = [];
			array?.forEach((item: { headerValue: string; headerColumn: string }) => {
				arrayWithHeader.push({
					header: item?.headerValue,
					col_mw: item?.headerColumn,
				});
			});

			array?.forEach((row: any) => {
				arrayWithHeader.forEach((item: any) => {
					if (row.headerColumn === item.col_mw) {
						row.headerValue = item.header;
					}
				});
			});

			return array;
		};
		addHeader(arrayHeader);
	}, [arrayHeader]);

	useEffect(() => {
		// Fonction de nettoyage
		return () => {
			setSelectedOptions([]); // Nettoyer le tableau selectedOptions
		};
	}, []);

	return fichierRx === undefined ? (
		<h1>Veuillez selectionner le fichier à mapper</h1>
	) : (
		<div className="varHeader_page">
			<h1>{pageTitle}</h1>
			<div className="varHeader_table">
				<table className="table">
					<thead>
						<tr className="header_sticky">
							<th className="varHeader_head">Colonne</th>
							<th className="varHeader_head">R6</th>
							<th className="varHeader_head">{fichierRx}</th>
						</tr>
					</thead>
					<tbody>
						{arrayHeader
							.sort(
								(
									a: {
										headerColumn: string;
									},
									b: { headerColumn: string }
								) => {
									if (a.headerColumn.length != b.headerColumn.length) {
										return a.headerColumn.length - b.headerColumn.length;
									}
									return a.headerColumn.localeCompare(b.headerColumn);
								}
							)
							?.map(
								(
									array: {
										headerColumn: string;
										headerValue: string;
										headerId: number;
									},
									index: number
								) => (
									<tr className="col" key={index}>
										<td>{array?.headerColumn}</td>
										<td className="varHeader_value">{array?.headerValue}</td>
										<td className="varHeader_select">
											<SelectWithFilter
												data={rRefArray}
												setSelectedOption={(
													value: string | null,
													column_R6: string
												) =>
													handleSelectOption(
														value,
														column_R6,
														index,
														array?.headerColumn,
														array?.headerValue
													)
												}
												selectedOption={selectedOption[index]}
											/>
										</td>
									</tr>
								)
							)}
					</tbody>
				</table>
				<div className="var_header_btn">
					<GenericBtn text="Envoyer" onClick={onClickSend} />
				</div>
			</div>
		</div>
	);
}

{
	// const [inputValues, setInputValues] = useState<string[]>([]);
	/* <tr>
							Ajout de la nouvelle colonne avec le contenu souhaité 
							<td>Valeur par défaut</td>
							{arrayHeader?.map((item: any, index: number) => (
								<td key={index}>
									<input
										type="text"
										onChange={(e) => {
											const newInputValues = [...inputValues];
											newInputValues[index] = e.target.value;
											setInputValues(newInputValues);
										}}
									/>
								</td>
							))}
						</tr> */
}
