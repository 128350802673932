import React from "react";
import GenericBtn from "../layout/buttons/generic/GenericBtn";

import "./VariableForm.css";

type VariableType = {
	traitementDescription: string;
	traitementValue: string;
};

interface PropsInterface {
	onSubmitForm: (event: React.FormEvent<HTMLFormElement>) => void;
	setNewTraitement: React.Dispatch<React.SetStateAction<VariableType>>;
	newTraitement: VariableType;
}

export default function TraitementForm({
	onSubmitForm,
	setNewTraitement,
	newTraitement,
}: PropsInterface): JSX.Element {
	return (
		<div className="dev-form-container">
			<div className="dev-form-box">
				<form onSubmit={onSubmitForm} className="dev-form">
					<div className="dev-form-body">
						<label className="dev-form-label">Valeur du traitement</label>
						<input
							name="variable"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setNewTraitement({
									...newTraitement,
									traitementValue: event.target.value,
								})
							}
							className="dev-form-input"
						/>
						<label className="dev-form-label">Description de traitement</label>
						<input
							name="description"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setNewTraitement({
									...newTraitement,
									traitementDescription: event.target.value,
								})
							}
							className="dev-form-input"
						/>
						<div className="dev-form-btn-container">
							<GenericBtn text="Créer" />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
