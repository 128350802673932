import React from "react";
import GenericBtn from "../layout/buttons/generic/GenericBtn";

import "./VariableForm.css";

type VariableType = {
  name: string;
  description: string;
};

interface PropsInterface {
  onSubmitForm: (event: React.FormEvent<HTMLFormElement>) => void;
  setNewVariable: React.Dispatch<React.SetStateAction<VariableType>>;
  newVariable: VariableType;
}

export default function VariableForm({
  onSubmitForm,
  setNewVariable,
  newVariable,
}: PropsInterface): JSX.Element {
  return (
    <div className="dev-form-container">
      <div className="dev-form-box">
        <form onSubmit={onSubmitForm} className="dev-form">
          <div className="dev-form-body">
            <label htmlFor="variable" className="dev-form-label">
              Créer une nouvelle variable
            </label>
            <input
              name="variable"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNewVariable({
                  ...newVariable,
                  name: event.target.value,
                })
              }
              className="dev-form-input"
            />
            <label htmlFor="description" className="dev-form-label">
              Description de la variable
            </label>
            <input
              name="description"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNewVariable({
                  ...newVariable,
                  description: event.target.value,
                })
              }
              className="dev-form-input"
            />
            <div className="dev-form-btn-container">
              <GenericBtn text="Créer" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
