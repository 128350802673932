import { Text, View } from "@react-pdf/renderer";
import { parseCharSpe } from "../../functions/replaceCharSpe";
import { styles } from "./StyleSheet";

export const Resume = ({
	total_fic,
	equipe,
	temps_en_jours,
	temps_en_heures,
	distance_equipe,
	CA,
}: {
	total_fic: number;
	equipe: string;
	temps_en_jours: number;
	temps_en_heures: number;
	distance_equipe: number;
	CA: number;
}) => {
	return (
		<View style={styles.header}>
			<View style={styles.direction}>
				<Text>{parseCharSpe(equipe)} Tension</Text>
				<Text style={styles.fic_size}>Nb total FIC : {total_fic}</Text>
			</View>
			<Text>
				Temps Total : {temps_en_jours} j ({temps_en_heures} h)
			</Text>
			<Text>Distance : {distance_equipe} km</Text>
			<Text>CA : {CA} €</Text>
		</View>
	);
};

export const ResumeCoupure = ({
	total_fic,
	equipe,
	temps_en_jours,
	temps_en_heures,
	distance_equipe,
	CA,
}: {
	total_fic: number;
	equipe: string;
	temps_en_jours: number;
	temps_en_heures: number;
	distance_equipe: number;
	CA: number;
}) => {
	return (
		<View style={styles.header}>
			<View style={styles.direction}>
				<Text>{parseCharSpe(equipe)} Coupure</Text>
				<Text style={styles.fic_size}>Nb total FIC : {total_fic}</Text>
			</View>
			<Text>
				Temps Total : {temps_en_jours} j ({temps_en_heures} h)
			</Text>
			<Text>Distance : {distance_equipe} km</Text>
			<Text>CA : {CA} €</Text>
		</View>
	);
};
