import { useNavigate } from "react-router-dom";

function useRedirect() {
  const navigate = useNavigate();

  function redirectTo(path: string) {
    navigate(path);
  }

  return redirectTo;
}

export default useRedirect;
