import React, { useState } from "react";
import axios from "axios";
import BtnUpload from "../../components/layout/buttons/upload/BtnUpload";
import Card from "../../components/layout/cards/Card";
import "./UploadDicoPage.css";
import { useToasts } from "../../hook/useToasts";

import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Breadcrumb from "../../components/utils/breadCrumb/Breadcrumb";
export default function UploadDicoPage() {
  const { pushToast } = useToasts();
  const [files, setFiles] = useState<File | null>(null);

  const uploadDico = async () => {
    try {
      const formData = new FormData();
      formData.append("file", files as Blob);
      await axios.post(`${process.env.REACT_APP_API_URL}/dico`, formData);
      pushToast({ message: "dico uploadé", type: "success" });
    } catch (error: unknown) {
      pushToast({ message: "erreur lors de l'upload", type: "error" });
      console.error({ message: error });
    }
  };

  const items = [
    { label: "Dashboard", path: "/dashboard/admin" },
    { label: "upload dico", path: "/dashboard/admin/upload-dico" },
  ];

  return (
    <div className="dico_page">
      <Breadcrumb items={items} />
      <div>
        <Card number="" display={false}>
          <h1>Veuillez charger votre dico </h1>
          <div>
            <BtnUpload
              setFiles={setFiles}
              fileName={files?.name}
              accept=".afs"
              text="Uploader le nouveau dico"
            />
            <ValidateStepBtn validate={uploadDico} text="Valider !" />
          </div>
        </Card>
      </div>
    </div>
  );
}
