import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { LoginContext, LoginType } from "../../../contexts/LoginContext";
import "./UserList.css";
import { useToasts } from "../../../hook/useToasts";
import GenericBtn from "../../layout/buttons/generic/GenericBtn";

interface UserInterface {
  userId: number;
  userName: string;
  userEmail: string;
  userPhone: string;
  Role: userRole;
  userAccessScreensPreparation: boolean;
  userAccessScreensRetourPrepa: boolean;
  userAccessScreensFacturation: boolean;
  userAccessScreensParams: boolean;
  [key: string]: any;
}

interface userRole {
  roleName: string;
}

type UserRoleT = {
  userId: number;
  roleRoleId: number;
};

export default function UserList() {
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [roleData, setRoleData] = useState([]);
  const [value, setValue] = useState([]);
  const [modifiedUsers, setModifiedUsers] = useState([]);
  const { pushToast } = useToasts();
  const { token } = useContext(LoginContext) as LoginType;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setUsers(res.data))
      .catch((err) => console.error(err));
  }, [token]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/role`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setRoleData(res.data))
      .catch((err) => console.error(err));
  }, [token]);

  const editRole = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      axios.patch(`${process.env.REACT_APP_API_URL}/user/roles`, value, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      pushToast({ type: "success", message: "Les droits ont été modifiés" });
    } catch (error) {
      console.error(error);
      pushToast({ type: "error", message: "Une erreur est survenue" });
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptionId = e.target.options[e.target.selectedIndex].id;
    const userRole: UserRoleT = {
      userId: Number(selectedOptionId),
      roleRoleId: Number(e.target.value),
    };

    const userIndex: number = value.findIndex(
      (user: { userId: string | number }) => user.userId === userRole.userId
    );

    if (userIndex >= 0) {
      const newValue: any = [...value];
      newValue[userIndex] = userRole;
      setValue(newValue);
    } else {
      const newValue: any = [...value];
      newValue.push(userRole); // ajouter un nouvel objet
      setValue(newValue);
    }
  };

  return (
    <form className="user_list_container" onSubmit={editRole}>
      <table className="table-container">
        <thead className="table-thead">
          <tr>
            <th className="table-th">userId</th>
            <th>Nom et prénom</th>
            <th>téléphone</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody className="table-tbody">
          {users
            .sort((a, b) => a.userId - b.userId)
            .map((user, index) => (
              <tr className="table-tr" key={index}>
                <td className="table-td">{user.userId}</td>
                <td className="table-td">{user.userName}</td>
                <td className="table-td">{user.userPhone}</td>
                <td className="table-td">{user.userEmail}</td>
                <td className="table-td">
                  <select className="table-select" onChange={handleChangeValue}>
                    <option>---- {user.Role.roleName} ----</option>
                    {roleData.map(
                      (role: { roleName: string; roleId: number }) => (
                        <option
                          id={user.userId.toString()}
                          value={role.roleId}
                          key={role.roleId}
                        >
                          {role.roleName}
                        </option>
                      )
                    )}
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <GenericBtn text={"Enregistrer"} />
    </form>
  );
}
