import { useCallback, useContext } from "react";

import { Params, ToastContext } from "../contexts/ToastContext";

export function useToasts() {
  const { pushToastRef } = useContext(ToastContext);
  return {
    pushToast: useCallback(
      (toast: Params) => {
        pushToastRef.current(toast);
      },
      [pushToastRef]
    ),
  };
}
