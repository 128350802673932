export const changeFileExtension = (
	filename: string,
	newExtension: string
): string => {
	const lastDotIndex = filename.lastIndexOf(".");
	if (lastDotIndex && lastDotIndex === -1) {
		return filename + "." + newExtension;
	}
	return filename?.slice(0, lastDotIndex + 1) + newExtension;
};
