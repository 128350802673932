import React from "react";
import "./AllProjectPage.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FilterInput from "../../components/filter/FilterInput";
import Accordeon from "../../components/utils/accordeon/Accordeon";
import ResponsiveTableAssistant from "../../components/utils/array/ResponsiveTableAssistant";
import {
  IoAddCircleOutline,
  IoChevronDownOutline,
  IoChevronUpOutline,
} from "react-icons/io5";
import Divider from "../../components/utils/divider/Divider";
import Loader from "../../components/loading/Loader";
import Popover from "../../components/modal/Popover";
import { ConfirmButton } from "../../components/goBack/GoBack";

export default function AllProjectPage() {
  const [projects, setProjects] = useState<[]>([]);

  const [filteredProjects, setFilteredProjects] = useState<string>("");
  const [filteredPreparator, setFilteredPreparator] = useState<string>("");
  const [filteredCompany, setFilteredCompany] = useState<string>("");

  const [standByfilteredPreparator, setStandByFilteredPreparator] =
    useState<string>("");
  const [standByfilteredCompany, setStandByFilteredCompany] =
    useState<string>("");
  const [isToggle, setIsToggle] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [standByProjects, setStandByProjects] = useState<[]>([]);
  const [preparators, setPreparators] = useState<[]>([]);
  const [companys, setCompanys] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStandByPage, setCurrentStandByPage] = useState(1);
  const [projectIdToDelete, setProjectIdToDelete] = useState<number>(0);
  const perPage = 20;
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfStandByPages, setNumberOfStandByPages] = useState(0);

  /**
   *
   * get the token in the session storage
   *
   *  */
  const token = localStorage.getItem("token");
  const parsedToken = JSON.parse(token as string);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/projet/projets?page=${currentPage}&perPage=${perPage}`,
          {
            headers: {
              Authorization: `Bearer ${parsedToken}`,
            },
            params: {
              status: "EnCours",
            },
          }
        );

        setNumberOfPages(response.data.totalPages);
        setProjects(response.data.projects);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [currentPage, perPage, parsedToken]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const handleStandByPageChange = (newPage: number) => {
    setCurrentStandByPage(newPage);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/preparator`
        );
        setPreparators(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/projet/projets?page=${currentStandByPage}&perPage=${perPage}`,
          {
            headers: {
              Authorization: `Bearer ${parsedToken}`,
            },
            params: {
              status: "StandBy",
            },
          }
        );
        setNumberOfStandByPages(response.data.totalPages);
        setStandByProjects(response.data.projects);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [parsedToken, perPage, currentStandByPage]);

  useEffect(() => {
    (async () => {
      try {
        const companys = await axios.get(
          `${process.env.REACT_APP_API_URL}/company`
        );
        setCompanys(companys.data);
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, []);

  const toggleStandBy = () => {
    setIsToggle(!isToggle);
  };

  const openPopover = (id: number) => {
    setProjectIdToDelete(id);

    setIsOpen(true);
  };

  const deleteProject = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/projet/${projectIdToDelete}`
      );

      if (response.status === 200) {
        setProjectIdToDelete(0);
        window.location.reload();
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return !isLoading ? (
    <div className="all-projects-page">
      <div className="all-project-container">
        <div className="all_project_title">
          <h1>Liste des dossiers</h1>
          <FilterInput
            setFilteredProjet={setFilteredProjects}
            value={filteredProjects}
          />
        </div>
        <Accordeon />
        <div>
          <h1>Liste des dossiers en cours</h1>
        </div>
        <Link to="/create-project" className="all-project-menu">
          <IoAddCircleOutline size={36} className="more" />
          nouveau dossier
        </Link>

        <div className="all-project-box">
          <ResponsiveTableAssistant
            projets={projects}
            filteredProjet={filteredProjects}
            filteredPreparator={filteredPreparator}
            filteredCompany={filteredCompany}
            preparatorArray={preparators}
            setPreparatorState={setFilteredPreparator}
            setCompanyState={setFilteredCompany}
            companyArray={companys}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onClick={openPopover}
          />
        </div>

        <Divider color="#000" marginTop={30} />
        <div>
          <div className="standby" onClick={toggleStandBy}>
            <div className="standby_arrow">
              {isToggle ? (
                <IoChevronDownOutline size={24} onClick={toggleStandBy} />
              ) : (
                <IoChevronUpOutline onClick={toggleStandBy} size={24} />
              )}
            </div>
            <h1>Projets en stand-by</h1>
          </div>
          {isToggle && (
            <div className="all-project-box">
              <ResponsiveTableAssistant
                projets={standByProjects}
                filteredProjet={filteredProjects}
                filteredPreparator={standByfilteredPreparator}
                filteredCompany={standByfilteredCompany}
                preparatorArray={preparators}
                setPreparatorState={setStandByFilteredPreparator}
                setCompanyState={setStandByFilteredCompany}
                companyArray={companys}
                handlePageChange={handleStandByPageChange}
                currentPage={currentStandByPage}
                numberOfPages={numberOfStandByPages}
                onClick={openPopover}
              />
            </div>
          )}
        </div>
      </div>
      <Popover isOpen={isOpen} onClick={() => setIsOpen(false)}>
        <div>
          <p>Souhaitez vous vraiment supprimer ce dossier ?</p>
          <p>Attention cette action est irréversible</p>
          <ConfirmButton
            onClickYes={deleteProject}
            onClickNo={() => setIsOpen(false)}
          />
        </div>
      </Popover>
    </div>
  ) : (
    <div className="loader_page">
      <p>Chargement des dossiers en cours...</p>
      <Loader />
    </div>
  );
}
