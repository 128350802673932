import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "../bullet-point/progress-bar/ProgressBar";
import "./MultiStep.css";
import Header from "../multi-step/Header";
import Archivage from "../../pages/step/Archivage";
import EnvoiPrepa from "../../pages/step/EnvoiPrepa";
import GestionErreur from "../../pages/step/GestionErreur";
import RetourPreparation from "../../pages/step/RetourPreparation";
import Facturation from "../../pages/step/Facturation";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import GestionErreurMapwood from "../../pages/step/mapwood/GestionErreurMapwood";
export default function MultiStep() {
  const { id } = useParams<{ id: string }>();
  const [currentStep, setCurrentStep] = useState<multiStepI>({} as multiStepI);
  const role = localStorage.getItem("role");
  const parsedRole = JSON.parse(role as string);
  useEffect(() => {
    if (id === undefined) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/projet/${id}`)
      .then((res) => setCurrentStep(res.data))
      .catch((err) => console.error(err));
  }, [id]);

  useEffect(() => {
    (async () => {
      if (currentStep.dicoId === undefined) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/stuffs/${currentStep?.dicoId}`
        );
        currentStep.stuffs = response?.data?.filter(
          (stuff: any) =>
            stuff?.equipementName !== "MWZoneProt" &&
            stuff?.equipementName !== "InfoProp" &&
            stuff?.equipementName !== "ProcAbs"
        );
      } catch (err) {
        console.error(err);
      }
    })();
  }, [currentStep]);

  return (
    <div className="multiStep">
      {id !== undefined && (
        <Header
          nomDossier={currentStep?.projetName}
          refDossier={currentStep?.projetReference}
        />
      )}
      {parsedRole === "ADMIN" || parsedRole === "ASSISTANT_GESTION" ? (
        <Link to={`/edit-project/${id}`} className="edit_project">
          <HiOutlinePencilSquare size={24} />
          <p>Editer le projet</p>
        </Link>
      ) : (
        <></>
      )}
      <ProgressBar
        currentStep={id === undefined ? 1 : currentStep?.stepId}
        id={id}
      />
      {currentStep?.stepId === 1 && <EnvoiPrepa currentStep={currentStep} />}
      {currentStep?.stepId === 2 && currentStep?.projetType === "CLASSIQUE" && (
        <GestionErreur currentStep={currentStep} />
      )}
      {currentStep?.stepId === 2 && currentStep?.projetType === "MAPWOOD" && (
        <GestionErreurMapwood currentStep={currentStep} />
      )}
      {currentStep?.stepId === 3 && (
        <RetourPreparation currentStep={currentStep} />
      )}
      {currentStep?.stepId === 4 && <Facturation currentStep={currentStep} />}
      {currentStep?.stepId === 5 && <Archivage currentStep={currentStep} />}
    </div>
  );
}
