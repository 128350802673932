import { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import InfoPopover from "../../modal/InfoPopover";
import "./Card.css";

export default function Card({
	children,
	number,
	display = true,

	content = "",
	id,
}: {
	children?: JSX.Element[];
	number: string;
	display?: boolean;

	content?: string;
	id?: string;
}): JSX.Element {
	const [show, setShow] = useState<boolean>(false);
	return (
		<div className="card-container">
			<div className="card-wrapper">
				{display && (
					<div className="card-number">
						<div className="number">{number}</div>
					</div>
				)}
				<div className="card-information">
					<IoInformationCircleOutline
						size={24}
						className="help"
						onMouseEnter={() => setShow(true)}
						onMouseLeave={() => setShow(false)}
					/>
				</div>
				{children}
				<div className="popover-info-container">
					<InfoPopover show={show} content={content} id={id} />
				</div>
			</div>
		</div>
	);
}
