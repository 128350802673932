import { View, Text } from "@react-pdf/renderer";

import { styles } from "./StyleSheet";

export const Row = ({
	length,
	fics,
	equipe,
}: {
	length: number;
	fics: string;
	equipe: string;
}) => {
	return (
		<View style={styles.row}>
			<Text style={styles.cellHeader}>{equipe}</Text>
			<Text>({length ? length : 0}) :</Text>
			<Text style={styles.fics}>{fics}</Text>
		</View>
	);
};
