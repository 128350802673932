import { useContext } from "react";
import { Link } from "react-router-dom";
import { LoginContext, LoginType } from "../../../contexts/LoginContext";
import { truncate } from "../../assistant/RowPreparator";
import "./ResponsiveTable.css";

interface ProjetInterface {
	projetId: number;
	projetName: string;
	stepId: number;
	User: { userName: string };
	File: { fileName: string };
}

export const redirectByRole = (
	role: string,
	projet: {
		projetId: number;
	}
): string => {
	if (role === "ASSISTANT_GESTION") {
		return `/projet/${projet.projetId}`;
	} else if (role === "PREPARATEUR") {
		return `/correction/${projet.projetId}`;
	}
	return "/";
};

function Thead() {
	return (
		<thead className="responsive-table">
			<tr className="responsive-table thead_row">
				<th className="responsive-table__head__title thead_cell">
					Nom du dossier
				</th>
				<th className="responsive-table__head__title thead_cell">Etape</th>
				<th className="responsive-table__head__title thead_cell">
					Preparateur
				</th>
				<th className="responsive-table__head__title thead_cell">Fichier</th>
			</tr>
		</thead>
	);
}

function Tbody({
	projets,
	filteredProjet,
}: {
	projets: [];
	filteredProjet?: string;
}) {
	const { role } = useContext(LoginContext) as LoginType;

	return (
		<tbody className="responsive-table tbody">
			{projets
				.sort(
					(
						a: any,
						b: any // sort by alpahbetical order
					) => (a.projetName > b.projetName ? 1 : -1)
				)
				.filter((itemFilter: any) =>
					itemFilter.projetName?.includes(filteredProjet?.toUpperCase())
				)
				?.map((projet: ProjetInterface, index: number) => (
					<tr className="responsive-table tbody_row" key={index}>
						<td className="responsive-table__body__text  tbody_cell tprojet ">
							<Link to={redirectByRole(role, projet)} className="row_link">
								{projet.projetName}
							</Link>
						</td>
						<td className="responsive-table__body__text tbody_cell">
							{projet.stepId}
						</td>
						<td className="responsive-table__body__text tbody_cell">
							{projet.User.userName}
						</td>
						<td className="responsive-table__body__text tbody_cell">
							{truncate(projet?.File?.fileName, 13)}
						</td>
					</tr>
				))}
		</tbody>
	);
}

function TableTag({ children }: { children?: JSX.Element }): JSX.Element {
	return <table className="responsive-table">{children}</table>;
}

export default function ResponsiveTable({
	projets,
	filteredProjet,
}: {
	projets: [];
	filteredProjet?: string;
}) {
	return (
		<div className="container">
			<TableTag>
				<>
					<Thead />
					<Tbody projets={projets} filteredProjet={filteredProjet} />
				</>
			</TableTag>
		</div>
	);
}
