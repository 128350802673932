import React, { useEffect, useState } from "react";
import Popover from "../../components/modal/Popover";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { useParams } from "react-router-dom";
import DownloadBtn from "../../components/layout/buttons/download/DownloadBtn";
import GenericBtn from "../../components/layout/buttons/generic/GenericBtn";
import BtnUpload from "../../components/layout/buttons/upload/BtnUpload";
import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Card from "../../components/layout/cards/Card";
import { MyDocument } from "../../components/pdf/Pdf";
import { removeSixFirstCharAndExtension } from "../../functions/removeSixFirstCharAndExtension";
import "./RetourPreparation.css";
import GoBack from "../../components/goBack/GoBack";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import { downloadRetourPrepaCorrected } from "../../functions/donwloadRPEdited";
import { downloadFileFromAPI } from "../../functions/downloadFileFromAPI";
import { downloadMapwood } from "../../functions/downloadMapwood";
import LoadingPopover from "../../components/modal/LoadingPopover";

export default function RetourPreparation({
  currentStep,
}: {
  currentStep: multiStepI;
}): JSX.Element {
  /* boolean */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /* file */
  const [files, setFiles] = useState<File | null>(null);
  const [agiFile, setAgiFile] = useState<File | null>(null);
  /* array */
  const [retourPrepa, setRetourPrepa] = useState<[]>([]);
  const [convertedFile, setConvertedFile] = useState<[]>([]);

  /* string */
  const { id } = useParams();
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [loadingMessage, setLoadingMessage] = useState<string>("");

  const getRetourPrepa = async () => {
    setIsLoading(true);
    setLoadingMessage("Téléchargement du fichier en cours...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/retour-prepa/corrected/${id}`
      );
      downloadRetourPrepaCorrected(
        response.data,
        `RP_${currentStep?.File?.fileName}.csv`
      );
      setIsLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoadingMessage(error.message);
      setTimeout(() => {
        setIsLoading(false);
        setLoadingMessage("");
      }, 5000);
    } finally {
      if (!loadingMessage) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/retour-prepa/corrected/${id}`
        );
        setRetourPrepa(response.data);
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, []);

  const convertR6toRx = async () => {
    setIsLoading(true);
    setLoadingMessage("Convertion du fichier en cours...");
    try {
      const file = await axios.post(
        `${process.env.REACT_APP_API_URL}/converts/reverse/${id}/${currentStep.rFileId}`,
        {
          retourPrepa: retourPrepa,
          rFile: currentStep.rFileId,
          fileId: currentStep.fileFileId,
        }
      );
      setIsLoading(false);
      if (file.status === 200) {
        setIsOpen(true);
        setConfirmMessage("Votre fichier à bien été transformé.");
        setConvertedFile(file?.data);
      }
      setTimeout(() => {
        setIsOpen(false);
      }, 1500);
    } catch (error: any) {
      console.error(error);
      setConfirmMessage(error.message);
      setTimeout(() => {
        setIsOpen(false);
        setConfirmMessage("");
      }, 5000);
    } finally {
      if (!loadingMessage) {
        setIsLoading(false);
      }
    }
  };

  const goToNextStep = async () => {
    const data = {
      stepId: 4,
    };
    try {
      axios.patch(`${process.env.REACT_APP_API_URL}/projet/${id}`, data);
      setIsOpen(true);
      setConfirmMessage("le dossier est prêt à partir en facturation.");
      setTimeout(() => {
        setIsOpen(false);
        window.location.reload();
      }, 1500);
    } catch (err) {
      setConfirmMessage("Erreur lors de la mise à jour du dossier.");
      console.error(err);
    }
  };

  const downloadConvertRetourPrepaWithoutEquipe = async () => {
    setIsLoading(true);
    setLoadingMessage("Téléchargement du fichier en cours...");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/traitement/replace/convert/${id}`,
        { convertFile: convertedFile, fileId: currentStep.rFileId }
      );
      downloadRetourPrepaCorrected(
        response.data,
        `RP_${currentStep?.File.fileName}.csv`
      );
      setIsLoading(false);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoadingMessage("");
    }
  };

  const downloadRetourPrepaWithoutEquipe = async () => {
    setIsLoading(true);
    setLoadingMessage("Téléchargement du fichier en cours...");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/traitement/replace/${id}`,
        retourPrepa,
        {
          responseType: "blob",
        }
      );

      downloadFileFromAPI(response.data, currentStep?.File.fileName, "csv");
      setIsLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoadingMessage(error.message);
      setTimeout(() => {
        setIsLoading(false);
        setLoadingMessage("");
      }, 5000);
    } finally {
      if (!loadingMessage) {
        setIsLoading(false);
      }
    }
  };

  const generateAGI = async () => {
    const formData = new FormData();
    formData.append("file", agiFile as File);
    setIsLoading(true);
    setLoadingMessage("Génération de l'agi de prod en cours...");
    try {
      if (agiFile) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/agi/${id}`,
          formData,
          {
            responseType: "blob",
          }
        );
        downloadFileFromAPI(res.data, agiFile?.name, "agi");
        setLoadingMessage("La génération de l'agi de prod est terminée.");
      } else {
        console.error(
          "Aucun fichier sélectionné pour la génération de l'agi de prod."
        );
      }
    } catch (error: any) {
      console.error(error);
      setLoadingMessage(error.message);
      setTimeout(() => {
        setIsLoading(false);
        setLoadingMessage("");
      }, 5000);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setLoadingMessage("");
      }, 5000);
    }
  };

  const generateMapwood = async () => {
    setIsLoading(true);
    setLoadingMessage("Génération du fichier Mapwood en cours...");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/mapping-mapwood/reverse/${id}`,
        {
          retourPrepa: retourPrepa,
        }
      );
      setConvertedFile(response.data);
      downloadMapwood(response.data, `mapwood.csv`);
      setLoadingMessage("La génération du fichier Mapwood est terminée.");
    } catch (error: any) {
      console.error(error);
      setLoadingMessage(error.message);
      setTimeout(() => {
        setIsLoading(false);
        setLoadingMessage(""); // Réinitialisation du message d'erreur après un certain délai
      }, 5000);
    } finally {
      if (!loadingMessage) {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Card number="1">
        <p>
          Télécharger le ficher de retour préparation sans erreur à importer
          dans Arpengtis
        </p>
        <DownloadBtn download={getRetourPrepa} text="Télécharger" />
      </Card>
      <Card number="2">
        <p>Uploader le fichier d&apos;Arpentgis (fonctionnalitée à venir)</p>
        <BtnUpload setFiles={setFiles} disabled={true} />
      </Card>

      <Card number="3">
        <h4>Générer votre agi de production</h4>
        <p>veuillez uploader votre fichier agi de retour prepa</p>
        <BtnUpload
          setFiles={setAgiFile}
          disabled={false}
          fileName={agiFile?.name}
        />
        {agiFile ? (
          <div>
            <h4>Télécharger le nouveau fichier AGI</h4>
            <GenericBtn onClick={generateAGI} text="Générer" />
          </div>
        ) : (
          <></>
        )}
      </Card>
      {currentStep.rFileId !== 4 ? (
        <Card number="4">
          <p>Transformer votre fichier en fichier RX</p>
          <GenericBtn
            onClick={convertR6toRx}
            text="Convertir le fichier en RX"
          />
        </Card>
      ) : (
        <></>
      )}
      <Card number={currentStep.rFileId !== 4 ? "5" : "4"}>
        {!isConfirm === true ? (
          <>
            <p>Télécharger les fichiers de retour prepa</p>
            <GenericBtn onClick={() => setIsConfirm(true)} text="Confirmer" />
          </>
        ) : (
          <></>
        )}
        {isConfirm === true ? (
          <div>
            <p>Télécharger vos fichiers</p>
            <div className="rp_completed_btn">
              <GenericBtn text="MapWood" onClick={generateMapwood} />
              <GenericBtn
                text="Fichier RP"
                onClick={
                  currentStep.rFileId === 4
                    ? downloadRetourPrepaWithoutEquipe
                    : downloadConvertRetourPrepaWithoutEquipe
                }
              />

              <button className="download_synthese">
                <PDFDownloadLink
                  document={
                    <MyDocument
                      id={id}
                      preparateur={currentStep.User.userName}
                      nom_du_dossier={currentStep.projetName}
                      dico_version={removeSixFirstCharAndExtension(
                        currentStep.Dico.dicoVersion
                      )}
                      ref_du_dossier={currentStep.projetReference}
                    />
                  }
                  fileName={`${currentStep.projetName}_syntheseProd.pdf`}
                  className="download_link"
                >
                  {({ loading }) =>
                    loading ? "Loading document..." : "Téléchager la synthèse"
                  }
                </PDFDownloadLink>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
      <Card number={currentStep.rFileId !== 4 ? "6" : "5"}>
        <p>Passer à l'étape suivante</p>
        <ValidateStepBtn validate={goToNextStep} text="Valider l'étape" />
      </Card>
      <GoBack currentStep={currentStep?.stepId} />
      <Popover isOpen={isOpen} onClick={() => setIsOpen(false)}>
        <p>{confirmMessage}</p>
      </Popover>
      <LoadingPopover isLoading={isLoading} message={loadingMessage} />
    </div>
  );
}
