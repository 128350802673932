import {
  useEffect,
  useState,
  ChangeEvent,
  MouseEvent,
  useContext,
} from "react";
import axios from "axios";
import SelectPreparator from "../../components/assistant/SelectPreparator";
import BtnUpload from "../../components/layout/buttons/upload/BtnUpload";
import "./CreateProject.css";
import Card from "../../components/layout/cards/Card";
import Popover from "../../components/modal/Popover";
import InitProjectPrice from "../../components/forms/InitProjectPrice";
import useRedirect from "../../hook/useRedirect";
import { UserContext, UserContextInterface } from "../../contexts/UserContext";
import SelectType from "../../components/assistant/SelectType";

export default function CreateProject() {
  /* ---------------- STRING ---------------- */
  const [dico, setDico] = useState<number | string | null>(null);
  const [company, setCompany] = useState<number | string | null>(null);
  const [confirmMessage, setConfirmMessage] = useState<String>("");
  const [reference, setReference] = useState<string>("");
  const [projetType, setProjetType] = useState<string>("");
  const [priceByTheCut, setPriceByTheCut] = useState<string>("");
  const [pricePerKm, setPricePerKm] = useState<string>("");
  /* ---------------- NUMBER ---------------- */
  const [preparatorId, setPreparatorId] = useState<number>(0);
  const [rFile, setRfile] = useState<number>(4);
  /* ---------------- FILE ---------------- */
  const [files, setFiles] = useState<File | null>(null);
  /* ---------------- ARRAY ---------------- */
  const [preparator, setPreparator] = useState<[]>([]);
  /* ---------------- BOOLEAN ---------------- */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { currentUserId } = useContext(UserContext) as UserContextInterface;
  /* ---------------- CUSTOM HOOK ---------------- */
  const navigate = useRedirect();

  /* ---------------- USE EFFECT ---------------- */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/preparator`
        );
        setPreparator(response.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  /* ---------------- API CALL ----------------*/
  const handleSubmit = async (e: MouseEvent) => {
    e.preventDefault();
    let fileId: number | null = null;
    let projetId: number | null = null;
    const formData = new FormData();
    setConfirmMessage("");
    if (files !== null) {
      formData.append("file", files);
      try {
        const newFile = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload`,
          formData
        );
        fileId = await newFile.data.fileId;
      } catch (err: any) {
        console.error(err);
        setConfirmMessage(
          "Une erreur s'est produite lors du téléchargement du fichier."
        );
        return;
      }
    }
    if (files === null) {
      console.error({ message: "veuillez selectionner un fichier" });
      setConfirmMessage("Veuillez sélectionner un fichier.");
      return;
    }
    /**
     * création d'un objet avec les données du dossier
     */
    const data = {
      userId: preparatorId,
      dicoId: Number(dico),
      projetPriceByTheCut: priceByTheCut,
      projetPricePerKm: pricePerKm,
      stepId: projetType === "MAPWOOD" ? 2 : 1,
      isOpenForPreparator: false,
      fileId: Number(fileId),
      projetCreatorId: Number(currentUserId),
      rFileId: Number(rFile),
      company: Number(company),
      projetReference: reference,
      projetType: projetType,
    };

    formData.append("data", JSON.stringify(data));
    try {
      const projet = await axios.post(
        `${process.env.REACT_APP_API_URL}/projet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      projetId = await projet.data.projetId;
      await axios.post(`${process.env.REACT_APP_API_URL}/equipe-projet`, {
        projetId: Number(projetId),
        dicoId: Number(dico),
      });
      setIsOpen(true);
      setConfirmMessage("Le dossier est prêt à partir en préparation.");
      setTimeout(() => {
        setIsOpen(false);
        navigate(`/projet/${projetId}`);
      }, 2000);
      return; // Sortir de la fonction après avoir affiché le message de confirmation
    } catch (err: any) {
      console.error(err);
      setConfirmMessage(
        "Une erreur s'est produite lors de la création du dossier."
      );
    }
  };

  /* ---------------- FEATURE ----------------*/
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPreparatorId(Number(e.target.value));
  };

  const handlechangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    setProjetType(e.target.value);
  };

  return (
    <div className="create-project-page">
      <div>
        <h1>Créer un nouveau dossier</h1>
      </div>
      <Card number="1">
        <p>Type de depart</p>
        <SelectType onChange={handlechangeType} />
      </Card>
      <Card number="2">
        <p>selectionner le fichier enedis</p>
        <BtnUpload setFiles={setFiles} fileName={files?.name} />
        <div className="create-project-dossier">
          <label className="edit-project-label">
            Définir la référence du dossier
          </label>
          <input
            onChange={(e) => setReference(e.target.value)}
            value={reference}
          />
        </div>
      </Card>
      <Card number="3">
        <p>Selectionner le preparateur</p>
        <SelectPreparator
          preparators={preparator}
          onChange={handleChange}
          className="first-step-select-comp"
        />
      </Card>

      <Card number="4">
        <p>Paramétrer le dossier</p>
        <InitProjectPrice
          setDico={setDico}
          setPriceByTheCut={setPriceByTheCut}
          setPriceForKm={setPricePerKm}
          priceForKm={pricePerKm}
          priceByTheCut={priceByTheCut}
          setRfile={setRfile}
          setCompany={setCompany}
        />
      </Card>

      <Popover isOpen={isOpen}>
        <p>{confirmMessage}</p>
      </Popover>
      <div className="create-project-btn">
        <button className="create-btn" onClick={handleSubmit}>
          Créer le nouveau dossier
        </button>
      </div>
    </div>
  );
}
