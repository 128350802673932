import "./TrancheArray.css";
export default function TrancheArray({
  tranche,
}: {
  tranche: [];
}): JSX.Element {
  return (
    <table className="table-tranche">
      <thead className="table-tranche-thead">
        <tr>
          <th>Tranche déjà facturée</th>
          <th>Distance fact.</th>
          <th>Montant fact.</th>
          <th>Distance RAF</th>
          <th>Montant RAF</th>
        </tr>
      </thead>
      <tbody>
        {tranche.map(
          (item: {
            trancheIndice: number;
            trancheDistance: number;
            sumOfDistanceRaf: number;
            trancheRAFDistance: number;
            trancheRAFMontant: number;
            trancheMontant: number;
          }) => (
            <tr key={item.trancheIndice} className="table-tranche-row">
              <td>{item.trancheIndice}</td>
              <td>{item.trancheDistance} km</td>
              <td>{item.trancheMontant} €</td>
              <td>{item.trancheRAFDistance} km</td>
              <td>{item.trancheRAFMontant} €</td>
            </tr>
          )
        )}
      </tbody>
      <tfoot>
        <tr className="table-tranche-row foot">
          <td>Total</td>
          <td>
            {tranche.reduce(
              (acc: any, cur: any) => acc + cur.trancheDistance,
              0
            )}
            km
          </td>
          <td>
            {tranche.reduce(
              (acc: any, cur: any) => acc + cur.trancheMontant,
              0
            )}
            €
          </td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );
}
