import { utils } from "xlsx";
import { changeFileExtension } from "./changeFileExtension";

export type objType = {
	[key: string]: string | undefined;
};

export const exportFileToCsv = (
	data: [],
	fileName: string,
	callBack: () => void
) => {
	return new Promise<void>((resolve, reject) => {
		if (data.length > 0) {
			const valEachObj = Object.values(data);

			//* get the value of each object
			const val = valEachObj.map((item: [[{ val: string }]]) =>
				item.map((i: [{ val: string }]) => i[0]?.val)
			);

			//* create new object with each val
			const object = val?.map((item: unknown[]) => {
				const obj: objType = {};
				item.forEach((i: any, index: number) => {
					obj[val[0][index]] = i;
				});
				return obj;
			});
			//* remove first row
			const removeFirstRow = object.slice(1);

			//* convert to sheet
			const ws = utils.json_to_sheet(removeFirstRow);
			//* change the separator to semi-comma
			const csv = utils.sheet_to_csv(ws, {
				FS: ";",
			});
			const encodedData = "\ufeff" + csv; // ajoute le BOM pour l'encodage UTF-8
			//* generate a csv file
			if (data) {
				//		Créer un objet Blob avec les données CSV encodées en UTF-8
				const blob = new Blob([encodedData], {
					type: "text/csv;charset=utf-8;",
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", changeFileExtension(fileName, "csv"));
				document.body.appendChild(link);
				link.click();
			} else {
				console.error("no file");
			}
			callBack();
			resolve(); // Résoud la promesse
		} else {
			console.error({
				message: "Les traitements n'ont pas été appliqués ❌",
			});
			reject(new Error("Les traitements n'ont pas été appliqués ❌")); // Rejette la promesse avec une erreur
		}
	});
};
