import "./SelectProject.css";
export default function SelectRole({
	setValue,
	roleData,
}: {
	setValue: React.Dispatch<React.SetStateAction<string>>;
	roleData: [];
}) {
	return (
		<select onChange={(e) => setValue(e.target.value)} className="select-prepa">
			<option value=""> ----- Selectionner un role ----- </option>
			{roleData.map((role: { roleName: string; roleId: number }) => (
				<option value={role.roleId} key={role.roleId}>
					{role.roleName}
				</option>
			))}
		</select>
	);
}
