import React, { useContext, useState } from "react";
import "./NavBar.css";
import { FaUserCog, FaCode, FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import elagage from "../../../assets/elagage.png";
import Divider from "../../utils/divider/Divider";

import LogoutBtn from "../../utils/buttons/LogoutBtn";
import Badge from "../../utils/badge/Badge";
import { v4 as uuidv4 } from "uuid";
import {
  UserContext,
  UserContextInterface,
} from "../../../contexts/UserContext";

const NavRow = ({
  path,
  icon,
  name,
}: {
  path: string;
  icon: JSX.Element;
  name: string;
}) => {
  return (
    <div className="drawer-nav">
      <div className="drawer-row">
        <div className="drawer-link-box">
          <Link to={path} className="drawer-link">
            {icon}
            <span>{name}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default function NavBar({
  setIsLoggedIn,
}: {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const { currentUserName } = useContext(UserContext) as UserContextInterface;

  const firstArr = [
    {
      id: uuidv4(),
      name: "Accueil",
      path: "/",
      icon: <FaHome className="icon" />,
    },
  ];
  const adminArr = [
    {
      id: uuidv4(),
      name: "Admin Config",
      path: "/dashboard/admin",
      icon: <FaUserCog className="icon" />,
    },
  ];

  const devArr = [
    {
      id: uuidv4(),
      name: "Developpeur Config",
      path: "/dashboard/dev",
      icon: <FaCode className="icon" />,
    },
  ];

  const drawerNav = firstArr.map((item) => (
    <NavRow key={item.id} path={item.path} icon={item.icon} name={item.name} />
  ));

  const drawerAdminNav = adminArr.map((item) => (
    <NavRow key={item.id} path={item.path} icon={item.icon} name={item.name} />
  ));

  const drawerDevNav = devArr.map((item) => (
    <NavRow key={item.id} path={item.path} icon={item.icon} name={item.name} />
  ));
  /**
   *
   * get the token in the session storage
   *
   *  */
  const role = localStorage.getItem("role");
  const parsedRole = JSON.parse(role as string);

  return (
    <div className="nav-container">
      <div className="nav-box">
        <div className="nav-header">
          <img src={elagage} alt="ja-elagage" className="logo" loading="lazy" />
        </div>
        <div className="nav-badge">
          <div className="badge-position">
            <Badge userName={currentUserName} />
          </div>
          <Divider color="lightgray" />
        </div>
        <div className="nav-content">
          <div className="">
            {drawerNav}
            <Divider color="lightgray" />
          </div>
          {parsedRole === "ADMIN" && (
            <div>
              {drawerAdminNav}
              <Divider color="lightgray" />
            </div>
          )}
          {parsedRole === "DEV" ? (
            <div>
              {drawerDevNav}
              <Divider color="lightgray" />
            </div>
          ) : null}
          <div className="logout-container">
            <LogoutBtn setIsLoggedIn={setIsLoggedIn} />
          </div>
        </div>
      </div>
    </div>
  );
}
