import NavBar from "./components/navigation/navBar/NavBar";
import Router from "./Router";
import LoginContextProvider from "./contexts/LoginContext";
import { useEffect, useState } from "react";
import LoginForm from "./components/forms/LoginForm";
import "./App.css";
import FormProvider from "./contexts/FormContext";
import ToastProvider from "./contexts/ToastContext";
import UserProvider from "./contexts/UserContext";

function App() {
	const initialIsLoggedIn =
		JSON.parse(localStorage.getItem("isLoggedIn") as string) || false;
	const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedIn);

	useEffect(() => {
		localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
	}, [isLoggedIn]);

	return (
		<LoginContextProvider>
			<UserProvider>
				<ToastProvider>
					<FormProvider>
						{isLoggedIn ? (
							<>
								<NavBar setIsLoggedIn={setIsLoggedIn} />
								<div className="drawer-width">
									<Router
										setIsLoggedIn={setIsLoggedIn}
										isLoggedIn={isLoggedIn}
									/>
								</div>
							</>
						) : (
							<Router setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
						)}
					</FormProvider>
				</ToastProvider>
			</UserProvider>
		</LoginContextProvider>
	);
}

export default App;
