export const arrondirNombre = (nombre: number): number => {
  if (nombre < 0) {
    return -arrondirNombre(-nombre);
  }

  const partieEntiere = Math.floor(nombre);
  const partieDecimale = nombre - partieEntiere;

  if (partieDecimale >= 0.1 && partieDecimale < 0.5) {
    return partieEntiere + 0.5;
  } else if (partieDecimale > 0.5 && partieDecimale <= 0.9) {
    return partieEntiere + 1;
  } else {
    return nombre;
  }
};
