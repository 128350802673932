import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumb.css";
export default function Breadcrumb({
	items,
}: {
	items: { path: string; label: string }[];
}) {
	return (
		<ul>
			{items.map((item, index) => (
				<li key={index}>
					{item.path ? (
						<Link to={item.path} className="breadcrumb_link">
							{item.label}
						</Link>
					) : (
						item.label
					)}
				</li>
			))}
		</ul>
	);
}
