import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import "./FilterInput.css";
interface IFilter {
	setFilteredProjet: React.Dispatch<React.SetStateAction<string>>;
	value?: string;
	onClick?: () => Promise<void>;
}

export default function FilterInput({
	setFilteredProjet,
	value,
	onClick,
}: IFilter) {
	return (
		<div className="input-icons">
			<i className="icon" onClick={onClick}>
				<IoSearchOutline />
			</i>
			<input
				className="input-field"
				type="text"
				onChange={(e: any) => setFilteredProjet(e.target.value)}
				value={value}
				placeholder="Rechercher un dossier"
			/>
		</div>
	);
}
