import React from "react";
import ValidateStepBtn from "../layout/buttons/validate/ValidateStepBtn";

export default function TraitementList({
	headers,
	traitements,
	handleChangeSelect,
	validateTraitement,
}: {
	headers: [];
	traitements: [];
	handleChangeSelect: (
		e: React.ChangeEvent<HTMLSelectElement>,
		headerId: number
	) => void;
	validateTraitement: () => void;
}) {
	return (
		<div>
			<h1>Listes des traitements</h1>
			<table className="var_cell var_table">
				<thead>
					<tr className="var_head_row">
						<th className="var_th">Nom du traitement</th>
						<th className="var_th">Valeur</th>
						<th className="var_th">Traitement</th>
					</tr>
				</thead>
				<tbody>
					{headers
						.sort(
							(
								a: {
									headerColumn: string;
								},
								b: { headerColumn: string }
							) => {
								if (a.headerColumn.length != b.headerColumn.length) {
									return a.headerColumn.length - b.headerColumn.length;
								}
								return a.headerColumn.localeCompare(b.headerColumn);
							}
						)
						.map(
							(v: {
								headerColumn: string;
								headerValue: string;
								headerId: number;
								Traitement: {
									traitementDescription: string;
								};
							}) => (
								<tr className="var_body_row" key={v.headerId}>
									<td className="var_td">{v.headerColumn}</td>
									<td className="var_td">{v.headerValue}</td>
									<td className="var_td">
										<select onChange={(e) => handleChangeSelect(e, v.headerId)}>
											<option value="">
												{v?.Traitement?.traitementDescription ||
													"----------------"}
											</option>
											{traitements.map(
												(trait: {
													traitementId: number;
													traitementDescription: string;
												}) => (
													<option
														value={trait.traitementId}
														key={trait.traitementId}
													>
														{trait.traitementDescription}
													</option>
												)
											)}
										</select>
									</td>
								</tr>
							)
						)}
				</tbody>
			</table>
			<ValidateStepBtn
				validate={validateTraitement}
				text="Valider mes traitements"
			/>
		</div>
	);
}
