import { LoginContext, LoginType } from "../../../contexts/LoginContext";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import GenericBtn from "../../../components/layout/buttons/generic/GenericBtn";
import { useToasts } from "../../../hook/useToasts";
import { EquipeInterface } from "../../../Types/Interfaces/Equipe";
import "./TeamsMapping.css";
import Breadcrumb from "../../../components/utils/breadCrumb/Breadcrumb";
import { parseCharSpe } from "../../../functions/replaceCharSpe";
export default function TeamsMapping() {
	const [teams, setTeams] = useState<[]>([]);
	const [dicos, setDicos] = useState<[]>([]);
	const [variables, setVariables] = useState<[]>([]);
	const [dico, setDico] = useState<{ dicoId: string; dicoVersion: string }>({
		dicoId: "",
		dicoVersion: "",
	});
	const [equipeColEdited, setEquipeColEdited] = useState<any>([]);
	const [equipeVarEdited, setEquipeVarEdited] = useState<any>([]);
	const [mergeArray, setMergeArray] = useState([]);
	const { token } = useContext(LoginContext) as LoginType;
	const { pushToast } = useToasts();

	useEffect(() => {
		(async () => {
			try {
				const responses = await axios.get(
					`${process.env.REACT_APP_API_URL}/equipes/${dico.dicoId}`
				);
				setTeams(responses.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, [dico.dicoId]);

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/dico`
				);
				setDicos(response.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	const getVersions = (dico: string): string[] => {
		const dicoVersion = dico.split("");
		const dicoVersionNumber = dicoVersion.filter((item) => {
			return !isNaN(Number(item));
		});

		return dicoVersionNumber;
	};

	const patchColonnes = () => {
		try {
			axios.patch(`${process.env.REACT_APP_API_URL}/equipes`, mergeArray);
			pushToast({
				type: "success",
				message: "Les colonnes ont bien été modifiées",
			});
		} catch (error: unknown) {
			pushToast({ type: "error", message: "Une erreur est survenue" });
			console.error(error);
		}
	};

	useEffect(() => {
		if (!token) {
			return;
		}
		(async () => {
			try {
				const responses = await axios.get(
					`${process.env.REACT_APP_API_URL}/variables`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				setVariables(responses.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, [token]);

	const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const itemIndex = equipeColEdited.findIndex(
			(item: { equipe_id: number }) => item.equipe_id === Number(name)
		);
		if (itemIndex >= 0) {
			setEquipeColEdited((prevData: any) =>
				prevData.map((item: EquipeInterface, index: number) =>
					index === itemIndex ? { ...item, value: value } : item
				)
			);
		} else {
			setEquipeColEdited([
				...equipeColEdited,
				{
					equipe_id: Number(name),
					value: value,
				},
			]);
		}
	};

	const handleChangeSelect = (
		e: React.ChangeEvent<HTMLSelectElement>,
		teamId: number
	) => {
		const { value } = e.target;

		// check if the team is already in the array
		const existingItem = equipeVarEdited.find(
			(item: { equipe_id: number }) => item.equipe_id === teamId
		);
		// if the team is already in the array, we update the variable_id
		if (existingItem) {
			setEquipeVarEdited((prevData: any) =>
				prevData.map((item: EquipeInterface) =>
					item.equipeId === teamId ? { ...item, variable_id: value } : item
				)
			);
			// if the team is not in the array, we add it
		} else {
			setEquipeVarEdited((prevData: any) => [
				...prevData,
				{
					equipe_id: teamId,
					variable_id: value,
				},
			]);
		}
	};

	useEffect(() => {
		const duplicateArray = [...equipeColEdited, ...equipeVarEdited];
		// in duplicateArray, we have the same team_id twice, so we need to merge the two objects
		const mergeArray = duplicateArray.reduce(
			(
				acc: { equipe_id: number }[],
				current: {
					equipe_id: number;
				}
			) => {
				const x = acc.find(
					(item: { equipe_id: number }) => item.equipe_id === current.equipe_id
				);
				if (!x) {
					return acc.concat([current]);
				} else {
					return acc.map((item: { equipe_id: number }) =>
						item.equipe_id === current.equipe_id
							? { ...item, ...current }
							: item
					);
				}
			},
			[]
		);
		setMergeArray(mergeArray);
	}, [equipeColEdited, equipeVarEdited]);

	const items = [
		{ label: "Dashboard", path: "/dashboard/admin" },
		{ label: "Mapping", path: "/dashboard/admin/mapping" },
		{ label: "Equipe", path: "/dashboard/admin/mapping/teams" },
	];

	return (
		<div className="teams_mapping">
			<Breadcrumb items={items} />
			<div className="dico_navigation">
				{dicos.map((item: { dicoId: string; dicoVersion: string }) => (
					<div
						onClick={() =>
							setDico({ dicoId: item.dicoId, dicoVersion: item.dicoVersion })
						}
						className={
							item.dicoId === dico.dicoId
								? "dico_version active"
								: "dico_version"
						}
						key={item.dicoId}
					>
						dico {getVersions(item.dicoVersion)}
					</div>
				))}
			</div>
			{dico.dicoVersion === "" ? (
				<h1>Liste globale</h1>
			) : (
				<h1>dico {getVersions(dico.dicoVersion)}</h1>
			)}
			<div>
				<table className="var_cell var_table mapping_table">
					<thead>
						<tr className="var_head_row">
							<th className="var_th">Equipes</th>
							<th className="var_th">Descriptions</th>
							<th className="var_th">Colonnes</th>
							<th className="var_th">Variables</th>
						</tr>
					</thead>
					<tbody>
						{teams.map((team: EquipeInterface) => (
							<tr className="var_body_row" key={team.equipeId}>
								<td className="var_td">{team.equipeName}</td>
								<td className="var_td">
									{parseCharSpe(team.equipeDescription)}
								</td>
								<td className="var_td">
									<input
										defaultValue={team.equipeCol}
										onChange={handleChangeInput}
										name={team.equipeId.toString()}
										className="var_input_mapping"
									/>
								</td>
								<td className="var_td">
									<select
										className="var_select_mapping"
										onChange={(event) =>
											handleChangeSelect(event, team.equipeId)
										}
										value={
											// dispplay team variable if it exists but allow to select another variable
											equipeVarEdited.find(
												(item: { equipe_id: number }) =>
													item.equipe_id === team.equipeId
											)
												? equipeVarEdited.find(
														(item: { equipe_id: number }) =>
															item.equipe_id === team.equipeId
												  ).variable_id
												: team.variableId
										}
									>
										{variables.map(
											(variable: {
												variableId: string;
												variableName: string;
											}) => (
												<option
													key={variable.variableId}
													value={variable.variableId}
												>
													{variable.variableName}
												</option>
											)
										)}
									</select>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="teams_mapping_btn">
					<GenericBtn onClick={patchColonnes} text="Valider" />
				</div>
			</div>
		</div>
	);
}
