import "./Error.css";

export default function Error({ array }: { array: any[] }): JSX.Element {
	const translateError = (error: number | string) => {
		switch (error) {
			case 1:
				return "Points avec Fic dupliqué";
			case 2:
				return "Points sans coordonnées GPS";
			case 4:
				return "Points avec absence propriétaire";
			case 5:
				return "Points sans équipe";
			case 6:
				return "Points sans mode de realisation et avec équipe";
			case 7:
				return "Points en incohérence mode JARR / BI ou robot";
			case 8:
				return "Points en incohérence mode EMAN / équipe manuelle";
			case 9:
				return "Points non terminés sans longueur";
			case 10:
				return "Points sans couleur";
			case 11:
				return "Points incohérence urgence / hors tension";
			case 12:
				return "Points incohérence urgence / mode realisation /hors tension";
			case 13:
				return "Points incohérence hors tension / longueur hors tension";
			case 14:
				return "Points incohérence avis propriétaire / longueur elagage";
			case 15:
				return "Points incohérence elagage par le propriétaire / mode realisation";
			case 16:
				return "Points incohérence refus propriétaire / mode realisation";
			case 17:
				return "Points incohérence mode realisation / avis propriétaire ";
			case 18:
				return "Points incohérence avis propriétaire / terminé";
			case 19:
				return "Points incohérence refus propriétaire / longueur elagage";
			case 20:
				return "Points incohérence refus propriétaire / terminé";
			case 21:
				return "Points incohérence longueur elagage / avis propriétaire / équipes";
		}
	};

	return (
		<div>
			{array?.map(
				(
					err: {
						retourPrepaErrorNumber: number;
						retourPrepaFic: number[];
					},
					index: number
				) => (
					<div key={index} className="error_log">
						<div className="error">
							{translateError(Number(err.retourPrepaErrorNumber))}
						</div>
						<p className="error_text">
							{err.retourPrepaFic.length > 1
								? err.retourPrepaFic.slice(0, -1).join(", ") +
								  ", " +
								  err.retourPrepaFic.slice(-1)
								: err.retourPrepaFic.join(", ")}
						</p>
					</div>
				)
			)}
		</div>
	);
}
