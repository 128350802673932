import React, { useEffect, useState } from "react";
import "./GestionErreur.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Card from "../../components/layout/cards/Card";
import BtnUpload from "../../components/layout/buttons/upload/BtnUpload";
import ValidateStepBtn from "../../components/layout/buttons/validate/ValidateStepBtn";
import Modal from "../../components/modal/Modal";
import Error from "../../components/error/Error";
import Popover from "../../components/modal/Popover";
import { donwloadFile } from "../../functions/downloadFile";
import json from "../../json/guide.json";
import { useToasts } from "../../hook/useToasts";
import GenericBtn from "../../components/layout/buttons/generic/GenericBtn";
import GoBack, { ConfirmButton } from "../../components/goBack/GoBack";
import { multiStepI } from "../../Types/Interfaces/multiStep";
import LoadingPopover from "../../components/modal/LoadingPopover";

const GestionErreur = ({ currentStep }: { currentStep: multiStepI }) => {
  /* -------------- BOOLEAN -------------- */
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [isPushDB, setIsPushDB] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /* -------------- STRING -------------- */
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [loadingMessage, setLoadingMessage] = useState<String>("");
  /* -------------- NUMBER -------------- */
  const [nbrError, setNbrError] = useState<number>(0);
  /* -------------- FILE -------------- */
  const [agiUpload, setAgiUpload] = useState<File | null>(null);
  const [csvUpload, setCsvUpload] = useState<File | null>(null);
  /* -------------- ARRAY -------------- */
  const [testErrorArray, setTestErrorArray] = useState<any[]>([]);

  const { id } = useParams<{ id: string }>();

  const apiUrl = process.env.REACT_APP_API_URL;

  const { pushToast } = useToasts();

  useEffect(() => {
    const calcError = () => {
      // check in mapData2 how many error there is
      const sum = mapData2.reduce(
        (acc: any, item: any) => acc + item.retourPrepaFic.length,
        0
      );
      setNbrError(sum);
    };
    calcError();
  }, [testErrorArray]);

  // this feature is used for post the file to the back-end
  // we use post method for post the file and use patch method for update the boolean
  // we use setTimeout for reload the page after 1 second
  const uploadRetourPrepaFile = async (response: [], id: string) => {
    setIsLoading(true);
    setLoadingMessage("Génération du retour prepa en base de données.");
    try {
      const retourPrepa = await axios.post(`${apiUrl}/retour-prepa`, {
        data: response,
        id: id,
      });
      const patchProjet = await axios.patch(`${apiUrl}/projet/${id}`, {
        projetFileRpIsUploaded: true,
      });
      setIsPushDB(!isPushDB);
      setIsLoading(false);
      if (retourPrepa.status === 200 && patchProjet.status === 200) {
        setTimeout(() => {
          setLoadingMessage("");
          window.location.reload();
        }, 1000);
      }
    } catch (error: unknown) {
      console.error({ message: error });
    }
  };

  // this feature is send notification to the preparator or the creator of the project
  // we use post method for send the notification
  // and use patch method for update the boolean for open the project to the preparator
  const sendNotification = async () => {
    const notifData = {
      projetId: Number(id),
      notificationType: "FlorenceInfoPrepa",
      notificationSenderId: currentStep?.projetCreatorId,
      notificationRecipientId: currentStep?.User.userId,
      notificationContent: `il y a des erreurs dans le fichier de retour de préparation ${currentStep?.projetName}`,
    };
    const patchProjet = {
      isOpenForPreparator: true,
    };
    try {
      await axios.patch(`${apiUrl}/projet/${id}`, patchProjet);
      await axios.post(`${apiUrl}/notifications`, notifData);
      pushToast({ type: "success", message: "Notification envoyée" });
    } catch (err: unknown) {
      console.error(err);
    }
  };
  // this feature is used for validation of the step
  const goToNextStep = async () => {
    const data = {
      stepId: 3,
    };
    try {
      axios.patch(`${apiUrl}/projet/${id}`, data);
      setIsOpen(true);
      setConfirmMessage("Passage à l'étape suivante");
    } catch (err) {
      setConfirmMessage("Erreur lors de la mise à jour du dossier");
      console.error(err);
    } finally {
      setTimeout(() => {
        setIsOpen(false);
        window.location.reload();
      }, 1500);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${apiUrl}/retour-prepa/error/${id}`);
        setTestErrorArray(response.data);
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }, []);

  // this feature for send the file to the back-end and file the file with new coordonate GPS

  const fillCoordGPSFromAgi = async () => {
    const uploadFiles = new FormData();
    uploadFiles.append("files", agiUpload as Blob);
    uploadFiles.append("files", csvUpload as Blob);
    try {
      const response = await axios.post(
        `${apiUrl}/retour-prepa/agi-excel`,
        uploadFiles
      );
      if (agiUpload && csvUpload) {
        donwloadFile(response.data, csvUpload.name);
        uploadRetourPrepaFile(response.data, id as string);
      } else {
        console.error("veuillez selectionner un fichier");
      }
    } catch (err: unknown) {
      console.error(err);
    }
  };

  // create a new array with only retourPrepaFic and retourPrepaErrorNumber
  const newArray = testErrorArray?.map(
    (item: { retourPrepaFic: number; retourPrepaErrorNumber: number }) => {
      return {
        retourPrepaFic: item.retourPrepaFic,
        retourPrepaErrorNumber: item.retourPrepaErrorNumber,
      };
    }
  );

  // list of all retourPrepaFic by retourPrepaErrorNumber in the same object
  const list = newArray?.reduce(
    (
      acc: any,
      item: {
        retourPrepaFic: number;
        retourPrepaErrorNumber: number;
      }
    ) => {
      if (!acc[item.retourPrepaErrorNumber]) {
        acc[item.retourPrepaErrorNumber] = [];
      }
      acc[item.retourPrepaErrorNumber].push(item.retourPrepaFic);
      return acc;
    },
    {}
  );

  // the first index of each array is the same as the key of the object
  const mapData = Object.keys(list).map((key) => {
    return {
      retourPrepaErrorNumber: key,
      retourPrepaFic: list[key],
    };
  });

  // in map data remove duplicate of retourPrepaFic
  const mapData2 = mapData?.map((item: any) => {
    return {
      retourPrepaErrorNumber: item.retourPrepaErrorNumber,
      retourPrepaFic: item.retourPrepaFic.filter(
        (
          item: [],
          index: number,
          self: {
            indexOf: (arg0: any) => number;
          }
        ) => {
          return index === self.indexOf(item);
        }
      ),
    };
  });

  const handleOpen = () => {
    setIsGenerated(!isGenerated);
  };

  return (
    <div>
      <h1>Gestion des erreurs</h1>
      {currentStep?.projetFileRpIsUploaded === false ? (
        <Card number="1" content={json.gestion_erreur.step_1.description}>
          <h4>
            Uploader l'agi et le fichier retour prepa (remplit automatiquement
            les coordonnées GPS du fichier excel et duplique les lignes de A à P
            des FICs 900)
          </h4>
          <div>
            <p>Uploader le fichier agi</p>
            <BtnUpload
              setFiles={setAgiUpload}
              fileName={agiUpload?.name}
              accept=".agi"
            />
          </div>
          <div>
            <p>Uploader le fichier Excel à remplir</p>
            <BtnUpload
              setFiles={setCsvUpload}
              fileName={csvUpload?.name}
              accept=".csv"
            />
          </div>
          <div className="gestion_erreur_btn">
            <GenericBtn
              text="Générer le fichier RP modifié"
              onClick={handleOpen}
              disabled={agiUpload && csvUpload ? false : true}
            />
          </div>
        </Card>
      ) : (
        <></>
      )}
      {currentStep?.projetFileRpIsUploaded && (
        <>
          <Card
            number={currentStep?.projetFileRpIsUploaded === true ? "1" : "2"}
            content={json.gestion_erreur.step_3.description}
          >
            <h4>Rapport des erreurs</h4>
            <div className="space-btn">
              <GenericBtn
                text="Synthèse d'erreurs"
                onClick={() => {
                  setOpenModal(!openModal);
                }}
              />
            </div>
          </Card>
          <Card
            number={currentStep?.projetFileRpIsUploaded === true ? "2" : "3"}
            content={json.gestion_erreur.step_4.description}
          >
            <h4>Correction</h4>
            <div className="space-btn">
              <Link
                to={`/correction/${id}`}
                target="_blank"
                className="link_btn"
              >
                Corriger moi même
              </Link>
              <GenericBtn
                text={
                  currentStep?.isOpenForPreparator === true
                    ? "✓ Notification envoyée"
                    : `Envoyer le dossier à ${currentStep?.User.userName}`
                }
                onClick={sendNotification}
                disabled={currentStep?.isOpenForPreparator === true}
              />
            </div>
          </Card>
          <Card
            number={currentStep?.projetFileRpIsUploaded === true ? "3" : "4"}
            content={json.gestion_erreur.step_5.description}
          >
            <h4>
              Valider les corrections{" "}
              <span>
                {nbrError > 0
                  ? `(il y a ${nbrError} d'erreurs restantes à corriger)`
                  : ``}
              </span>
            </h4>
            <ValidateStepBtn
              validate={goToNextStep}
              text="Valider"
              // disabled={nbrError === 0 ? false : true}
            />
          </Card>
        </>
      )}

      <GoBack currentStep={currentStep?.stepId} />

      {/** //*____________________________ MODAL COMPONENT ____________________________//
       *
       * this modal is used to display a log with all the errors
       * cette modal est utilisée pour afficher un log avec toutes les erreurs
       *
       */}

      <Modal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        title="Recap des erreurs"
      >
        <Error array={mapData2} />
      </Modal>
      {/**
       *
       *  //*____________________________ POPOVER COMPONENT ____________________________//
       * this popover is used to confirm we agree to push the file in the database
       * cette popover est utilisée pour confirmer que l'on souhaite pousser le fichier en base de données
       *
       */}
      <Popover isOpen={isGenerated} onClick={handleOpen}>
        <div>
          <p>
            Attention, souhaitez vous vraiment pousser ce fichier en base de
            données ? Cette action est irréversible.
          </p>
          <ConfirmButton
            onClickYes={() => {
              fillCoordGPSFromAgi();
              handleOpen();
            }}
            onClickNo={() => setIsPushDB(!isPushDB)}
          />
        </div>
      </Popover>
      {/**
       *
       *  //*____________________________ POPOVER COMPONENT ____________________________//
       * this popover is used to confirm we agree to push the file in the database
       * cette popover est utilisée pour confirmer que l'on souhaite pousser le fichier en base de données
       *
       */}
      <Popover isOpen={isOpen}>
        <p>{confirmMessage}</p>
      </Popover>
      <LoadingPopover isLoading={isLoading} message={loadingMessage} />
    </div>
  );
};

export default GestionErreur;
