import React from "react";
import "./ValidateStepBtn.css";
export default function ValidateStepBtn({
	validate,
	text,
	disabled = false,
}: {
	validate: () => void;
	text: string;
	disabled?: boolean;
}) {
	return (
		<button onClick={validate} className="validate-btn" disabled={disabled}>
			{text}
		</button>
	);
}
