import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import ErrorTableBackEnd from "../../components/utils/array/ErrorTableBackEnd";
import "./Correction.css";
import GenericBtn from "../../components/layout/buttons/generic/GenericBtn";
import { FormContext, FormInterface } from "../../contexts/FormContext";
import Legend from "../../components/utils/legend/Legend";
import { LoginContext, LoginType } from "../../contexts/LoginContext";
import { IoInformationCircleOutline } from "react-icons/io5";
import Loader from "../../components/loading/Loader";

export default function CorrectionMapwood() {
  const [backEndArray, setBackEndArray] = useState([]);
  const [formData, setFormData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const { handleSubmit } = useContext(FormContext) as FormInterface;
  const { role } = useContext(LoginContext) as LoginType;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/retour-prepa-mapwood/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response && response.status === 200) {
          setBackEndArray(response.data);
          setIsLoading(false);
        } else {
          console.error(response);
        }
      } catch (error: unknown) {
        console.error({ message: "error", error });
      }
    })();
  }, []);

  const recalculateErrors = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/retour-prepa-mapwood/error/${id}`
      );
      if (response && response.status === 200) {
        window.location.reload();
      } else {
        console.error(response);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };
  const removeSpeChar = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/retour-prepa-mapwood/clean-character/${id}`
      );
      window.location.reload();
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handleToggleModal = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  return (
    <div className="correction_container">
      <div className="correction_legend_container">
        <div className="correction_header_sticky">
          <h1>Correction des erreurs</h1>
          <Legend />
          <div className="correction_button_container">
            <div className="correction_gap">
              <GenericBtn
                text="Confirmer corrections"
                onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                  handleSubmit(event, id as string, formData)
                }
              />
            </div>
            <div className="correction_gap">
              {role === "ASSISTANT_GESTION" || "ADMIN" ? (
                <>
                  <GenericBtn
                    text="Recalculer les erreurs"
                    onClick={recalculateErrors}
                  />
                </>
              ) : null}
            </div>
            <div className="correction_gap">
              {role === "ASSISTANT_GESTION" || "ADMIN" ? (
                <GenericBtn
                  text="Supprimer les caractères spéciaux"
                  onClick={removeSpeChar}
                />
              ) : null}
            </div>
            <div>
              <Link
                to="/correction/guid"
                target="_blank"
                className="help_btn"
                onClick={handleToggleModal}
              >
                <IoInformationCircleOutline size={24} className="help" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ErrorTableBackEnd
        arrayOfData={backEndArray}
        left={200}
        setFormData={setFormData}
      />
    </div>
  );
}
