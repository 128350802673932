import React from "react";
import "./Toast.css";
type Props = {
	message?: string;
	type?: "success" | "error" | "default" | "warning" | "info";
};

export default function Toast({ message, type = "default" }: Props) {
	return (
		<div className={`toast toast--${type}`}>
			<p className="toast_message">{message}</p>
		</div>
	);
}
