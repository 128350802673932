import React, { useState } from "react";
import "./FloatInput.css";
import { FaUserCog, FaCode, FaHome, FaEyeSlash, FaEye } from "react-icons/fa";

interface FloatInputI {
  inputLabel: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  required?: boolean;
  type?: string;
  defaultValue?: string | number;
  name?: string;
  placeholder?: string;
}

export function FloatInput({
  inputLabel,
  onChange,
  value,
  required,
  type = "text",
  defaultValue,
  name,
  placeholder,
}: FloatInputI): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="input_container">
      <input
        type={showPassword ? "text" : type}
        value={value}
        onChange={onChange}
        name={name}
        className="floatinput"
        required={required}
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
      <label className={value || defaultValue ? "filled" : ""}>
        {inputLabel}
      </label>
      {type === "password" && (
        <button
          onClick={togglePasswordVisibility}
          className="password-toggle-button"
        >
          {showPassword ? <FaEye size={18} /> : <FaEyeSlash size={18} />}
        </button>
      )}
    </div>
  );
}

export function FloatSelect({
  selectLabel,
  setValue,
  value,
  required,
  options,
}: {
  selectLabel: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  required?: boolean;
  options: any;
}): JSX.Element {
  return (
    <div className="input_container">
      <select
        id="select"
        required={required}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="select_input"
      >
        <option value="">{""}</option>
        {options.data.select_option_group
          .sort((a: any, b: any) => {
            return a.select_option_number - b.select_option_number;
          })
          .map((option: any, index: number) => (
            <option value={option.select_option[0].text} key={index}>
              {option.select_option[0].text}
            </option>
          ))}
      </select>
      <label className={value && "filled"}>{selectLabel}</label>
    </div>
  );
}
